import React, { useState, useEffect, useCallback } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { formatAddress } from "../../utils/support";
import { useApi } from "../../hooks";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';
const api_uri = process.env.REACT_APP_API;

const RoolsCard = ({
	id = "",
	title = "",
	text,
	isActive,
	events = 0,
	newEvents = 0,
	address = "",
	roolOrder = 1,
	adressesInList = 0,
	type = "",
}) => {
	const [addressArr, setAddressArr] = useState([]);
	const [cardColorClass, setCardColorClass] = useState("");
	const [isSwitched, setIsSwitched] = useState(isActive);
	const { userSign } = useApi();
	

	useEffect(() => {
		if (address) {
			if (address.includes("0x")) {
				setAddressArr(formatAddress(address));
			} else {
				setAddressArr(address);
			}
		}
	}, [address]);

	useEffect(() => {
		if (roolOrder % 2 === 0) {
			setCardColorClass("blue");
		}

		if (roolOrder % 3 === 0) {
			setCardColorClass("green");
		}

		if (roolOrder % 4 === 0) {
			setCardColorClass("purple");
		}
	}, [roolOrder]);

	const addressSuffixes = useCallback((count) => {
		const countString = count.toString();
		if (
			countString[countString.length - 1] === "1" &&
			countString[countString.length - 2] !== "1" &&
			localStorage.getItem('language') == 'ru'
		) {
			return "";
		} else if (
			(countString[countString.length - 1] === "2" && 
			localStorage.getItem('language') == 'ru' ||
				countString[countString.length - 1] === "3" &&
				localStorage.getItem('language') == 'ru' ||
				countString[countString.length - 1] === "4") &&
			countString[countString.length - 2] !== "1" &&
			localStorage.getItem('language') == 'ru'
		) {
			return text("suffixes.a");
		} else if (
			localStorage.getItem('language') == 'ru'
		) {
			return text("suffixes.ov");
		} else if (
			localStorage.getItem('language') == 'en' && 
			countString[countString.length - 1] !== "1"
		) {
			return text("suffixes.s");
		} else if (localStorage.getItem('language') == 'en') {
			return text("suffixes.zero");
		}
	}, []);

	const eventsSuffixes = useCallback((count) => {
		const countString = count.toString();
		if (
			countString[countString.length - 1] === "1" &&
			countString[countString.length - 2] !== "1" &&
			localStorage.getItem('language') == 'ru'
		) {
			return text("suffixes.e");
		} else if (
			(countString[countString.length - 1] === "2" ||
				countString[countString.length - 1] === "3" ||
				countString[countString.length - 1] === "4") &&
			countString[countString.length - 2] !== "1" &&
			localStorage.getItem('language') == 'ru'
		) {
			return text("suffixes.ya");
		} else if (
			localStorage.getItem('language') == 'ru'
		){
			return text("suffixes.y");
		} else if (
			localStorage.getItem('language') == 'en' && 
			countString[countString.length - 1] !== "1"
		) {
			return text("suffixes.s");
		} else if (localStorage.getItem('language') == 'en') {
			return text("suffixes.zero");
		}
	}, []);

	async function switchRool(sign, eventId, isActive) {
		try {
			const response = await axios.patch(`${api_uri}/project/event/path_active`, {
				"id_event": eventId,
				"is_active": `${isActive}`,
			},
			{headers: {
				sign: sign
			}})

			setIsSwitched(!isSwitched);
		} catch (error) {
			console.log(error)
			setIsSwitched(false);
			error.message === "Request failed with status code 402" && toast.error(text("project.error"), { position: "top-center"});
		}
	}

	return (
		<Link
			to={`rool/${id}`}
			className={"card rools_card  " + cardColorClass}
		>
			<div className="rools_card__inner flex  flex--space_between flex--column">
				<div className="rools_card__header flex  flex--space_between flex--column">
					{addressArr.length > 0 && type !== "NT" ? (
						typeof addressArr === "object" ? (
							<div className="rools_card__address  flex subtitle">
								<p className="zero">
									{/* 0x */}
								</p>
								<div className="rest rest--ml grid grid--two_columns">
									{addressArr?.map((item, i) => (
										<p key={"ii" + i}>{item}</p>
									))}
								</div>
							</div>
						) : (
							<div className="rools_card__address subtitle">
								<p className="zero">
									{adressesInList}
									{text("project.rool_card_addresses")}
									{addressSuffixes(adressesInList)}
								</p>

								<p className="zero">
									{text("project.rool_card_from_list")}
								</p>

								<div className="rest">
									{addressArr?.split(" ").map((item, i) => (
										<p key={"ii" + i}>{item}</p>
									))}
								</div>
							</div>
						)
					) : (
						<p className="rools_card__address zero subtitle">
							{text("project.native")}
						</p>
					)}
					<p className="rools_card__title subtitle">
						{title && title}{' '}
						<span className="rools_card__span">{`[id:${id}]`}</span>
					</p>
				</div>

				<div className="rools_card__bottom_info">
					<div className="tumbler__box">
						<p className="tumbler__text  text text--dark">{text("project.indicator_active_text")}</p>
						<div
							className={isSwitched ? 'activeData' : 'disActiveData'}
							onClick={(event) => {
									event.preventDefault();
									switchRool(userSign, id, !isActive);
								}
							}
						/>
					</div>

					<div className="flex flex--align-items-center">

						<p className="text  text--dark">
							{events} {text("project.rool_card_from_events")}
							{eventsSuffixes(events)}
							{newEvents > 0 && <span> + {newEvents}</span>}
						</p>
					</div>
				</div>
			</div>
		</Link>
	);
};

export default RoolsCard;
