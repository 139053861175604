import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import PageHeaderWithArr from "../../components/page_header_with_arr";
import ProjectRools from "../../blocks/project/project-rools";
import ProjectList from "../../blocks/project/project-list";
import { useApi } from "../../hooks";
import { projectAPI } from "../../services";
import "./index.scss";
import { locale } from "../../constants/constants";

const Project = ({ text, refetchProjects }) => {
	const { userSign } = useApi();
	let { projectId } = useParams();
	const [skip, setSkip] = useState(true);

	const { project, error, isLoading, refetch } =
		projectAPI.useFetchProjectQuery(
			{ userSign, projectId },
			{
				selectFromResult: ({ data, error, isLoading }) => {
					return {
						project: data?.projects[0],
						error: error,
						isLoading: isLoading,
					};
				},
				skip,
			}
		);

	const [isShowList, setIsShowList] = useState(false);
	const [isShowRools, setIsshowRools] = useState(true);

	const roolBtn = useRef();
	const listBtn = useRef();

	useEffect(() => {
		const choosenTab = localStorage.getItem("projectTab");
		if (choosenTab && choosenTab === "lists") {
			showList();
		}
	}, []);

	useEffect(() => {
		if (userSign && projectId) {
			setSkip(false);
		}
	}, [userSign, projectId]);

	useEffect(() => {
		if (project) {
			refetchProjects();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project]);

	const showRools = () => {
		roolBtn.current.classList.add("active");
		listBtn.current.classList.remove("active");
		setIsshowRools(true);
		setIsShowList(false);
		localStorage.setItem(
			"projectTab",
			roolBtn.current.getAttribute("aria-label")
		);
	};

	const showList = () => {
		roolBtn.current.classList.remove("active");
		listBtn.current.classList.add("active");
		setIsshowRools(false);
		setIsShowList(true);
		localStorage.setItem(
			"projectTab",
			listBtn.current.getAttribute("aria-label")
		);
	};

	return (
		<div className="project">
			<PageHeaderWithArr
				text={project?.name}
				to={`/${locale}`}
				isLoading={isLoading || !userSign}
				isError={error}
				error={error}
			/>
			<div className="tabs">
				<div className="tabs__btns  flex">
					<button
						className="btn tabs__btn btn--thin active"
						ref={roolBtn}
						aria-label="rools"
						type="button"
						onClick={showRools}
					>
						{text("project.project_tab_btn_rools")}
					</button>

					<button
						className="btn tabs__btn  btn--thin"
						ref={listBtn}
						aria-label="lists"
						type="button"
						onClick={showList}
					>
						{text("project.project_tab_btn_lists")}
					</button>
				</div>

				{isShowRools && (
					<div className="tabs__item">
						<ProjectRools text={text} />
					</div>
				)}

				{isShowList && (
					<div className="tabs__item">
						<ProjectList
							id={projectId}
							sign={userSign}
							text={text}
							refetchProjectInfo={refetch}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default Project;
