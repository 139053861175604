import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import {
	EthereumClient,
	w3mConnectors,
	w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { setupStore } from "./redux";
import { chains } from "./constants/chains.config";
import { icons } from "./constants/chains.config";
import { config } from "./app-config";
import "./index.scss";
import ErrorBoundry from "./components/error-boundry";
import App from "./App";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const store = setupStore();

// Wagmi client
const { publicClient } = configureChains(chains, [
	w3mProvider({ projectId: config.walletConnectId }),
]);

const wagmiClient = createConfig({
	autoConnect: true,
	connectors: w3mConnectors({
		projectId: config.walletConnectId,
		version: "2",
		chains,
	}),
	publicClient,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, icons);

//
const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

root.render(
	<>
		<WagmiConfig config={wagmiClient}>
			<Provider store={store}>
				<ErrorBoundry>
					<App />
				</ErrorBoundry>
			</Provider>
		</WagmiConfig>

		<Web3Modal
			projectId={config.walletConnectId}
			ethereumClient={ethereumClient}
			themeMode={"light"}
			themeColor={"default"}
			enableNetworkView={true}
			chainImages={{
				1231: "img/log_in/networks/ultron_square.svg",
				1230: "img/log_in/networks/ultron_square.svg",
				1666600000: "img/log_in/networks/harmony_square.svg",
				"": "img/log_in/networks/tron-circle.svg",
			}}
			// privacyPolicyUrl={`${window.location.origin}/privacy-policy`}
			// termsOfServiceUrl={`${window.location.origin}/terms-of-use`}
		/>
		<ToastContainer pauseOnFocusLoss={false} limit={1}/>
	</>
);

reportWebVitals();
