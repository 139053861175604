import React, { useState } from "react";
import "./index.scss";
import Modal from "../../../blocks/modal";
import CustomInput from "../../../components/custom-input";
import Loader from "../../../components/loader";
import Message from "../../../components/message";
import { ReactComponent as Success } from "../../../commons/svg/test_modal/success.svg";
import { ReactComponent as Error } from "../../../commons/svg/test_modal/error.svg";
import { updateErrorInfo } from "../../../utils/support";
import { roolAPI } from "../../../services";

const RoolTest = ({ userSign = "", roolId = "", text, closeModalFunc }) => {
	const [inputValue, setValue] = useState("");
	const [errorText, setErrorText] = useState("");
	const [showMess, setShowMess] = useState(false);

	const [fetchRoolTest, { data, error, isError, isLoading, reset }] =
		roolAPI.useFetchRoolTestMutation();

	const handleChange = (value) => {
		setValue(value);
		setShowMess(false);
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		setShowMess(true);
		if (inputValue && !errorText) {
			await fetchRoolTest({
				userSign,
				eventId: roolId,
				hash: inputValue,
			});
		}
	};

	return (
		<Modal
			closeModalHandle={closeModalFunc}
			isCloseNotFilled={true}
			addTopClose={false}
		>
			<form className="test_modal" onSubmit={onSubmit}>
				<h2 className="title">{text("rool_test_modal.rool_test")}</h2>

				<p className="text test_modal__text">
					{text("rool_test_modal.rool_test_description")}
				</p>

				<CustomInput
					type="text"
					value={inputValue}
					setValue={handleChange}
					getErrorText={setErrorText}
					text={text("rool_test_modal.enter_transaction_hash")}
					resetStatus={reset}
				/>

				<div className="test_modal__footer">
					<button className="btn btn--blue  btn--thin" type="submit">
						{text("rool_test_modal.test")}
					</button>

					{isLoading ? (
						<Loader />
					) : isError ? (
						error.data.status === false ? (
							<div className="flex flex--align-items-center  test_modal__result text--error subtitle">
								<Error className="test_modal__icon  test_modal__icon--error" />
								<p>{text("rool_test_modal.error_message")}</p>
							</div>
						) : (
							<Message
								text={updateErrorInfo(error)}
								isError={true}
							/>
						)
					) : (
						data?.status === true &&
						showMess && (
							<div className="flex flex--align-items-center  test_modal__result text--success subtitle">
								<Success className="test_modal__icon  test_modal__icon--success" />
								<p>{text("rool_test_modal.success_message")}</p>
							</div>
						)
					)}
				</div>
			</form>
		</Modal>
	);
};

export default RoolTest;
