/**
 * @param {string} _value
 */

export function cutAddress(_value) {
  try {
    if (!_value) {
      return "";
    }
    const leftChars = 4;
    const rightChars = 4;
    if (_value.length > leftChars + rightChars + 3) {
      return `${_value.slice(0, leftChars)}...${_value.slice(-rightChars)}`;
    }
    return _value;
  } catch (err) {
    console.log(err);
  }
}

/**
 * @param {string} _address
 */

export const formatAddress = (_address) => {
  try {
    const str = _address.slice(0, _address.length); //для удаления 0x в начале адреса нужно заменить 0 на 2
    const sep = (xs, s) =>
      xs.length ? [xs.slice(0, s), ...sep(xs.slice(s), s)] : [];
    return sep(str, 4);
  } catch (err) {
    console.log(err);
  }
};

export const formatAddressList = (_address) => {
  try {
    const str = _address.slice(0, _address.length); //для удаления 0x в начале адреса нужно заменить 0 на 2
    const sep = (xs, s) =>
      xs.length ? [xs.slice(0, s), ...sep(xs.slice(s), s)] : [];
    return sep(str, 42);
  } catch (err) {
    console.log(err);
  }
};

export const formatAddressProfile = (_address) => {
  try {
    const str = _address.slice(2, _address.length);
    const sep = (xs, s) =>
      xs.length ? [xs.slice(0, s), ...sep(xs.slice(s), s)] : [];
    return sep(str, 4);
  } catch (err) {
    console.log(err);
  }
};

export const addHiddenToBody = () => {
  try {
    document.body.style.overflow = "hidden";
  } catch (err) {
    console.log(err);
  }
};

export const formatDate = (date, locale, isOnlyDate = false) => {
  try {
    const day = date.toLocaleString(locale, { day: "numeric" });
    const month = date.toLocaleString(locale, { month: "long" });
    const year = date.toLocaleString(locale, { year: "numeric" });
    const time = date.toLocaleTimeString();

    function getMonth(monthStr) {
      let month = monthStr;
      let newMonth = "";

      if (monthStr[month.length - 1] === "ь") {
        newMonth = `${month.slice(0, month.length - 1)}я`;
      } else if (monthStr[month.length - 1] === "й") {
        newMonth = `${month}я`;
      } else {
        newMonth = `${month}а`;
      }

      return newMonth;
    }

    const newMonth = locale === "ru" ? getMonth(month) : month;

    if (isOnlyDate) {
      return locale === "ru"
        ? `${day} ${newMonth} ${year}`
        : `${newMonth} ${day + ","} ${year}`;
    } else if (time) {
      return locale === "ru"
        ? `${day} ${newMonth} ${year}, ${time}`
        : `${newMonth} ${day + ","} ${year}, ${time}`;
    }
  } catch (err) {
    console.log(err);
  }
};

export const timestampToDate = (timestamp, locale) => {
  try {
    const time = new Date(timestamp * 1000);

    return formatDate(time, locale);
  } catch (err) {
    console.log(err);
  }
};

export const getLocaleUserTime = (_date, locale, is_YYYY_MM_DD_format = true) => {
  try {
    let date;

    if (is_YYYY_MM_DD_format) {
      date = new Date(`${_date?.replace(" ", "T")}Z`);
    } else {
      date = new Date(`${_date} UTC`);
    }

    return formatDate(date, locale);
  } catch (err) {
    console.log(err);
  }
};

/**
 * @param {string} _date
 */

export const getOnlyDate = (_date, locale, is_YYYY_MM_DD_format = true) => {
  try {
    let date;
    if (is_YYYY_MM_DD_format) {
      date = new Date(`${_date?.replace(" ", "T")}Z`);
    } else {
      date = new Date(`${_date} UTC`);
    }

    return formatDate(date, locale, true);
  } catch (err) {
    console.log(err);
  }
};

/**
 * @param {string} _date
 */

export const formatDateForTimeInterval = (
  _date,
  is_YYYY_MM_DD_format = true,
  locale
) => {
  try {
    let date;
    if (is_YYYY_MM_DD_format) {
      date = new Date(`${_date?.replace(" ", "T")}Z`);
    } else {
      date = new Date(`${_date} UTC`);
    }

    const userDate = new Date();

    const day = date.toLocaleString(locale, { day: "numeric" });
    const month = date.toLocaleString(locale, { month: "long" });
    const year = date.toLocaleString(locale, { year: "numeric" });
    const time = date.toLocaleTimeString();

    const userDay = userDate.toLocaleString(locale, { day: "numeric" });
    const userMonth = userDate.toLocaleString(locale, { month: "long" });
    const userYear = userDate.toLocaleString(locale, { year: "numeric" });

    if (day === userDay && month === userMonth && year === userYear) {
      return locale === "ru" ? `сегодня в ${time}` : `today ${time}`;
    } else if (
      day - userDay === 1 &&
      month === userMonth &&
      year === userYear
    ) {
      return locale === "ru" ? `завтра в ${time}` : `tomorrow ${time}`;
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @param {string} _event_string
 */

export const parseEventString = (_event_string) => {
  try {
    if (_event_string.length > 1) {
      let indexStart = 0;
      let indexEnd = 0;
      if (_event_string.includes("(") && _event_string.includes(")")) {
        indexStart = _event_string.indexOf("(");
        indexEnd = _event_string.indexOf(")");
      }

      if (indexStart !== 0 && indexEnd !== 0) {
        const parsedString = _event_string.slice(indexStart + 1, indexEnd);

        const valuesArr = parsedString.trim().split(",");

        const fileds = valuesArr.map((item) => {
          const splitItem = item
            .trim()
            .split(" ")
            .filter((i) => !i.includes("index"));
          let type;
          const nameSplitStart = splitItem[1].indexOf("_");

          if (splitItem[0].includes("address")) {
            type = "string";
          } else {
            type = "number";
          }

          return {
            name: splitItem[1].slice(nameSplitStart + 1, splitItem[1].length),
            type: type,
          };
        });

        return fileds;
      }
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @param {array} _error_info
 */

export const updateErrorInfo = (_error_info) => {
  try {
    if (typeof _error_info === "string") {
      return _error_info;
    } else if (typeof _error_info?.data?.detail === "object") {
      return _error_info.data.detail[0].msg;
    } else if (_error_info?.error) {
      return _error_info?.error;
    } else if (_error_info?.data?.error) {
      return _error_info?.data?.error;
    } else if (_error_info?.data.message) {
      return _error_info.data.message;
    } else {
      return _error_info?.data.detail;
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @param {string} val
 * @param {Function} setErrText
 */

export const deleteScript = (val, setErrText) => {
  try {
    const value = val.toLowerCase();
    const regExp = /<?\/?script>?/g;
    if (regExp.test(value)) {
      setErrText(`Текст содержит тег script. Пожалуйста, удалите его.`);
    } else {
      setErrText(``);
    }
  } catch (err) {
    console.log(err);
  }
};

export const deleteSymbols = (val, setErrText) => {
  try {
    const value = val.toLowerCase();
    const regExp = /(<|>|\[|\])/g;
    if (regExp.test(value)) {
      setErrText(`Удалите недопустимые символы:  >, <, [, ]`);
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @param {string} _addresses
 */

export const formatListTextareaAddresses = (_addresses) => {
  try {
    if (window.innerWidth > 560) {
      return _addresses.split("\n");
    } else {
      return _addresses.split("\n\n");
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @param {object} _string
 */

export const showCode = (_object) => {
  try {
    const newObj = Object.entries(_object).map((item) => {
      if (item[0] === "logs") {
        return [item[0], Object.entries(item[1][0])];
      }
      if (typeof item[1] === "object") {
        return [item[0], Object.entries(item[1])];
      } else {
        return item;
      }
    });

    return newObj;
  } catch (err) {
    console.log(err);
  }
};

export const clearLocaleStorageUTM = (key) => {
  window.localStorage.removeItem(key);
};

export const setLocaleStorageUTM = (key, value) => {
  if (value) {
    window.localStorage.setItem(key, value);
  }
};

export const getLocaleStorageUTM = (key) => {
  return window.localStorage.getItem(key);
};

export const getUTM = (param) => {
  const urlParams = new URL(window.location.toString()).searchParams;
  return urlParams.get(param) || "";
};

export const setAllUTM = (location, locale) => {
  if (location !== `/${locale}`) {
    clearLocaleStorageUTM("utm_source");
    clearLocaleStorageUTM("utm_medium");
    clearLocaleStorageUTM("utm_campaign");
    clearLocaleStorageUTM("utm_content");
    clearLocaleStorageUTM("utm_term");
  }
  const utm_source = getUTM("utm_source");
  const utm_medium = getUTM("utm_medium");
  const utm_campaign = getUTM("utm_campaign");
  const utm_content = getUTM("utm_content");
  const utm_term = getUTM("utm_term");

  setLocaleStorageUTM("utm_source", utm_source);
  setLocaleStorageUTM("utm_medium", utm_medium);
  setLocaleStorageUTM("utm_campaign", utm_campaign);
  setLocaleStorageUTM("utm_content", utm_content);
  setLocaleStorageUTM("utm_term", utm_term);
};

export const getAllUTM = () => {
  let utms = {};
  const utm_source = getLocaleStorageUTM("utm_source");
  const utm_medium = getLocaleStorageUTM("utm_medium");
  const utm_campaign = getLocaleStorageUTM("utm_campaign");
  const utm_content = getLocaleStorageUTM("utm_content");
  const utm_term = getLocaleStorageUTM("utm_term");

  if (utm_source) {
    utms.utm_source = utm_source;
  }

  if (utm_medium) {
    utms.utm_medium = utm_medium;
  }

  if (utm_campaign) {
    utms.utm_campaign = utm_campaign;
  }

  if (utm_content) {
    utms.utm_content = utm_content;
  }

  if (utm_term) {
    utms.utm_term = utm_term;
  }

  return utms;
};

export const checkUTMOnRedirect = (newUrl) => {
  var utm = window.location.search;
  var url = new URL(newUrl);
  url.search = utm;

  window.location.replace(url);
};

const addressTronRegex = /^T[a-zA-Z0-9]{33}$/;
export function isTronAddress(address) {
  return addressTronRegex.test(address);
}
