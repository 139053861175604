import { web3modal, account} from "./reducers";
import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {settingsAPI, projectAPI, siteAPI, listAPI, roolAPI, eventAPI, accountAPI} from "../services";

const rootReducer = combineReducers({
    web3modal, 
    account,
    [settingsAPI.reducerPath]: settingsAPI.reducer,
    [projectAPI.reducerPath]: projectAPI.reducer,
    [siteAPI.reducerPath]: siteAPI.reducer,
    [listAPI.reducerPath]: listAPI.reducer,
    [roolAPI.reducerPath]: roolAPI.reducer,
    [eventAPI.reducerPath]: eventAPI.reducer,
    [accountAPI.reducerPath]: accountAPI.reducer,
})

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
                .concat(settingsAPI.middleware)
                .concat(projectAPI.middleware)
                .concat(siteAPI.middleware)
                .concat(listAPI.middleware)
                .concat(roolAPI.middleware)
                .concat(eventAPI.middleware)
                .concat(accountAPI.middleware)
    })
}

