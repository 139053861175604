import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import axios from "axios";
const api_uri = process.env.REACT_APP_API;

export const roolAPI = createApi({
    reducerPath: 'roolAPI',
    baseQuery: fetchBaseQuery({baseUrl: api_uri}),
    tagTypes: ['Rool'],
    endpoints: (build) => ({
        fetchAllRool: build.query({
            query: ({userSign, projectId, limit = 10, skip = 0}) => ({
                url: `project/events`,
                params: {
                    project_id : projectId,
                    skip: skip,
                    limit: limit
                },
                headers: {
                    sign: userSign
                }
            }),
            providesTags: result => ['Rool'],
        }),
        fetchRool: build.query({
            query: ({userSign, projectId, eventId, limit = 10, skip = 0}) => ({
                url: `project/events`,
                params: {
                    project_id : projectId,
                    event_id: eventId,
                    skip: skip,
                    limit: limit
                },
                headers: {
                    sign: userSign
                }
            }),
            providesTags: result => ['Rool'],
            transformResponse: (response, meta, arg) => response.events,
        }),
        fetchRoolsLogs: build.query({
            query: ({userSign, projectId, eventId}) => ({
                url: `project/event/logs`,
                params: {
                    event_id: eventId,
                    project_id : projectId,
                },
                headers: {
                    sign: userSign
                }
            }),
            providesTags: result => ['Rool'],
        }),
        fetchRoolTest: build.mutation({
            query: ({userSign, eventId, hash}) => ({
                url: `/project/event/test`,
                method: 'GET',
                params: {
                    event_id: eventId,
                    tx: hash
                },
                headers: {
                    sign: userSign
                }
            }),
            providesTags: result => ['Rool'],
            // transformResponse: (response, meta, arg) => response.events,
        }),
        sendMess: build.mutation({
            query: ({sign, body}) => ({
                url: `/project/event/send_message`,
                method: 'POST',
                body: body,
                headers: {
                    sign: sign
                }
            }),
            invalidatesTags: ['Rool']
        }),
        createRool: build.mutation({
            query: ({body, sign}) => ({
                url: `/project/event`,
                method: 'POST',
                body: body,
                headers: {
                    sign: sign
                },
            }),
            invalidatesTags: ['Rool']
        }),
        updateRool: build.mutation({
            query: ({sign, eventId, body}) => ({
                url: `/project/event`,
                method: 'PATCH',
                headers: {
                    sign: sign
                },
                body: body
            }),
            invalidatesTags: ['Rool']
        }),
        deleteRool: build.mutation({
            query: ({sign, eventId, projectId}) => ({
                url: `/project/event`,
                method: 'DELETE',
                body: {
                    event_id: eventId,
                    project_id: projectId
                },
                headers: {
                    sign: sign
                }
            }),
            invalidatesTags: ['Rool']
        }),
        switchRool: build.mutation({
            query: ({sign, eventId, isActive}) => ({
                url: ``,
                method: 'PATCH',
                params: {
                    event_id: eventId,
                    isActive: isActive
                },
                headers: {
                    sign: sign
                }
            }),
            invalidatesTags: ['Rool']
        }),
    })
})
