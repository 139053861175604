/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
	BrowserRouter as Router,
	Route,
	Routes,
} from "react-router-dom";
import { useAccount, useNetwork } from "wagmi";
import Header from "./blocks/header";
import Home from "./pages/home";
import LogIn from "./pages/log-in";
import Events from "./pages/events";
import Projects from "./pages/projects";
import Sites from "./pages/sites";
import Balance from "./pages/balance";
import Project from "./pages/project";
import Rool from "./pages/rool";
import List from "./pages/list";
import Website from "./pages/website";
import EventDetail from "./pages/event-detail";
import Profile from "./pages/profile";
import { config } from "./app-config";
import { useApi } from "./hooks";
import { settingsAPI, accountAPI, projectAPI } from "./services";
import { setAllUTM, getAllUTM, checkUTMOnRedirect } from "./utils/support";
import { recoverMessageAddress } from 'viem'
import i18n from "./i18n";
import { locale } from "./constants/constants";
import {Helmet} from "react-helmet";

const accountSelector = (s) => s.account;
function App() {
	const { t } = useTranslation();
	const { userAddress } = useSelector(accountSelector);
	const { chain } = useNetwork();
	const { userLogIn } = useApi();
	const { userSign } = useApi();
	const { address, isConnected, connector, isReconnecting } = useAccount();
	const [lastProjectsIndex, setLastProjectsIndex] = useState(10);
	const [skipProjects, setSkipProjects] = useState(true);
	const {
		data: settings,
		error,
		isLoading,
	} = settingsAPI.useFetchSettingsQuery();

	const [
		getAddressInfo,
		{
			data: addressInfo,
		},
	] = accountAPI.useGetAddressInfoMutation();

	const {
		projects,
		errorProjects,
		isLoadingProjects,
		refetch: refetchProjects,
	} = projectAPI.useFetchAllProjectsQuery(
		{ userSign: userSign, limit: lastProjectsIndex },
		{
			selectFromResult: ({ data, error, isLoading }) => {
				return {
					projects: data?.projects,
					errorProjects: error,
					isLoadingProjects: isLoading,
				};
			},
			skip: skipProjects,
		}
	);

	useEffect(() => {
		if (window.location.pathname.substring(0, 3) === '/en') {
			setEnglishLanguage();
		}
		if (window.location.pathname.substring(0, 3) === '/ru') {
			setRussianLanguage();
		}	
	}, []);
	  
	function setRussianLanguage () {
		i18n.changeLanguage('ru');
		localStorage.setItem('language', 'ru');
	}

	function setEnglishLanguage () {
		i18n.changeLanguage('en');
		localStorage.setItem('language', 'en');
	}

	useEffect(() => {
		if (isConnected && address && window.location.pathname === "/") {
			if (window.location.protocol === "https:") {
				window.location.replace(
					`https://${window.location.hostname}/${locale}/projects`
				)
			} else {
				window.location.replace(
					`http://${window.location.hostname}:${window.location.port}/${locale}/projects`
				)
			}
		}
	}, [])

	useEffect(() => {
		setAllUTM(window.location.pathname, locale)
		document.addEventListener("visibilitychange", function () {
			if (document.visibilityState === "hidden" && config.is_iphone) {
				window.localStorage.removeItem("WALLETCONNECT_DEEPLINK_CHOICE");
			}
		});
	}, []);

	useEffect(() => {
		if (userSign && lastProjectsIndex) {
			setSkipProjects(false);
		} else {
			setSkipProjects(true);
		}
	}, [userSign, lastProjectsIndex, addressInfo, refetchProjects]);

	useEffect(() => {
		if (userAddress && address) {
			if (address !== userAddress) {
				checkUTMOnRedirect(window.location.origin);
			}
		}
	}, [address]);

	useEffect(() => {
		const domain =
			window.location.origin === "https://ipntest.alfaline.dev" ||
			window.location.origin === "https://ipn.tools";
		if (address && domain) {
			if (addressInfo?.New_address === true) {
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					event: "registration",
					user_id: address,
				});
			} else if (addressInfo?.New_address === false) {
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					event: "login",
					user_id: address,
				});
			}
		}
	}, [addressInfo]);

	useEffect(() => {
		async function auth() {
			if (
				(userSign == '') &&
				connector &&
				address &&
				chain?.id &&
				settings?.sign_message
			) {
				login(settings?.sign_message, address, chain?.id)
			} else if (userSign !== '' && isConnected && settings?.sign_message) {
				const signAddress = await recoverMessageAddress({
					message: settings?.sign_message,
					signature: userSign
				})
				if (signAddress !== address) {
					login(settings?.sign_message, address, chain?.id)
				}
			}
		}

		auth()
	}, [
		connector,
		isConnected,
		isReconnecting,
		address,
		chain,
		userAddress,
		settings,
	]);


	const login = async (mess, address, chainId) => {
		const sign = await userLogIn(mess);
		const params = getAllUTM();

		if (address && chainId && sign) {
			await getAddressInfo({ address, userSign: sign, chainId, params });
		}
	};

	return (
		<Router>
			<>
				<Helmet>
					<title>{t('title')}</title>
				</Helmet>
				{!isConnected && !address ? (
					<LogIn 
						text={t}
						// path={`/${locale}`}
					/>
				) : (
					locale === "ru" ? (
						<div className="page_container">
							<Header 
								address={address} 
								text={t} 
							/>
							<main className="container">
								<Routes>
									<Route
										exact
										path={`/ru`}
										element={<Home />}
									/>
									<Route
										exact
										path={`/ru/projects`}
										element={
											<Projects
												settingsError={error}
												isSettingsLoading={isLoading}
												text={t}
												projects={projects}
												errorProjects={errorProjects}
												isLoadingProjects={isLoadingProjects}
												lastPostIndex={lastProjectsIndex}
												setLastPostIndex={setLastProjectsIndex}
											/>
										}
									/>

									<Route
										exact
										path={`/ru/projects/:projectId/list/:listId`}
										element={
											<List
												text={t}
											/>
										}
									/>
									<Route
										exact
										path={`/ru/projects/:projectId/rool/:roolId`}
										element={
											<Rool
												address={address}
												text={t}
											/>
										}
									/>
									<Route
										exact
										path={`/ru/projects/:projectId`}
										element={<Project refetchProjects={refetchProjects} text={t} />}
									/>

									<Route
										exact
										path={`/ru/sites/:siteId`}
										element={
											<Website
												text={t}
											/>
										}
									/>
									<Route
										exact
										path={`/ru/sites`}
										element={
											<Sites
												text={t}
											/>
										}
									/>

									<Route
										exact
										path={`/ru/projects/:projectId/rool/:roolId/:eventId`}
										element={
											<EventDetail
												text={t}
											/>
										}
									/>

									<Route
										exact
										path={`/ru/events/:eventId`}
										element={
											<EventDetail
												text={t}
											/>
										}
									/>
									<Route
										exact
										path={`/ru/events`}
										element={
											<Events
												text={t}
											/>
										}
									/>

									<Route
										exact
										path={`/ru/balance`}
										element={
											<Balance
												text={t}
											/>
										}
									/>

									<Route
										exact
										path={`/ru/profile`}
										element={
											<Profile
												address={address}
												text={t}
											/>
										}
									/>
								</Routes>
							</main>
						</div>
					) : (
						<div className="page_container">
							<Header 
								address={address} 
								text={t} 
							/>
							<main className="container">
								<Routes>
									<Route
										exact
										path={`/en`}
										element={<Home />}
									/>
									<Route
										exact
										path={`/en/projects`}
										element={
											<Projects
												settingsError={error}
												isSettingsLoading={isLoading}
												text={t}
												projects={projects}
												errorProjects={errorProjects}
												isLoadingProjects={isLoadingProjects}
												lastPostIndex={lastProjectsIndex}
												setLastPostIndex={setLastProjectsIndex}
											/>
										}
									/>

									<Route
										exact
										path={`/en/projects/:projectId/list/:listId`}
										element={
											<List
												text={t}
											/>
										}
									/>
									<Route
										exact
										path={`/en/projects/:projectId/rool/:roolId`}
										element={
											<Rool
												address={address}
												text={t}
											/>
										}
									/>
									<Route
										exact
										path={`/en/projects/:projectId`}
										element={<Project refetchProjects={refetchProjects} text={t} />}
									/>

									<Route
										exact
										path={`/en/sites/:siteId`}
										element={
											<Website
												text={t}
											/>
										}
									/>
									<Route
										exact
										path={`/en/sites`}
										element={
											<Sites
												text={t}
											/>
										}
									/>

									<Route
										exact
										path={`/en/projects/:projectId/rool/:roolId/:eventId`}
										element={
											<EventDetail
												text={t}
											/>
										}
									/>

									<Route
										exact
										path={`/en/events/:eventId`}
										element={
											<EventDetail
												text={t}
											/>
										}
									/>
									<Route
										exact
										path={`/en/events`}
										element={
											<Events
												text={t}
											/>
										}
									/>

									<Route
										exact
										path={`/en/balance`}
										element={
											<Balance
												text={t}
											/>
										}
									/>

									<Route
										exact
										path={`/en/profile`}
										element={
											<Profile
												address={address}
												text={t}
											/>
										}
									/>

								</Routes>
							</main>
						</div>
					)
				)}
			</>
		</Router>
	);
}

export default App;
