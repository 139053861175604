import React from "react";
import { useSelector } from "react-redux";
import Message from "../message";
import { updateErrorInfo } from "../../utils/support";
import { useApi } from "../../hooks";

const accountSelector = (s) => s.account;

const SignError = ({ text }) => {
	const { signError } = useSelector(accountSelector);
	const { userSign } = useApi();

	return signError ? (
		<Message text={updateErrorInfo(signError)} isError={true} />
	) : (
		!userSign && (
			<Message
				text={text("waiting_sign_message")}
				isWarning={true}
				isCenter={true}
			/>
		)
	);
};

export default SignError;
