import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

const api_uri = process.env.REACT_APP_API;

export const accountAPI = createApi({
	reducerPath: "accountAPI",
	baseQuery: fetchBaseQuery({ baseUrl: api_uri }),
	tagTypes: ["Account"],
	endpoints: (build) => ({
		getAddressInfo: build.mutation({
			query: ({address, userSign, chainId, params = {}}) => ({
				url: `address_info`,
				method: "GET",
				params: {
					input_address: address,
					network: chainId,
					...params
				},
				headers: {
                    sign: userSign
                }
			}),
			providesTags: (result) => ["Account"],
		}),
		fetchBlanceOperations: build.query({
			query: ({
				userSign,
				limit = 10,
				orderBy = "-created",
				skip = 0,
			}) => ({
				url: `/balance/history`,
				params: {
					skip: skip,
					limit: limit,
					order_by: orderBy,
				},
				headers: {
                    sign: userSign
                }
			}),
			providesTags: (result) => ["Account"],
		}),
		depositTokens: build.mutation({
			query: ({ body }) => ({
				url: `/user/topup`,
				method: "POST",
				body,
			}),
			providesTags: (result) => ["Account"],
		}),
	}),
});
