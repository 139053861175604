import React , { useEffect }from "react";
import "./index.scss";
import i18n from "../../i18n";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import GB from "../../commons/svg/GB.svg";
import RU from "../../commons/svg/RU.svg"

const LanguageButtons = ({props}) => {
	let language = localStorage.getItem('language');
	const { address, isConnected } = useAccount();

	function setRussianLanguage () {
		i18n.changeLanguage('ru');
		localStorage.setItem('language', 'ru');
		isConnected && address ? window.location.replace(`${window.location.origin}/ru/profile`) : window.location.replace(`${window.location.origin}/ru`);
	}

	function setEnglishLanguage () {
		i18n.changeLanguage('en');
		localStorage.setItem('language', 'en');
		isConnected && address ? window.location.replace(`${window.location.origin}/en/profile`) : window.location.replace(`${window.location.origin}/en`);
	}

	return (
		<>
			<button 
				className={ language == 'ru' ? `button ${props} button-active` : `button ${props}` }
				onClick={ () => { setRussianLanguage(); } }
				disabled={language === "ru"}
			>
				<img src={RU} alt="флаг РФ"/> Русский
			</button>
			<button
				className={ language == 'ru' ? `button ${props}` : `button ${props} button-active` }
				onClick={ () => { setEnglishLanguage(); } }
				disabled={language !== "ru"}
			>
				<img src={GB} alt="British flag"/> English
			</button>
		</>
	);
};

export default LanguageButtons;