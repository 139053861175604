import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNetwork, useSwitchNetwork, useWalletClient } from "wagmi";
import { usePublicClient, useAccount, useFeeData } from "wagmi";
import { getContract, parseUnits } from "viem";
import { config } from "../../app-config";
import CustomInput from "../../components/custom-input";
import PaginationContainer from "../../components/pagination-container";
import Message from "../../components/message";
import Modal from "../../blocks/modal";
import FormBtns from "../../blocks/form-btns";
import BlockLayout from "../../blocks/block-layout";
import {
	cutAddress,
	getLocaleUserTime,
	updateErrorInfo,
} from "../../utils/support";
import { accountAPI } from "../../services";
import { operationOptionsRu } from "../../constants/transactions-ru";
import { operationOptionsEng } from "../../constants/transactions-eng";
import { useApi } from "../../hooks";
import "./index.scss";
import { erc20ABI } from "wagmi";
import SignError from "../../components/sign-error";
import { locale } from "../../constants/constants";
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";

const Balance = ({ text }) => {
	const { userSign } = useApi();
	const { chain } = useNetwork();
	const { switchNetworkAsync } = useSwitchNetwork();
	const { address } = useAccount();
	const { data: feeData } = useFeeData();
	const [operationOptions, setOperationOptions] = useState(operationOptionsRu)
	const { t } = useTranslation();

	useEffect(() => {
		locale === 'ru' ? setOperationOptions(operationOptionsRu) : setOperationOptions(operationOptionsEng)
	});

	const [
		getAddressInfo,
		{ data: addressInfo, error: accountError, isLoading: isAccountLoading },
	] = accountAPI.useGetAddressInfoMutation();

	const publicClient = usePublicClient({
		chainId: config.currentChainId,
	});

	const { data: walletClient } = useWalletClient({
		chainId: config.currentChainId,
	});

	const contract = getContract({
		address: config.tetherBinanceAddress,
		abi: erc20ABI,
		publicClient,
		walletClient,
	});

	const [balanceAmount, setBalanceAmount] = useState("");
	const [successAmount, setSuccessAmount] = useState(0);
	const [firstPostIndex, setFirstPostIndex] = useState(0);
	const [lastPostIndex, setLastPostIndex] = useState(10);
	const [errorText, setErrorText] = useState("");
	const [orderBy, setOrderBy] = useState("-created");

	const [sortedDown, setSortedDown] = useState(false);
	const [isAddBalance, setIsAddBalance] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [skip, setSkip] = useState(true);

	const {
		data: operations,
		error: operationsError,
		isLoading: isLoadingOperations,
		refetch,
	} = accountAPI.useFetchBlanceOperationsQuery(
		{ userSign, limit: lastPostIndex, orderBy },
		{ skip }
	);

	const [
		depositTokens,
		{
			error: depositTokensError,
			isError: isDepositTokensError,
			isLoading: isDepositTokensLoading,
			isSuccess: isDepositTokensSuccess,
			reset: resetDepositTokensResult,
		},
	] = accountAPI.useDepositTokensMutation();
	
	// refetch balance after adding
	useEffect(() => {
		let timeout;
		if (isDepositTokensSuccess) {
			setBalanceAmount("");
			setProcessing(false);

			timeout = setTimeout(() => {
				refetch();
				getAddressInfo({ address, userSign, chainId: chain?.id });
				resetDepositTokensResult();
			}, 7000);
		} else {
			clearTimeout(timeout);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDepositTokensSuccess]);

	useEffect(() => {
		if (userSign && orderBy && lastPostIndex) {
			setSkip(false);
		}
	}, [userSign, orderBy, lastPostIndex]);

	useEffect(() => {
		if (address && userSign && chain?.id) {
			getAddressInfo({ address, userSign, chainId: chain?.id });
		}
	}, [address, userSign, chain, getAddressInfo]);

	useEffect(() => {
		if (sortedDown) {
			setOrderBy("created");
		} else {
			setOrderBy("-created");
		}
	}, [sortedDown]);

	const addBalanceHandle = async (e) => {
		if (e) {
			e.preventDefault();
		}

		let network;

		if (chain.id !== config.currentChainId) {
			network = await switchNetworkAsync?.(config.currentChainId);
		}

		if (chain.id === 56 || network.id === 56) {
			try {
				setProcessing(true);
				const to = config.tetherTransferContractAddress;
				const amountToSend = +balanceAmount / 100;
				let amount = 0;
				let gas;
				let depositFuncHash;

				const decimals = await contract.read.decimals();

				if (decimals) {
					amount = parseUnits(`${amountToSend}`, decimals);
				}

				if (amount) {
					gas = await contract.estimateGas
						.transfer({
							args: [to, amount],
						})
						.catch((error) => {
							console.log(error);
							toast.error(t("balance.error"), { position: "top-center"}) 
						});
				}

				if (gas) {
					depositFuncHash = await contract.write.transfer({
						args: [to, amount],
						account: address,
						gasPrice: feeData.gasPrice,
						gas: gas,
					});
				}

				if (depositFuncHash) {
					let harvestReceipt =
						await publicClient.waitForTransactionReceipt({
							hash: depositFuncHash,
						});

					if (
						chain &&
						harvestReceipt.status === "success" &&
						depositFuncHash
					) {
						const body = {
							tx: depositFuncHash,
							network: walletClient.chain.id,
						};

						await depositTokens({ body });
					}

					setTimeout(() => {
						refetch();
					}, 3000);
				}
			} catch (err) {
				console.log(err);
				setErrorText(
					err.details || err.reason || err.cause.reason || err.message
				);
				setProcessing(false);
			}
		}
	};

	const onCloseModal = () => {
		setIsModalOpen(false);
		setIsAddBalance(false);
		setErrorText("");
		setProcessing(false);
		if (!isDepositTokensSuccess) {
			resetDepositTokensResult();
		}

		setBalanceAmount("");
		setSuccessAmount(0);
	};

	const openAddBalance = () => {
		setIsAddBalance(true);
		setIsModalOpen(true);
	};

	const sort = () => {
		setSortedDown(!sortedDown);
	};

	const onBalanceInputChange = useCallback((value) => {
		setBalanceAmount(value);
		setSuccessAmount(value);
		setProcessing(false);
		setErrorText("");
	}, []);

	const notificationsSlice = useMemo(() => {
		return operations?.notifications?.slice(firstPostIndex, lastPostIndex);
	}, [firstPostIndex, lastPostIndex, operations?.notifications]);

	const updateProof = useCallback((address) => {
		const index = address?.indexOf("0x");
		const proofStart = address?.slice(0, index);
		return proofStart + cutAddress(address?.slice(index, address?.length));
	}, []);

	const tokenSuffixes = useCallback((count) => {
		const countString = count.toString();
		if (
			countString[countString.length - 1] === "1" &&
			countString[countString.length - 2] !== "1" &&
			localStorage.getItem('language') === 'ru'
		) {
			return "";
		} else if (
			(countString[countString.length - 1] === "2" &&
				localStorage.getItem('language') === 'ru' ||
				countString[countString.length - 1] === "3" &&
				localStorage.getItem('language') === 'ru' ||
				countString[countString.length - 1] === "4") &&
			countString[countString.length - 2] !== "1" &&
			localStorage.getItem('language') === 'ru'
		) {
			return text("suffixes.a");
		} else if (
			localStorage.getItem('language') === 'ru'
		) {
			return text("suffixes.ov");
		} else if (
			localStorage.getItem('language') === 'en' &&
			countString[countString.length - 1] !== "1"
		) {
			return text("suffixes.s");
		} else if (localStorage.getItem('language') === 'en') {
			return text("suffixes.zero");
		}
	});

	return (
		<div className="balance_page">
			<h1 className="visually-hidden">{text("balance.balance")}</h1>
			<div>
				<BlockLayout
					loading={!userSign || isAccountLoading}
					error={accountError}
					bigLoader={false}
				>
					<p className="title">
						{addressInfo?.balance + addressInfo?.bonus}{" "}
						{text("balance.token")}{tokenSuffixes(addressInfo?.balance + addressInfo?.bonus || 0)}
					</p>{" "}
				</BlockLayout>
			</div>

			<p className="text--small text--light_gray">
				{text("balance.from_them")} {addressInfo?.bonus}{" "}
				{text("balance.bonuses")}{" "}
				{text("balance.token")}{tokenSuffixes(addressInfo?.bonus || 0)}
			</p>

			<div className="balance_page__btns flex">
				<button
					className="btn  btn--green"
					type="button"
					onClick={openAddBalance}
				>
					{text("balance.add")}
				</button>
			</div>

			<SignError text={text} />

			<BlockLayout
				loading={isLoadingOperations || !userSign}
				error={operationsError}
			>
				<PaginationContainer
					pageCount={operations?.count}
					getFirstIndex={setFirstPostIndex}
					getLastIndex={setLastPostIndex}
					lastIndex={lastPostIndex}
					postPerPage={10}
				>
					<div className="table">
						<div className="table__row text--light_gray">
							<p className="table__column table__header first_column">
								<button
									className={`btn--no_styles date_btn ${sortedDown && "to_down"
										}`}
									type="button"
									onClick={sort}
								>
									{text("balance.date")}
								</button>
							</p>
							<p className="table__column table__header two_column">
								{text("balance.cause")}
							</p>
							<p className="table__column table__header three_column">
								{text("balance.amount")}
							</p>
						</div>

						{notificationsSlice?.map((item, i) => {
							const wdError = item.type === "WC",
								wdProcess = item.type === "WD",
								wdSuccess = item.type === "WV",
								isWithdrawal =
									wdError || wdProcess || wdSuccess,
								tpSuccess =
									isDepositTokensSuccess || !processing,
								tpProcess = processing,
								tpError = isDepositTokensError,
								tpSuccessClass = tpSuccess ? "success" : "",
								tpErrorClass = tpError ? "error" : "",
								tpProcessClass = tpProcess ? "process" : "",
								wdSuccessClass = wdSuccess ? "success" : "",
								wdErrorClass = wdError ? "error" : "",
								wdProcessClass = wdProcess ? "process" : "",
								itemTypeTitle = operationOptions?.find(
									(i) => i.value === item?.type
								)?.title;
							let proofAddress;

							if (
								item.type === "TX" &&
								item.proof?.includes("0x")
							) {
								proofAddress = updateProof(item.proof);
							}

							return (
								<div className="table__row" key={i + "bt"}>
									<div className="table__column  first_column">
										<p className="text  text--light_gray mobile_title">
											<button
												className={`btn--no_styles date_btn ${sortedDown && "to_down"
													}`}
												type="button"
												onClick={sort}
											>
												{text("balance.date")}
											</button>
										</p>

										<p className="text  text--light_gray">
											{getLocaleUserTime(
												item.created,
												locale,
												false
											)}
										</p>
									</div>

									<div className="table__column  two_column">
										<p className="text  text--light_gray mobile_title">
											{text("balance.cause")}
										</p>

										<div>
											{item.type === "TP" ? (
												<p
													className={`text  text--bold cause process ${tpSuccessClass} ${tpErrorClass} ${tpProcessClass}`}
												>
													{itemTypeTitle} -
													{tpProcess
														? text(
															"balance.in_process"
														)
														: text("balance.done")}
												</p>
											) : isWithdrawal ? (
												<p
													className={`text  text--bold cause process ${wdSuccessClass} ${wdErrorClass} ${wdProcessClass}`}
												>
													{itemTypeTitle}
												</p>
											) : (
												<p className="text  text--bold">
													{itemTypeTitle}
												</p>
											)}

											{item.type === "WV" ||
												item.type === "TP" ? (
												<p className="text  text--light_gray  text--small text--word-wrap">
													txid:{" "}
													{window.innerWidth > 560
														? item.proof
														: cutAddress(
															item.proof
														)}
												</p>
											) : (
												<p className="text  text--light_gray  text--small">
													{proofAddress
														? proofAddress
														: item.proof}
												</p>
											)}
										</div>
									</div>

									<div className="table__column  three_column">
										<p className="text  text--light_gray  mobile_title">
											{text("balance.amount")}
										</p>

										<p className="text text--light_gray">
											{item.type === "TP" ||
												item.type === "BS"
												? "+"
												: ""}
											&nbsp;{item.amount.toFixed(0)}
											&nbsp;{text("balance.token")}{tokenSuffixes(item.amount.toFixed(0))}
										</p>
									</div>
								</div>
							);
						})}
					</div>
				</PaginationContainer>
			</BlockLayout>

			{isModalOpen && (
				<Modal closeModalHandle={onCloseModal}>
					{/* Add balance modal */}
					{isAddBalance && (
						<form onSubmit={addBalanceHandle}>
							<h2 className="title">
								{text("balance.add_balance")}
							</h2>
							<CustomInput
								type="number"
								value={balanceAmount}
								setValue={onBalanceInputChange}
								text={text("balance.amount")}
								helperText={text("balance.add_helper_text")}
								isReadOnly={
									isDepositTokensLoading || processing
								}
								addRoundFunc={true}
								resetStatus={resetDepositTokensResult}
							/>
							<FormBtns
								waitingText={text("balance.adding")}
								isLoading={isDepositTokensLoading || processing}
								confirmText={text("balance.add")}
								onCloseHandle={onCloseModal}
								addDeleteBtn={false}
								isDisabledConfirmBtn={balanceAmount.length === 0}
							/>
							{errorText && (
								<Message text={errorText} isError={true} />
							)}
							{isDepositTokensSuccess && (
								<Message
									text={`${text(
										"balance.adding_succes"
									)} ${successAmount} ${text("balance.token")}${tokenSuffixes(successAmount)}`}
									isSuccess={true}
								/>
							)}
							{isDepositTokensError && (
								<Message
									text={updateErrorInfo(depositTokensError)}
									isError={true}
								/>
							)}
						</form>
					)}
				</Modal>
			)}
		</div>
	);
};

export default Balance;
