import React, { useState, useEffect } from "react";
import BlockPagination from "../../blocks/block-pagination";

const PaginationContainer = ({
	pageCount = 0,
	postPerPage = 10,
	getFirstIndex = () => {},
	firstIndex = 0,
	getLastIndex = () => {},
	lastIndex = 10,
	addScrollTo = false,
	children,
}) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [pageNumber, setPageNumber] = useState(1);
	const [firstPostIndex, setFirstPostIndex] = useState(firstIndex);
	const [lastPostIndex, setLastPostIndex] = useState(lastIndex);

	useEffect(() => {
		if (addScrollTo) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		}
	}, [lastPostIndex, firstPostIndex, addScrollTo]);

	useEffect(() => {
		if (pageCount) {
			setPageNumber(Math.ceil(pageCount / postPerPage));
		}
	}, [pageCount, postPerPage]);

	useEffect(() => {
		setFirstPostIndex(lastPostIndex - postPerPage);
		getFirstIndex(lastPostIndex - postPerPage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastPostIndex, postPerPage]);

	useEffect(() => {
		setLastPostIndex(currentPage * postPerPage);
		getLastIndex(currentPage * postPerPage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, postPerPage]);

	return (
		<div className="paginationContainer">
			{children}
			{pageCount > postPerPage && (
				<BlockPagination
					itemsCount={pageCount}
					pageCount={pageNumber}
					setPage={setCurrentPage}
					currentPage={currentPage}
					resultFrom={firstPostIndex + 1}
					resultTo={
						currentPage === pageNumber ? pageCount : lastPostIndex
					}
				/>
			)}
		</div>
	);
};

export default PaginationContainer;
