import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import PaginationContainer from "../../../components/pagination-container";
import AddNewElement from "../../../components/add-new-element";
import { ReactComponent as Book } from "../../../commons/svg/book.svg";
import Modal from "../../modal";
import AddListItemForm from "../add-list-item-form";
import { listAPI } from "../../../services";
import BlockLayout from "../../../blocks/block-layout";
import { useApi } from "../../../hooks/components/useApi";

import "./index.scss";

const ProjectList = ({ id, sign, text, refetchProjectInfo }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [firstPostIndex, setFirstPostIndex] = useState(0);
	const [lastPostIndex, setLastPostIndex] = useState(10);
	const [skip, setSkip] = useState(true);
	const { userSign } = useApi();

	const { projectLists, count, listsError, listsLoading } =
		listAPI.useFetchListsQuery(
			{ sign: userSign, projectId: id, limit: lastPostIndex },
			{
				selectFromResult: ({ data, error, isLoading }) => {
					return {
						projectLists: data?.project_lists,
						count: data?.count + 1,
						listsError: error,
						listsLoading: isLoading,
					};
				},
				skip,
			}
		);

	useEffect(() => {
		if (id && lastPostIndex) {
			setSkip(false);
		}
	}, [id, lastPostIndex]);

	const onCloseModal = () => {
		setIsModalOpen(false);
	};

	const updatedLists = useMemo(() => {
		if (projectLists) {
			return [
				{
					addNewELBtn: true,
				},
				...projectLists,
			];
		}
	}, [projectLists]);

	const addressSuffixes = useCallback((count) => {
		const countString = count.toString();
		if (
			countString[countString.length - 1] === "1" &&
			countString[countString.length - 2] !== "1" &&
			localStorage.getItem('language') == 'ru'
		) {
			return "";
		} else if (
			(countString[countString.length - 1] === "2" &&
			localStorage.getItem('language') == 'ru' ||
				countString[countString.length - 1] === "3" &&
				localStorage.getItem('language') == 'ru' ||
				countString[countString.length - 1] === "4") &&
			countString[countString.length - 2] !== "1" &&
			localStorage.getItem('language') == 'ru'
		) {
			return text("suffixes.a");
		} else if (
			localStorage.getItem('language') == 'ru'
		) {
			return text("suffixes.ov");
		} else if (
			localStorage.getItem('language') == 'en' && 
			countString[countString.length - 1] !== "1"
		) {
			return text("suffixes.s");
		} else if (localStorage.getItem('language') == 'en') {
			return text("suffixes.zero");
		}
	}, []);

	return (
		<>
			<PaginationContainer
				pageCount={count}
				getFirstIndex={setFirstPostIndex}
				getLastIndex={setLastPostIndex}
				lastIndex={lastPostIndex}
			>
				<BlockLayout loading={listsLoading && !sign} error={listsError}>
					<div className="grid project_list">
						{updatedLists
							?.slice(firstPostIndex, lastPostIndex)
							.map((item, i) => {
								let color;
								if ((i + 1) % 2 === 0) {
									color = "blue";
								}

								if ((i + 1) % 3 === 0) {
									color = "green";
								}

								if ((i + 1) % 4 === 0) {
									color = "purple";
								}

								return item.addNewELBtn ? (
									<AddNewElement
										key={item.id + "new"}
										text={text("project.add_new_list_text")}
										onClickHandle={() => {
											setIsModalOpen(true);
										}}
									/>
								) : (
									<Link
										key={item.id + "l"}
										to={`list/${item.id}`}
										className={
											"project_list__item  flex flex--center_space_between " +
											color
										}
									>
										<p
											className={`text--dark  project_list__text ${
												item.name.includes("0x") &&
												"text--word-wrap"
											}`}
										>
											{item.name}
										</p>
										<p className="addresses flex  flex--align-items-center">
											<span className="project_list__icon_container flex flex--center">
												<Book className="book_icon" />
											</span>

											<span>
												{item.body.length}&nbsp;
												{text(
													"project.rool_card_addresses"
												)}
												{addressSuffixes(
													item.body.length
												)}
											</span>
										</p>
									</Link>
								);
							})}
					</div>
				</BlockLayout>
			</PaginationContainer>

			{isModalOpen && (
				<Modal
					isBigSize={true}
					isNotFullWidth={true}
					closeModalHandle={onCloseModal}
				>
					<AddListItemForm
						confirmBtnText={text("project.add_btn_text")}
						addDeleteBtn={false}
						text={text}
						onCansel={onCloseModal}
						refetchProjectInfo={refetchProjectInfo}
					/>
				</Modal>
			)}
		</>
	);
};

export default ProjectList;
