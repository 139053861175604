import React from "react";
import { useTranslation } from "react-i18next";
import FormBtns from "../form-btns";
import Modal from "../modal";
import Message from "../../components/message";
import { updateErrorInfo } from "../../utils/support";

const ConfirmDeleteModal = ({
	deleteEvent = "",
	onCloseModalHandle = () => {},
	onDeleteHandle = () => {},
	isLoading = false,
	isError = false,
	error = {},
}) => {
	const { t } = useTranslation();

	return (
		<Modal closeModalHandle={onCloseModalHandle}>
			<div>
				<form onSubmit={onDeleteHandle} className="projects__form">
					<p className="subtitle">
						{t("delete_modal.delete_text")} {deleteEvent}?
					</p>
					<FormBtns
						isLoading={isLoading}
						confirmText={t("delete_modal.confirm_btn_text")}
						addDeleteBtn={false}
						onCloseHandle={onCloseModalHandle}
						waitingText={t("delete_modal.loading_btn_text")}
					/>
				</form>

				{isError && (
					<Message text={updateErrorInfo(error)} isError={true} />
				)}
			</div>
		</Modal>
	);
};

export default ConfirmDeleteModal;
