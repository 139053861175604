import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import {
	formatAddress,
	cutAddress,
	getLocaleUserTime,
} from "../../utils/support";
import { useWeb3 } from "../../hooks";
import { transactionTypeOptionsRu } from "../../constants/transactions-ru";
import { transactionTypeOptionsEng } from "../../constants/transactions-eng";
import { locale } from "../../constants/constants";

const EventCard = ({
	data,
	text,
	paidAmount = 0,
	addProjectInfo = false,
	noSeparateAddress = false,
}) => {
	const { id, created, request, sent } = data;
	const { fromWei } = useWeb3();
	const [addressArr, setAddressArr] = useState([]);
	const [cardColorClass, setCardColorClass] = useState("");
	const [eventType, setEventType] = useState('');

	useEffect(() => {
		let language = localStorage.getItem('language')
		let transactionTypeOptions = language === 'ru' ? transactionTypeOptionsRu : transactionTypeOptionsEng;
		const event = transactionTypeOptions?.find(
				(i) => i.value === request?.info?.type
			)?.title;
		setEventType(event);
	}, []);

	useEffect(() => {
		const address = request?.logs[0].from;
		if (address) {
			setAddressArr(formatAddress(address));
		}
	}, [request?.logs]);

	// set card color class
	useEffect(() => {
		if (!sent) {
			setCardColorClass("error");
		}
		if (!request?.info?.on_site) {
			setCardColorClass("no_webhook");
		}
	}, [sent, request?.info?.on_site]);

	return (
		<Link
		to={`${id}`}
			className={"btn card event_card text--small  " + cardColorClass}
		>
			<p className="event_card__title subtitle">{eventType}</p>

			<div className="flex  flex--center_space_between event_card__inner">
				<div className="event_card__block">
					<p className="event_card__date">
						{getLocaleUserTime(created, locale)}
					</p>

					<div className="flex">
						{addressArr.length > 0 &&
							(window.innerWidth > 560 ? (
								!noSeparateAddress ? (
									<>
										<p>
											from 
											{/* 0x */}
										</p>

										<div className="grid  event_card__address">
											{addressArr.map((item, i) => (
												<p key={"ec" + i}>{item}</p>
											))}
										</div>
									</>
								) : (
									<p className="text--word-wrap">
										<span className="text--light_gray">
											from{" "}
										</span>
										{request?.logs[0].from}
									</p>
								)
							) : (
								cutAddress(request?.logs[0].from)
							))}

						{request?.logs[0].data && (
							<p className="text--word-wrap">
								<span className="text--light_gray">amount</span>
								{Number(fromWei(request?.logs[0].data))}&nbsp;{text("balance.token")}
							</p>
						)}
					</div>

					{/* project and rool */}
					{addProjectInfo && (
						<div>
							<span className="text--light_gray">
								{text("events.project_and_rool")}&nbsp;
							</span>

							<span className="text">
								{request?.info?.project}/
							</span>

							<span className="text--word-wrap">
								{request?.info?.rule}
							</span>
						</div>
					)}
				</div>

				{/* webhook */}
				<div className="event_card__block">
					{!request?.info?.on_site ? (
						<p>{text("events.now_webhook")}</p>
					) : sent ? (
						<p className="text--success">
							{text("events.webhook_send")}
						</p>
					) : (
						<p className="text--error">
							{text("events.webhook_not_send")}
						</p>
					)}

					{/* telegram */}
					{request?.info?.telegram ? (
						<p>{text("events.note_to_telegram")}</p>
					) : (
						<p>{text("events.no_telegram")}</p>
					)}

					{/* Amount */}
					{paidAmount > 0 ? (
						<p>
							{text("events.decommissioned")} {paidAmount} {text("balance.token")}
						</p>
					) : (
						<p>{text("events.nothing_decommissioned")}</p>
					)}
				</div>
			</div>
		</Link>
	);
};

export default EventCard;
