import React, { useState, useEffect, useMemo } from "react";
import AddNewElement from "../../components/add-new-element";
import ProjectCard from "../../components/project-card";
import FormBtns from "../../blocks/form-btns";
import Modal from "../../blocks/modal";
import "./index.scss";
import InputTitle from "../../components/input-title";
import { useApi } from "../../hooks";
import Message from "../../components/message";
import ConfirmDeleteModal from "../../blocks/confirm-delete-modal";
import { projectAPI } from "../../services";
import { updateErrorInfo } from "../../utils/support";
import PaginationContainer from "../../components/pagination-container";
import SignError from "../../components/sign-error";
import BlockLayout from "../../blocks/block-layout";
import { id } from "ethers/lib/utils";
import { toast } from 'react-toastify';

const Projects = ({
	isSettingsLoading = false,
	text,
	projects,
	lastPostIndex,
	setLastPostIndex,
	isLoadingProjects,
	errorProjects,
	error
}) => {
	const { userSign } = useApi();
	const [firstPostIndex, setFirstPostIndex] = useState(0);

	const [
		createProject,
		{
			error: createError,
			isError: isCreateError,
			isLoading: isCreateLoading,
			isSuccess: isCreateSuccess,
			reset: resetCreateResult,
		},
	] = projectAPI.useCreateProjectMutation();
	const [
		updateProject,
		{
			error: updateError,
			isError: isUpdateError,
			isLoading: isUpdateLoading,
			isSuccess: isUpdateSuccess,
			reset: resetUpdateResult,
		},
	] = projectAPI.useUpdateProjectMutation();
	const [
		deleteProject,
		{
			error: deleteError,
			isError: isDeleteError,
			isLoading: isDeleteLoading,
			isSuccess: isDeleteSuccess,
			reset: resetDeleteResult,
		},
	] = projectAPI.useDeleteProjectMutation();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [addDeleteBtn, setAddDeleteBtn] = useState(false);
	const [isEdit, setIsEdit] = useState(false);

	const [projectTitle, setProjectTitle] = useState("");
	const [oldProjectTitle, setOldProjectTitle] = useState("");
	const [confirmBtnText, setConfirmBtnText] = useState(
		text("projects.add_btn_text")
	);
	const [errorText, setErrorText] = useState("");

	const successMessageText = isEdit
		? text("projects.success_edit_message")
		: text("projects.success_create_message");
	
	const notifySuccess = () => toast( successMessageText, 
		{ position: "top-center"}
	);	

	useEffect(() => {
		if (isDeleteSuccess) {
			setOpenDeleteModal(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDeleteSuccess]);

	useEffect(() => {
		if (localStorage.getItem('language') == "ru") {
			setConfirmBtnText(text("projects.add_btn_text"));
		}
		else if (localStorage.getItem('language') == "en") {
			setConfirmBtnText(text("projects.add_btn_text"));
		}
	}, []);

	useEffect(() => {
		if (isCreateSuccess) {
			resetForm();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCreateSuccess]);

	const resetForm = () => {
		setProjectTitle("");
	};

	const resetResult = () => {
		resetUpdateResult();
		resetCreateResult();
	};

	const onCofirm = async (e) => {
		e.preventDefault();
		if (projectTitle && userSign.length > 0 && !errorText) {
			const body = {
				name: projectTitle,
			};
			await createProject({body, sign: userSign}).unwrap();
			onCloseModal();
			notifySuccess();
		}
	};

	const onEditProject = async (e) => {
		e.preventDefault();
		if (oldProjectTitle && userSign && projectTitle && !errorText) {
			const body = {
				old_name: oldProjectTitle,
				new_name: projectTitle,
			};

			await updateProject({body: body, sign: userSign,});
			onCloseModal();
			notifySuccess();
		}
	};

	const onDelete = async (e) => {
		e.preventDefault();
		if (projectTitle && userSign.length > 0 && !errorText) {
			const body = {
				name: projectTitle
			};
			await deleteProject({body: body, sign: userSign,}).unwrap();
		}
	};

	const onOpenDeleteModal = () => {
		setOpenDeleteModal(true);
		onCloseModal();
	};

	const onCloseDeleteModal = () => {
		setOpenDeleteModal(false);
		resetDeleteResult();
	};

	const onCloseModal = () => {
		setIsModalOpen(false);
		setIsEdit(false);
		resetResult();
		setErrorText("");
		setAddDeleteBtn(false);
	};

	const addNewElHandle = () => {
		resetForm();
		setIsModalOpen(true);
	};

	const edit = (value, id) => {
		setIsEdit(true);
		setOldProjectTitle(value);
		setProjectTitle(value);
		setAddDeleteBtn(true);
		setIsModalOpen(true);
		setConfirmBtnText(text("projects.edit_btn_text"));
	};

	const updatedProjects = useMemo(() => {
		if (projects) {
			return [
				{
					addNewELBtn: true,
				},
				...projects,
			];
		}
	}, [projects]);

	return (
		<div className="projects">
			<h1 className="visually-hidden">{text("projects.title")}</h1>
			<PaginationContainer
				pageCount={projects?.count + 1}
				getFirstIndex={setFirstPostIndex}
				getLastIndex={setLastPostIndex}
				addScrollTo={true}
			>
				<SignError text={text} />

				<BlockLayout
					loading={
						isLoadingProjects ||
						!userSign ||
						isSettingsLoading ||
						!userSign
					}
					error={errorProjects}
				>
					<>
						{updatedProjects
							?.slice(firstPostIndex, lastPostIndex)
							.map((item, i) => {
								return item.addNewELBtn ? (
									<AddNewElement
										key={i + "new"}
										text={text("projects.new_project")}
										onClickHandle={addNewElHandle}
									/>
								) : (
									<ProjectCard
										text={text}
										key={i + "pp"}
										title={item.name && item.name}
										roolsCount={item.events}
										listCount={item.lists}
										eventsCount={item.webhooks}
										newEventsCount={
											item.new_webhooks &&
											item.new_webhooks
										}
										id={item.id}
										editHandle={edit}
									/>
								);
							})}
					</>
				</BlockLayout>
			</PaginationContainer>

			{isModalOpen && (
				<Modal closeModalHandle={onCloseModal}>
					<div>
						<form
							onSubmit={isEdit ? onEditProject : onCofirm}
							className="projects__form"
						>
							<InputTitle
								type="text"
								value={projectTitle}
								setValue={setProjectTitle}
								label={text(
									"projects.input_title_placeholder"
								)}
								placeholder={text(
									"projects.input_title_placeholder"
								)}
								resetStatus={resetResult}
								getErrorText={setErrorText}
							/>

							<FormBtns
								isLoading={isCreateLoading || isUpdateLoading}
								waitingText={text("projects.loading_btn_text")}
								confirmText={confirmBtnText}
								deleteText={text("projects.delete_btn_text")}
								addDeleteBtn={addDeleteBtn}
								onCloseHandle={onCloseModal}
								isDisabledConfirmBtn={!projectTitle}
								onDeleteHandle={onOpenDeleteModal}
							/>
						</form>

						{/* {(isUpdateSuccess || isCreateSuccess) && (
							<Message
								text={successMessageText}
								isSuccess={true}
							/>
						)} */}
						{isCreateError && (
							<Message
								text={updateErrorInfo(createError)}
								isError={true}
							/>
						)}
						{isUpdateError && (
							<Message
								text={updateErrorInfo(updateError)}
								isError={true}
							/>
						)}
					</div>
				</Modal>
			)}

			{openDeleteModal && (
				<ConfirmDeleteModal
					isLoading={isDeleteLoading}
					deleteEvent={text("projects.delete_event")}
					onCloseModalHandle={onCloseDeleteModal}
					onDeleteHandle={onDelete}
					isError={isDeleteError}
					error={deleteError}
				/>
			)}
		</div>
	);
};

export default Projects;
