export const isMainnet = true;
console.log('isMainnet ' + isMainnet)

export const config = {
	lang: 'ru',
	walletConnectId: "8fa02f1c819014a2c7ecfdb9df986233",
	bonusTokenAddress: "0x8DFdAEc7FAE3A98D4Fcb597bE278FDd4A12D1Be5", // IPNT 
	// tetherAvaxAddress: "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
	tetherBinanceAddress: "0x55d398326f99059fF775485246999027B3197955",
	transferContractAddress: "0x4BFe13FD065DB627425068D203BA5237d6f185b1",
	tetherTransferContractAddress: "0x7bd186fc60b1A7cAFe065cb6C296A44C6c16Fd03",
	currentChainId: 56,
	version: "2.0.0",
	is_iphone: iOS(),
	is_mobile: mobileDetect(),
}

function iOS() {
	return (
		[
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod',
		].includes(navigator.platform) ||
		// iPad on iOS 13 detection
		(navigator.userAgent.includes('Mac') && 'ontouchend' in document)
	);
}

function mobileDetect() {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i
    .test(window.navigator.userAgent)) {
    return true;
  } else return false;
}
