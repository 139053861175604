import React, { useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Close } from "../../commons/svg/closeForBtn.svg";
import { ReactComponent as ThreeDots } from "../../commons/svg/three_dots.svg";
import { ReactComponent as Edit } from "../../commons/svg/edit.svg";
import { locale } from "../../constants/constants";
import "./index.scss";

const ProjectCard = ({
	title = "",
	text,
	roolsCount = 0,
	listCount = 0,
	eventsCount = 0,
	newEventsCount = 0,
	id = "",
	editHandle = () => {},
}) => {
	const openedEdit = useRef();
	const openEditBtn = useRef();

	const openEditHandle = () => {
		openEditBtn.current.classList.toggle("opened");
		openedEdit.current.classList.toggle("open");
	};

	const onEditHandle = () => {
		openEditBtn.current.classList.toggle("opened");
		openedEdit.current.classList.toggle("open");

		editHandle(title, id);
	};

	const eventsSuffixes = useCallback((count) => {
		const countString = count.toString();
		if (
			countString[countString.length - 1] === "1" &&
			countString[countString.length - 2] !== "1" &&
			localStorage.getItem('language') == 'ru'
		) {
			return text("suffixes.e");
		} else if (
			(countString[countString.length - 1] === "2" && 
			localStorage.getItem('language') == 'ru' ||
				countString[countString.length - 1] === "3" &&
				localStorage.getItem('language') == 'ru' ||
				countString[countString.length - 1] === "4") &&
			countString[countString.length - 2] !== "1" &&
			localStorage.getItem('language') == 'ru'
		) {
			return text("suffixes.ya");
		} else if (
			localStorage.getItem('language') == 'ru'
		) {
			return text("suffixes.y");
		} else if (
			localStorage.getItem('language') == 'en' && 
			countString[countString.length - 1] !== "1"
		) {
			return text("suffixes.s");
		} else if (localStorage.getItem('language') == 'en') {
			return text("suffixes.zero");
		}
	}, []);

	const listsSuffixes = useCallback((count) => {
		const countString = count.toString();
		if (
			countString[countString.length - 1] === "1" &&
			countString[countString.length - 2] !== "1" &&
			localStorage.getItem('language') == 'ru'
		) {
			return text("suffixes.ok");
		} else if (
			(countString[countString.length - 1] === "2" &&
			localStorage.getItem('language') == 'ru' ||
				countString[countString.length - 1] === "3" && 
				localStorage.getItem('language') == 'ru' ||
				countString[countString.length - 1] === "4") &&
			countString[countString.length - 2] !== "1" &&
			localStorage.getItem('language') == 'ru'
		) {
			return text("suffixes.ka");
		} else if (
			localStorage.getItem('language') == 'ru'
		) {
			return text("suffixes.kov");
		} else if (
			localStorage.getItem('language') == 'en' && 
			countString[countString.length - 1] !== "1"
		) {
			return text("suffixes.s");
		} else if (localStorage.getItem('language') == 'en') {
			return text("suffixes.zero");
		}
	}, []);

	const roolsSuffixes = useCallback((count) => {
		const countString = count.toString();
		if (
			countString[countString.length - 1] === "1" &&
			countString[countString.length - 2] !== "1" &&
			localStorage.getItem('language') == 'ru'
		) {
			return text("suffixes.o");
		} else if (
			(countString[countString.length - 1] === "2" ||
				countString[countString.length - 1] === "3" ||
				countString[countString.length - 1] === "4") &&
			countString[countString.length - 2] !== "1" &&
			localStorage.getItem('language') == 'ru'
		) {
			return text("suffixes.a");
		} else if (
			localStorage.getItem('language') == 'ru'
		) {
			return text("");
		} else if (
			localStorage.getItem('language') == 'en' && 
			countString[countString.length - 1] !== "1"
		) {
			return text("suffixes.s");
		} else if (localStorage.getItem('language') == 'en') {
			return text("suffixes.zero");
		}
	}, []);

	return (
		<div className="project_card__container">
			<div className="edit_project">
				<button
					className="btn open_edit  flex  flex--center"
					ref={openEditBtn}
					type="button"
					onClick={openEditHandle}
				>
					<span>
						<Close className="close" />

						<ThreeDots className="dots" />
					</span>
				</button>

				<button
					ref={openedEdit}
					className="btn edit_project__btn  flex  flex--align-items-center"
					type="button"
					onClick={onEditHandle}
				>
					<Edit className="edit" />
					<span>
						{text("projects.edit_project_text_part_1")}{" "}{text("projects.edit_project_text_part_2")}
					</span>
				</button>
			</div>

			<Link
				to={`/${locale}/projects/${id}`}
				className="project_card  flex  flex--align-items-center card--big"
			>
				<p className="card__title  text--center">{title}</p>

				<div className="card__info  flex  flex--align-items-center">
					<p>
						{roolsCount} {text("projects.project_card_rools")}
						{roolsSuffixes(roolsCount)}
					</p>

					<p>
						{listCount} {text("projects.project_card_lists")}
						{listsSuffixes(listCount)}
					</p>

					<p>
						<span>
							{eventsCount} {text("projects.project_card_events")}
							{eventsSuffixes(eventsCount)}
						</span>{" "}
						<span>
							+ {newEventsCount}{" "}
							{text("projects.project_card_new_events")}
						</span>
					</p>
				</div>
			</Link>
		</div>
	);
};

export default ProjectCard;
