import React, { useState, useEffect, useMemo, useCallback } from "react";
import "./index.scss";
import LanguageButtons from "../../components/language-buttons";
import { ReactComponent as LogOutIcon } from "../../commons/svg/log_out.svg";
import { ReactComponent as AddApiKeyIcon } from "../../commons/svg/plus2.svg";
import { useWeb3 } from "../../hooks/components/useWeb3";
import { formatAddressProfile } from "../../utils/support";
import ProjectCardModal from "../../blocks/project-card-modal";
import { getApiKey, getKeyCreateTime } from "../../services/apiKey";

const Profile = ({ address = "", text }) => {
    const { onDisconnect } = useWeb3();
    const [openProjectCardModal, setOpenProjectCardModal] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [keyCopied, setKeyCopied] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [timestamp, setTimestamp] = useState('');

    let createTime = timestamp === '' ? text("profile.modal_info") : `${text("profile.modal_info_created")}: ${timestamp}`;

    const logOut = useCallback(() => {
        onDisconnect();
    }, []);

    useEffect(() => {
        getKeyCreateTime(setTimestamp);
    }, []);

    const onCloseDeleteModal = () => {
        setOpenProjectCardModal(false);
        setFormSubmitted(false);
        setKeyCopied(false);
    };

    const onCreateKeyHandle = async (e) => {
        e.preventDefault();
        getApiKey(setApiKey);
        setFormSubmitted(true);
    };

    const onCopyKeyHandle = (e) => {
        e.preventDefault();
        let copyText = document.getElementById("myInput");
        copyText.select();
        document.execCommand("copy");
        setKeyCopied(true);
    }

    return (
        <div className="profile">
            <h1 className="title">{text("profile.profile")}</h1>
            <span className="profile__span">{text("profile.interface")}</span>
            <div className="adress__button-box">
                <LanguageButtons props={`button-footer`} />
            </div>
            <span className="profile__span">{text("profile.profile_id")}</span>
            <p className="flex  address__address">
                {address}
            </p>
            <button className="btn btn__exit" onClick={logOut}>
                <LogOutIcon />
                <span>{text("header.exit")}</span>
            </button>
            <h2>{text("profile.api_key")}</h2>
            <button
                className="btn btn--green btn__profile"
                onClick={
                    () => setOpenProjectCardModal(!openProjectCardModal)
                }
            >
                <AddApiKeyIcon />
                <span>{text("profile.create_key")}</span>
            </button>
            <span className="profile__span profile__span_time">{createTime}</span>
            {openProjectCardModal && (
                <ProjectCardModal
                    onCreateKeyHandle={onCreateKeyHandle}
                    onCopyKeyHandle={onCopyKeyHandle}
                    onCloseModalHandle={onCloseDeleteModal}
                    formSubmitted={formSubmitted}
                    apiKey={apiKey}
                    keyCopied={keyCopied}
                />
            )}
        </div>
    );
};

export default Profile;