import React from "react";
import { useNavigate, Link } from "react-router-dom";
import Loader from "../loader";
import Message from "../message";
import { ReactComponent as ArrLeft } from "../../commons/svg/projects/arr.svg";
import { updateErrorInfo, cutAddress } from "../../utils/support";
import "./index.scss";

const PageHeaderWithArr = ({
	text = "",
	to = "",
	isLoading = false,
	isError = false,
	error = {},
	isBtnToPrev = false,
	roolId
}) => {
	const navigate = useNavigate();

	return (
		<>
			{isLoading ? (
				<Loader />
			) : isError ? (
				<Message text={updateErrorInfo(error)} isError={true} />
			) : isBtnToPrev ? (
				<button
					className="page_header  flex  flex--align-items-center btn--no_styles"
					type="button"
					onClick={() => navigate(-1)}
				>
					<p className="arr_container flex  flex--center">
						<ArrLeft className="page_header__arr" />
					</p>

					<p className="title  title--big">
						{text.includes("0x") ? cutAddress(text) : text}
						{roolId && <sapn className='grey'>{`[id:${roolId}]`}</sapn>}
					</p>
				</button>
			) : (
				<Link
					to={to}
					className="page_header  flex  flex--align-items-center page_header__link"
				>
					<p className="arr_container flex  flex--center">
						<ArrLeft className="page_header__arr" />
					</p>
					<p className="title  title--big">
						{text.includes("0x") ? cutAddress(text) : text}
					</p>
				</Link>
			)}
		</>
	);
};

export default PageHeaderWithArr;
