import React from "react";
import './index.scss';

const SubpageLayout = ({children}) => {
    return (
        <div className="subpage_main grid  grid--two_columns">
            {children}
        </div>
    )
}

export default SubpageLayout;