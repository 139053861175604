import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import PageHeaderWithArr from "../../components/page_header_with_arr";
import SubpageLayout from "../../components/subpage-layout";
import PaginationContainer from "../../components/pagination-container";
import Modal from "../../blocks/modal";
import AddListForm from "../../blocks/project/add-list-item-form";
import ConfirmDeleteModal from "../../blocks/confirm-delete-modal";
import BlockLayout from "../../blocks/block-layout";
import { cutAddress, formatAddressList } from "../../utils/support";
import { listAPI } from "../../services";
import "./index.scss";
import { useApi } from "../../hooks/components/useApi";
import { locale } from "../../constants/constants";

const List = ({ text }) => {
	const navigate = useNavigate();
	let { listId, projectId } = useParams();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [firstPostIndex, setFirstPostIndex] = useState(0);
	const [lastPostIndex, setLastPostIndex] = useState(10);
	const [skip, setSkip] = useState(true);
	const { userSign } = useApi();

	const { list, usedEvents, error, isLoading } = listAPI.useFetchListQuery(
		{ sign: userSign, projectId: projectId, listId: listId },
		{
			selectFromResult: ({ data, error, isLoading, refetch }) => {
				return {
					list: data?.project_lists[0],
					usedEvents: data?.used_events,
					error: error,
					isLoading: isLoading,
					refetch: refetch,
				};
			},
			skip,
		}
	);

	const [
		deleteList,
		{
			error: deleteError,
			isError: isDeleteError,
			isLoading: isDeleteLoading,
			isSuccess: isDeleteSuccess,
			reset: resetDeleteResult,
		},
	] = listAPI.useDeleteListMutation();

	useEffect(() => {
		if (listId && projectId) {
			setSkip(false);
		}
	}, [listId, projectId]);

	useEffect(() => {
		if (isDeleteSuccess) {
			setOpenDeleteModal(false);
			navigate(`/${locale}/projects/${projectId}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDeleteSuccess]);

	const deleteListHandle = async (e) => {
		e.preventDefault();
		if (projectId && listId) {
			const body = {
				project_id: projectId,
				list_id: listId
			};

			await deleteList({ sign: userSign, body });
		}
	};

	const onOpenDeleteModal = () => {
		setOpenDeleteModal(true);
		onCloseModal();
	};

	const onCloseModal = () => {
		setIsModalOpen(false);
	};

	const onCloseDeleteModal = () => {
		setOpenDeleteModal(false);
		resetDeleteResult();
	};

	return (
		<div className="li_page">
			<PageHeaderWithArr
				text={list?.name}
				isBtnToPrev={true}
				isLoading={isLoading}
				error={error}
				isError={error}
			/>

			<SubpageLayout>
				<section>
					<div className="section text">
						<h2 className="subtitle">{text("list.address")}</h2>

						<button
							className="btn btn--thin"
							type="button"
							onClick={() => {
								setIsModalOpen(true);
							}}
						>
							{text("list.edit_list")}
						</button>
						<PaginationContainer
							pageCount={list?.body?.length}
							getFirstIndex={setFirstPostIndex}
							getLastIndex={setLastPostIndex}
							addScrollTo={true}
						>
							<BlockLayout loading={isLoading} error={error}>
								<ul className="li_page__addresses  list_style_type_none">
									{list?.body
										?.slice(firstPostIndex, lastPostIndex)
										.map((item, i) => (
											<li
												key={i + "lip1"}
												className="flex flex--wrap"
											>
												{/* 0x */}
												{formatAddressList(item).map(
													(point, i) => (
														<span
															className="li_page__address"
															key={i + "lips"}
														>
															{point}
														</span>
													)
												)}
											</li>
										))}
								</ul>
							</BlockLayout>
						</PaginationContainer>
					</div>
				</section>

				{/* Used in rool */}
				<div className="right_block">
					<section>
						<h2 className="subtitle">
							{text("list.rool_where_list_used")}
						</h2>

						<BlockLayout loading={isLoading} error={error}>
							<div className="text text--bold">
								{usedEvents?.length > 0 ? (
									usedEvents.map((item, i) => {
										let color;

										if ((i + 1) % 2 === 0) {
											color = "blue";
										}

										if ((i + 1) % 3 === 0) {
											color = "green";
										}

										if ((i + 1) % 4 === 0) {
											color = "purple";
										}
										return (
											<Link
												to={`/${locale}/projects/${projectId}/rool/${item?.id}`}
												className={
													"li_page__rool " + color
												}
												key={"litlr" + i}
											>
												<p>
													{item?.title
														? item?.title
														: window.innerWidth >
														  440
														? item?.address
														: cutAddress(
																item?.address
														  )}
												</p>
											</Link>
										);
									})
								) : (
									<p>{text("list.list_no_used")}</p>
								)}
							</div>
						</BlockLayout>
					</section>

					{/* Use API instruction */}
					<section className="li_page__instruction">
						
						<h2 className="subtitle">
							{text("list.how_put_list_by_api")}
						</h2>
						<div className="text--small text--light_gray">
							<p>
								{text("list.send_request_to_the_next_address")}{" "}
								https://ipn.tools/api/upd_addr
							</p>

							<div className="example_block">
								<code className="code">
									<p className="code">PUT</p>
									<p>&#123;</p>
										<p className="code__two_row">
											“addresses”: “addr”,
										</p>
										<p className="code__two_row">
											“secret_key”: “SECRET_KEY”
										</p>
									<p>&#125;</p>
								</code>
							</div>							
						</div>

						<h2 className="subtitle">
							{text("list.how_update_list_by_api")}
						</h2>
						<div className="text--small text--light_gray">
							<p>
								{text("list.send_request_to_the_next_address")}{" "}
								https://ipn.tools/api/upd_addr
							</p>

							<div className="example_block">
								<code className="code">
									<p className="code">POST</p>
									<p>&#123;</p>
										<p className="code__two_row">
											“addresses”: [“addr 1”, “addr 2”, ...
											“addr N”],
										</p>
										<p className="code__two_row">
											“secret_key”: “SECRET_KEY”
										</p>
									<p>&#125;</p>
								</code>
							</div>							
						</div>

						<h2 className="subtitle">
							{text("list.how_delete_list_by_api")}
						</h2>
						<div className="text--small text--light_gray">
							<p>
								{text("list.send_request_to_the_next_address")}{" "}
								https://ipn.tools/api/upd_addr
							</p>

							<div className="example_block">
								<p className="code">DELETE</p>
								<code className="code">
									<p>&#123;</p>
										<p className="code__two_row">
											“addresses”: “addr”,
										</p>
										<p className="code__two_row">
											“secret_key”: “SECRET_KEY”
										</p>
									<p>&#125;</p>
								</code>
							</div>							

							<p>{text("list.key_instruction")}</p>

							<p>{text("list.secret_key")}</p>

							<BlockLayout loading={isLoading} error={error}>
								<div className="example_block">
									<code className="code">
										<p>{list?.key}</p>
									</code>
								</div>
							</BlockLayout>
						</div>

					</section>
				</div>
			</SubpageLayout>

			{isModalOpen && (
				<Modal
					isBigSize={true}
					isNotFullWidth={true}
					closeModalHandle={onCloseModal}
				>
					<AddListForm
						title={list?.name}
						onCansel={onCloseModal}
						text={text}
						onDelete={onOpenDeleteModal}
						confirmBtnText={text("list.save")}
						addresses={list?.body}
						isDelete={usedEvents?.length === 0}
						isEdit={true}
					/>
				</Modal>
			)}
			{openDeleteModal && (
				<ConfirmDeleteModal
					isLoading={isDeleteLoading}
					deleteEvent={text("list.delete_event")}
					onCloseModalHandle={onCloseDeleteModal}
					onDeleteHandle={deleteListHandle}
					isError={isDeleteError}
					error={deleteError}
				/>
			)}
		</div>
	);
};

export default List;
