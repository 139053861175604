import React from "react";
import "./index.scss";

const Indicator = ({ type, text, size = "" }) => {
	return (
		<div className="indicator flex  flex--align-items-center">
			<div
				className={`indicator__alert flex  flex--center  ${type}  ${size}`}
			>
				<p></p>
			</div>

			<p className="indicator__text  text text--dark">{text}</p>
		</div>
	);
};

export default Indicator;
