import React from "react";
import { useTranslation } from "react-i18next";
import FormBtns from "../form-btns";
import Modal from "../modal";

const ProjectCardModal = ({
	onCloseModalHandle = () => {},
    onCreateKeyHandle = (e) => {},
	onCopyKeyHandle = (e) => {},
	formSubmitted,
	apiKey,
	keyCopied,
}) => {

	const { t } = useTranslation();

	return (
		<Modal closeModalHandle={onCloseModalHandle}>
			<div>
				{ formSubmitted === false &&
					<form onSubmit={onCreateKeyHandle} className="projects__form">
						<p className="profile__subtitle">{t("profile.modal_span")}</p>
						<p className="subtitle">
							{t("profile.modal_create_key")}
						</p>
						<p className="profile__subtitle">{t("profile.modal_alert")}</p>
						<FormBtns
							confirmText={t("profile.agree_button")}
							addDeleteBtn={false}
							onCloseHandle={onCloseModalHandle}
							waitingText={t("delete_modal.loading_btn_text")}
						/>
					</form>
				}
				{ formSubmitted === true &&
					<form onSubmit={onCopyKeyHandle} className="projects__form">
						<p className="profile__subtitle">{t("profile.modal_span")}</p>
						<p className="subtitle">
							{t("profile.modal_created")}
						</p>
						<p className="profile__subtitle">
							{t("profile.modal_created_subtitle")}
						</p>
						<input
							type="text"
							id="myInput"
							value={apiKey}
							readOnly
							className="profile__input"
						/>
						<FormBtns
							confirmText={keyCopied ?  t("profile.copy_button_true") : t("profile.copy_button")}
							addDeleteBtn={false}
							onCloseHandle={onCloseModalHandle}
							waitingText={t("delete_modal.loading_btn_text")}
							formSubmitted ={formSubmitted}
						/>
					</form>
				}
				
			</div>
		</Modal>
	);
};

export default ProjectCardModal;
