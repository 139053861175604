import { isMainnet } from "../app-config";
import {
  avalanche,
  bsc,
  mainnet,
  fantom,
  fantomTestnet,
  polygon,
  // gnosis,
  harmonyOne,
  bscTestnet,
  avalancheFuji,
  polygonMumbai,
  sepolia,
} from "wagmi/chains";

export const defaultChain = isMainnet ? bsc : bscTestnet;
export const chains = isMainnet
  ? [
      {
        ...mainnet,
      },
      {
        ...sepolia,
      },
      {
        ...bsc,
      },
      {
        ...bscTestnet,
      },
      {
        ...avalanche,
      },
      {
        ...avalancheFuji,
      },
      {
        ...polygon,
      },
      {
        ...polygonMumbai,
      },
      {
        ...fantom,
      },
      {
        ...fantomTestnet,
      },
      {
        id: 1231,
        name: "Ultron",
        network: "ultron-testnet",
        nativeCurrency: {
          decimals: 18,
          name: "ULX",
          symbol: "ULX",
        },
        rpcUrls: {
          default: {
            http: ["https://ultron-dev.io"],
          },
          public: {
            http: ["https://ultron-dev.io	"],
          },
        },
        blockExplorers: {
          etherscan: {
            name: "UlxScan",
            url: "https://explorer.ultron-dev.io",
          },
          default: {
            name: "UlxScan",
            url: "https://explorer.ultron-dev.io",
          },
        },
      },
      {
        id: 1230,
        name: "Ultron Testnet",
        network: "ultron",
        nativeCurrency: {
          decimals: 18,
          name: "ULX",
          symbol: "ULX",
        },
        rpcUrls: {
          default: {
            http: ["https://ultron-rpc.net"],
          },
          public: {
            http: ["https://ultron-rpc.net"],
          },
        },
        blockExplorers: {
          etherscan: {
            name: "UlxScan",
            url: "https://ulxscan.com",
          },
          default: {
            name: "UlxScan",
            url: "https://ulxscan.com",
          },
        },
      },
      {
        id: 111000,
        name: "Siberium Test Network",
        network: "Siberium Test Network",
        nativeCurrency: {
          decimals: 18,
          name: "SIBR",
          symbol: "SIBR",
        },
        rpcUrls: {
          default: {
            http: ["https://rpc.test.siberium.net"],
          },
          public: {
            http: ["https://rpc.test.siberium.net"],
          },
        },
        blockExplorers: {
          etherscan: {
            name: "SIBR",
            url: "https://explorer.test.siberium.net/",
          },
          default: {
            name: "SIBR",
            url: "https://explorer.test.siberium.net/",
          },
        },
      },
      {
        id: "",
        name: "Tron",
        network: "Tron",
        nativeCurrency: {
          decimals: 18,
          name: "TRX",
          symbol: "TRX",
        },
        rpcUrls: {
          default: {
            http: ["https://api.trongrid.io"],
          },
          public: {
            http: ["https://api.trongrid.io"],
          },
        },
        blockExplorers: {
          etherscan: {
            name: "TRX",
            url: "https://tronscan.org/",
          },
          default: {
            name: "TRX",
            url: "https://tronscan.org/",
          },
        },
      },
      {
        id: "",
        name: "Tron(T)",
        network: "Tron-testnet",
        nativeCurrency: {
          decimals: 18,
          name: "TRX",
          symbol: "TRX",
        },
        rpcUrls: {
          default: {
            http: ["https://api.trongrid.io"],
          },
          public: {
            http: ["https://api.trongrid.io"],
          },
        },
        blockExplorers: {
          etherscan: {
            name: "TRX",
            url: "https://tronscan.org/",
          },
          default: {
            name: "TRX",
            url: "https://tronscan.org/",
          },
        },
      },
      {
        ...harmonyOne,
      },
      {
        id: 1666700000,
        name: "Harmony Testnet",
        network: "harmony-testnet",
        nativeCurrency: {
          decimals: 8,
          name: "Harmony",
          symbol: "ONE",
        },
        rpcUrls: {
          default: {
            http: ["https://api.s0.b.hmny.io"],
          },
          public: {
            http: ["https://api.s0.b.hmny.io"],
          },
        },
        blockExplorers: {
          etherscan: {
            name: "explorer.harmony",
            url: "https://explorer.ps.hmny.io/",
          },
          default: {
            name: "explorer.harmony",
            url: "https://explorer.ps.hmny.io/",
          },
        },
      },
    ]
  : [
      {
        ...fantomTestnet,
      },
      {
        id: 1666700000,
        name: "Harmony Testnet",
        network: "harmony-testnet",
        nativeCurrency: {
          decimals: 8,
          name: "Harmony",
          symbol: "ONE",
        },
        rpcUrls: {
          default: {
            http: ["https://api.s0.b.hmny.io"],
          },
          public: {
            http: ["https://api.s0.b.hmny.io"],
          },
        },
        blockExplorers: {
          etherscan: {
            name: "explorer.harmony",
            url: "https://explorer.ps.hmny.io/",
          },
          default: {
            name: "explorer.harmony",
            url: "https://explorer.ps.hmny.io/",
          },
        },
      },
      {
        id: "",
        name: "Tron(T)",
        network: "Tron-testnet",
        nativeCurrency: {
          decimals: 18,
          name: "TRX",
          symbol: "TRX",
        },
        rpcUrls: {
          default: {
            http: ["https://api.trongrid.io"],
          },
          public: {
            http: ["https://api.trongrid.io"],
          },
        },
        blockExplorers: {
          etherscan: {
            name: "TRX",
            url: "https://tronscan.org/",
          },
          default: {
            name: "TRX",
            url: "https://tronscan.org/",
          },
        },
      },

      {
        ...bscTestnet,
      },
    ];

export const icons = isMainnet
  ? [
      {
        ...mainnet,
      },
      {
        ...bsc,
      },
      {
        ...polygon,
      },
      {
        ...avalanche,
      },
      {
        ...harmonyOne,
      },
      {
        ...fantom,
      },
      {
        id: 1231,
        name: "Ultron",
        network: "ultron",
        nativeCurrency: {
          decimals: 18,
          name: "ULX",
          symbol: "ULX",
        },
        rpcUrls: {
          default: {
            http: ["https://ultron-rpc.net"],
          },
          public: {
            http: ["https://ultron-rpc.net"],
          },
        },
        blockExplorers: {
          etherscan: {
            name: "UlxScan",
            url: "https://ulxscan.com",
          },
          default: {
            name: "UlxScan",
            url: "https://ulxscan.com",
          },
        },
      },
    ]
  : [
      // {
      // 	id: 1230,
      // 	name: "Ultron(T)",
      // 	network: "ultron-testnet",
      // 	nativeCurrency: {
      // 		decimals: 18,
      // 		name: "ULX",
      // 		symbol: "ULX",
      // 	},
      // 	rpcUrls: {
      // 		default: {
      // 			http: ["https://ultron-dev.io"],
      // 		},
      // 		public: {
      // 			http: ["https://ultron-dev.io"],
      // 		},
      // 	},
      // 	blockExplorers: {
      // 		etherscan: {
      // 			name: "UlxScan",
      // 			url: "https://explorer.ultron-dev.io",
      // 		},
      // 		default: {
      // 			name: "UlxScan",
      // 			url: "https://explorer.ultron-dev.io",
      // 		},
      // 	},
      // },
      {
        ...fantomTestnet,
      },
      {
        id: 1666700000,
        name: "Harmony Testnet",
        network: "harmony-testnet",
        nativeCurrency: {
          decimals: 8,
          name: "Harmony",
          symbol: "ONE",
        },
        rpcUrls: {
          default: {
            http: ["https://api.s0.b.hmny.io"],
          },
          public: {
            http: ["https://api.s0.b.hmny.io"],
          },
        },
        blockExplorers: {
          etherscan: {
            name: "explorer.harmony",
            url: "https://explorer.ps.hmny.io/",
          },
          default: {
            name: "explorer.harmony",
            url: "https://explorer.ps.hmny.io/",
          },
        },
      },
    ];
