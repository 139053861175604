import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { locale } from "../constants/constants";

const Home = () => {
	const navigate = useNavigate();
	
	useEffect(() => {
		navigate(`/${locale}/projects`)
	}, []);

	return <></>;
};

export default Home;
