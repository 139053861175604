import React from "react";
import { Pagination } from "@mui/material";
import "./index.scss";

const BlockPagination = ({
	pageCount,
	itemsCount,
	setPage,
	currentPage,
	resultFrom,
	resultTo,
}) => {
	const handleChange = (_, num) => {
		setPage(num);
	};

	return (
		<div className="pagination">
			<Pagination
				count={pageCount}
				shape="rounded"
				onChange={handleChange}
				page={currentPage}
				hidePrevButton
				hideNextButton
			/>

			<p className="text--small pagination__pages_info  text--light_gray">
				{resultFrom}-{resultTo} of {itemsCount} results
			</p>
		</div>
	);
};

export default BlockPagination;
