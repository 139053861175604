import React, { useRef } from "react";
import BurgerMenu from "../../components/burger-menu";
import { locale } from "../../constants/constants";
import "./index.scss";
import header_logo from '../../images/header_logo.svg'

const baseURL = process.env.REACT_APP_ARTICLES;
const LogInHeader = ({ text, connect }) => {
	const links = [
		{
			text: text("log_in_header.about"),
			link: "#about",
		},
		{
			text: text("log_in_header.how_it_works"),
			link: "#how-it-works",
		},
		{
			text: text("log_in_header.advantages"),
			link: "#advantages",
		},
		{
			text: text("log_in_header.faq"),
			link: "#faq",
		},
		{
			text: text("log_in_header.articles"),
			link: `${baseURL}/${locale}/articles/`,
		},
	];

	const menu = useRef();
	const burger = useRef();

	const openMenu = () => {
		menu.current.classList.toggle("open");
		burger.current.classList.toggle("open");
	};

	const closeMenu = () => {
		menu.current.classList.remove("open");
		burger.current.classList.remove("open");
	};

	return (
		<header className="log_in_header">
			<div ref={burger} className="burger_container" onClick={openMenu}>
				<img
					className="logo"
					src={header_logo}
					alt="logo"
				/>
				<BurgerMenu />
			</div>
			<div
				ref={menu}
				className="flex  flex--center_space_between header__menu"
			>
				<img
					className="logo"
					src={header_logo}
					alt="logo"
				/>
				<div className="flex flex--align-items-center menu_inner">
					<nav className="nav flex flex--align-items-center">
						{links.map((item, i) => {
							return (
								<a
									key={i + "l"}
									onClick={closeMenu}
									className="nav__item"
									href={item.link}
								>
									{item.text}
								</a>
							);
						})}
					</nav>
					<button
						className="btn btn--green log_in_btn"
						onClick={connect}
					>
						{text("log_in.enter")}
					</button>
				</div>
			</div>
		</header>
	);
};

export default LogInHeader;
