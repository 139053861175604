import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import PaginationContainer from "../../components/pagination-container";
import Modal from "../../blocks/modal";
import { ReactComponent as Arr } from "../../commons/svg/select_arr.svg";
import { eventAPI } from "../../services";
import {
	getLocaleUserTime,
	showCode,
	timestampToDate,
} from "../../utils/support";
import { transactionTypeOptionsRu } from "../../constants/transactions-ru";
import { transactionTypeOptionsEng } from "../../constants/transactions-eng";
import { useApi } from "../../hooks";
import "./index.scss";
import BlockLayout from "../../blocks/block-layout";
import SignError from "../../components/sign-error";
import { locale } from "../../constants/constants";

const EventDetail = ({ text }) => {
	const { userSign } = useApi();
	let { eventId } = useParams();
	const navigate = useNavigate();
	const [firstPostIndex, setFirstPostIndex] = useState(0);
	const [lastPostIndex, setLastPostIndex] = useState(4);
	const [skipEvent, setSkipEvent] = useState(true);
	const [skipEventLogs, setSkipEventLogs] = useState(true);
	const [webhookSendStatuses, setWebhookSendStatuses] = useState({
		status: "no_webhook",
		text: text("events_detail.not_set"),
	});

	const { event, error, isLoading } = eventAPI.useFetchEventQuery(
		{ userSign, eventId },
		{
			selectFromResult: ({ data, error, isLoading }) => {
				return {
					event: data && data?.webhooks[0],
					error: error,
					isLoading: isLoading,
				};
			},
			skip: skipEvent,
		}
	);

	const {
		data: eventLogs,
		error: eventsLogsError,
		isLoading: isLoadingEventsLog,
	} = eventAPI.useFetchEventLogsQuery(
		{ userSign, eventId: eventId, limit: lastPostIndex },
		{
			skip: skipEventLogs,
		}
	);

	const onSite = event?.request?.info?.on_site;

	useEffect(() => {
		if (userSign && eventId) {
			setSkipEvent(false);
		}
	}, [userSign, eventId]);

	useEffect(() => {
		if (userSign && eventId && lastPostIndex) {
			setSkipEventLogs(false);
		}
	}, [userSign, eventId, lastPostIndex]);

	useEffect(() => {
		if (!onSite) {
			setWebhookSendStatuses({
				status: "no_send",
				text: text("events_detail.sending_is_not_required"),
			});
			return;
		}

		if (event?.sent) {
			setWebhookSendStatuses({
				status: "success",
				text: text("events_detail.send"),
			});
		} else {
			setWebhookSendStatuses({
				status: "error",
				text: text("events_detail.not_send"),
			});
		}
	}, [onSite, event?.sent, text]);

	const eventCode = (index, createdTime, url, status_code, time, request) => {
		return (
			<Accordion key={index} TransitionProps={{ unmountOnExit: true }}>
				<div className="table__row">
					<div className="table__column first_column">
						<p className="text  text--light_gray mobile_title">
							{text("events_detail.send_date")}
						</p>

						<div className="text--word-wrap">
							<p className="text  text--bold">
								{getLocaleUserTime(createdTime, locale)}
							</p>

							<p className="text  text--light_gray text--small">
								{url}
							</p>
						</div>
					</div>
					<div className="table__column  two_column">
						<p className="text  text--light_gray mobile_title">
							{text("events_detail.answer")}
						</p>

						<div>
							<p
								className={`text  text--bold  answer ${
									status_code === 200 && "success"
								}`}
							>
								<span>{status_code}</span>

								<span>
									{status_code === 200
										? ": Success"
										: ": Forbidden "}
								</span>
							</p>

							<p className="text  text--light_gray  text--small">
								{timestampToDate(time, locale)}
							</p>
						</div>
					</div>

					<AccordionSummary
						expandIcon={<Arr />}
						aria-controls={`panel${index}a-content`}
						id={`panel${index}a-header`}
					>
						<div className="table__column  three_column">
							<p className="text  text--light_gray  mobile_title">
								{text("events_detail.sended")}
							</p>
							<p className="text request  text--bold">
								<span>{text("events_detail.request")}</span>
							</p>
						</div>
					</AccordionSummary>
				</div>

				<AccordionDetails>
					<p className="text--small text--light_gray">
						{text("events_detail.send_request")}
					</p>

					<code className="code">
						<p>&#123;</p>

						{showCode(request)?.map((item, i) => {
							if (typeof item[1] !== "object") {
								return (
									<p key={i + "kk"} className="code__two_row">
										“{item[0]}”: “
										<span className="text--word-wrap">
											{item[1]}
										</span>
										”,
									</p>
								);
							} else {
								return (
									<p
										key={i + "kki"}
										className="code__two_row"
									>
										“{item[0]}”: &#123;
										{item[1]?.map((p, i) => {
											return (
												<span
													key={i + "kkik"}
													className="code__three_row"
												>
													“{p[0]}”: “
													<span className="text--word-wrap">
														{p[1]}
													</span>
													”,
												</span>
											);
										})}
										<span>&#125;</span>
									</p>
								);
							}
						})}
						<p>&#125;</p>
					</code>
				</AccordionDetails>
			</Accordion>
		);
	};

	const transactionTypeRu = useMemo(() => {
		return transactionTypeOptionsRu.find(
			(i) => i.value === event?.request?.info?.type
		)?.title;
	}, [event?.request?.info?.type]);

	const transactionTypeEng = useMemo(() => {
		return transactionTypeOptionsEng.find(
			(i) => i.value === event?.request?.info?.type
		)?.title;
	}, [event?.request?.info?.type]);

	

	return (
		<Modal
			closeModalHandle={() => navigate(-1)}
			addTopClose={true}
			isCloseNotFilled={true}
			isBigSize={true}
		>
			<div className="event_detail">
				<div className="header_with_line">
					<h1 className="subtitle">
						{text("events_detail.event_detail")}
					</h1>
				</div>

				<div className="event_detail__main">
					{/* sign messsages */}
					<SignError text={text} />

					<BlockLayout loading={isLoading || !userSign} error={error}>
						<section className="event_detail__section">
							<h2 className="title">{locale === 'ru' ? transactionTypeRu : transactionTypeEng}</h2>

							{/* Event detail */}
							{event?.request?.info?.project && (
								<p>
									<span className="text--light_gray">
										{text("events_detail.project")}
									</span>

									<span className="text--bold">
										{event?.request?.info?.project}
									</span>
								</p>
							)}

							{event?.request?.info?.rule && (
								<p className="text--mt">
									<span className="text--light_gray">
										{text("events_detail.tool")}
									</span>

									<span className="text--bold">
										{event?.request?.info?.rule}
									</span>
								</p>
							)}

							<p className="text--mt">
								<span className="text--light_gray">
									{text("events_detail.transaction_id")}
								</span>

								<span className="text--bold  text--word-wrap">
									{event?.request?.hash}
								</span>
							</p>

							<p className="text--mt">
								<span className="text--light_gray">
									{text("events_detail.event_discovered")}
								</span>

								<span className="text--bold">
									{getLocaleUserTime(event?.created, locale)}
								</span>
							</p>
						</section>

						{/* Telegram section */}
						<section className="event_detail__section event_detail__telegram">
							<h2 className="subtitle">
								{text("events_detail.telegram")}
							</h2>
							{event?.request?.info?.telegram ? (
								<p className="status success">
									{text("events_detail.sended")}
								</p>
							) : (
								<p className="status no_send">
									{text(
										"events_detail.sending_is_not_required"
									)}
								</p>
							)}
						</section>

						{/* Webhook section */}
						<section className="event_detail__section event_detail__webhook">
							<h2 className="subtitle">
								{text("events_detail.webhook")}
							</h2>

							<div>
								<p
									className={`${webhookSendStatuses?.status} status`}
								>
									{webhookSendStatuses?.text}
								</p>

								{onSite && (
									<div className="event_detail__webhook_info">
										{event?.check_at && (
											<p>
												<span className="text--light_gray">
													{text(
														"events_detail.next_try"
													)}
												</span>
												<span className="text">
													{getLocaleUserTime(
														event?.check_at,
														locale
													)}
												</span>
											</p>
										)}

										<p>
											<span className="text--light_gray">
												{text(
													"events_detail.send_trying"
												)}
											</span>

											<span className="text">
												{event?.resends}
											</span>
										</p>
									</div>
								)}

								{/* Event logs */}
								<BlockLayout
									loading={isLoadingEventsLog}
									error={eventsLogsError}
								>
									<PaginationContainer
										pageCount={eventLogs?.count}
										getFirstIndex={setFirstPostIndex}
										getLastIndex={setLastPostIndex}
										lastIndex={lastPostIndex}
										postPerPage={4}
									>
										<div className="table">
											{/* table header */}
											<div className="table__row text--light_gray">
												<p className="table__column table__header first_column">
													{text(
														"events_detail.send_date"
													)}
												</p>
												<p className="table__column table__header two_column">
													{text(
														"events_detail.answer"
													)}
												</p>
												<p className="table__column table__header three_column">
													{text(
														"events_detail.sended"
													)}
												</p>
											</div>

											{/* table body */}
											{eventLogs?.logs?.length > 0 ? (
												eventLogs.logs
													.slice(
														firstPostIndex,
														lastPostIndex
													)
													.map((item, i) =>
														eventCode(
															i,
															item.created,
															item.url,
															item.response
																?.status_code,
															item.response?.time,
															item.request
														)
													)
											) : (
												<p className="text--mt">
													{text(
														"events_detail.there_is_nothing_here_yet"
													)}
												</p>
											)}
										</div>
									</PaginationContainer>
								</BlockLayout>
							</div>
						</section>
					</BlockLayout>
				</div>
			</div>
		</Modal>
	);
};

export default EventDetail;
