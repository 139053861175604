import React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";

const AntSwitch = styled(Switch)(({ theme }) => ({
	width: 54,
	height: 30,
	padding: 0,
	display: "flex",
	borderRadius: 20,
	"&:active": {
		"& .MuiSwitch-thumb": {
			width: 24,
		},
		// '& .MuiSwitch-switchBase.Mui-checked': {
		//   transform: 'translateX(9px)',
		// },
	},
	"& .MuiSwitch-switchBase": {
		padding: 3,
		color: "#3BAAFB",
		"&.Mui-checked": {
			transform: "translateX(25px)",
			color: "#fff",

			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor: "#11CA71",
			},
		},
	},
	"& .MuiSwitch-thumb": {
		boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
		width: 24,
		height: 24,

		borderRadius: 20,
		transition: "all 0.5s",
	},
	"& .MuiSwitch-track": {
		borderRadius: 20,
		opacity: 1,
		backgroundColor: "rgba(16, 17, 18, 0.1)",
		boxSizing: "border-box",
	},
}));

const SwitchBtn = ({ checked, onChangeHandle }) => {
	return (
		<Stack direction="row" spacing={1} alignItems="center">
			<AntSwitch checked={checked} onChange={onChangeHandle} />
		</Stack>
	);
};

export default SwitchBtn;
