import React from "react";
import "./index.scss";

const HowItWorksCard = ({ number, text }) => {
	return (
		<div
			className={`how_it_works_card  flex flex--space_between flex--column n${number}`}
		>
			<p className="number">{number}</p>
			<p className="how_it_works_card__text">{text}</p>
		</div>
	);
};

export default HowItWorksCard;
