import React from "react";
import "./index.scss";

const AboutCard = ({
	addTextToLeft = false,
	imgSrc = "",
	imgAlt = "",
	rightTextTitle = [],
	rightTextDescr = "",
}) => {
	return (
		<div className="about_card grid">
			<div className="img_container flex flex--center">
				{imgSrc && (
					<img
						src={imgSrc}
						alt={imgAlt}
						className={`${
							addTextToLeft ? "positioned_img" : ""
						} img`}
					/>
				)}
			</div>

			<div className="about_card__text flex flex--column">
				<div className="flex flex--wrap">
					{rightTextTitle.length > 0 &&
						rightTextTitle.map((item, i) => {
							return (
								<p key={i + "ak"} className="log_in__subtitle">
									{item}&nbsp;
								</p>
							);
						})}
				</div>

				<p className="descr">{rightTextDescr}</p>
			</div>
		</div>
	);
};

export default AboutCard;
