import { config } from "../app-config";

export const transactionTypeOptionsEng = [
	{
		value: "TR",
		title: "Transfer",
	},
	{
		value: "CU",
		title: "Custom event",
	},
	{
		value: "NT",
		title: "Native",
	},
];

export const nativeTransactionTypeOptionsEng = [
	{
		value: "NT",
		title: "Native",
	},
];

export const operationOptionsEng = [
	{
		value: "TP",
		title: "Top up",
	},
	{
		value: "BS",
		title: "Bonuses",
	},
	{
		value: "TX",
		title: "Rule executed",
	},
	{
		value: "WC",
		title:"Withdrawal cancel",
	},
	{
		value: "WD",
		title: "Withdrawal processing",
	},
	{
		value: "WV",
		title: "Withdrawal confirm",
	},
];