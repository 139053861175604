import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { ReactComponent as Lock } from "../../commons/svg/lock.svg";
import { deleteScript, deleteSymbols } from "../../utils/support";
import { useTranslation } from "react-i18next";
import "./index.scss";

const CustomInput = ({
	type = "text",
	name = "",
	value = "",
	text = "",
	helperText = "",
	placeholder = "",
	limitForNumber = 0,
	addLockIcon = false,
	isReadOnly = false,
	addRoundFunc = false,
	setValue = () => {},
	resetStatus = () => {},
	getErrorText = () => {},
}) => {
	const [errorText, setErrorText] = useState("");
	const { t } = useTranslation();

	useEffect(() => {
		getErrorText(errorText);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorText]);

	const onChangeHandle = (e) => {
		if (addRoundFunc && e.target.value) {
			setValue(Math.round(+e.target.value));
		} else {
			setValue(e.target.value);
		}

		if (type === "text" || type === "url") {
			deleteScript(e.target.value, setErrorText);
			deleteSymbols(e.target.value, setErrorText);
		}

		if (type === "number" && limitForNumber) {
			if (+e.target.value > limitForNumber) {
				setErrorText(
					t("input.error")
				);
			} else {
				setErrorText("");
			}
		}

		resetStatus();
	};

	return (
		<div className="custom_input">
			<TextField
				placeholder={placeholder}
				value={value}
				type={type}
				fullWidth
				error={Boolean(errorText)}
				disabled={isReadOnly}
				helperText={errorText ? errorText : helperText}
				label={
					<p>
						{addLockIcon && isReadOnly && (
							<Lock className="lock_icon" />
						)}
						{text}
					</p>
				}
				variant="filled"
				onChange={(e) => {
					onChangeHandle(e);
				}}
				onClick={(e) => {
					if (value === "Transfer (index_topic_1 address from, index_topic_2 address to, uint256 value)") {
						setValue(" ")
					}
				}}
			/>
			<input
				id="my-input"
				className="hidden"
				name={name}
				type={type}
				value={value}
				onChange={(e) => {
					onChangeHandle(e);
				}}
			/>
		</div>
	);
};

export default CustomInput;
