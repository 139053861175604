import React from "react";
import { useTranslation } from "react-i18next";
import EventCard from "../../components/event-card";
import "./index.scss";

const EventList = ({
	items = [],
	addProjectInfo = false,
	noSeparateAddress = false,
}) => {
	const { t } = useTranslation();

	return (
		<ul className="full_width list_style_type_none even_list">
			{items.length > 0 ? (
				items.map((item) => (
					<EventCard
						data={item}
						key={item.id}
						addProjectInfo={addProjectInfo}
						noSeparateAddress={noSeparateAddress}
						text={t}
						paidAmount={1}
					/>
				))
			) : (
				<li className="text text--mt">{t("events.not_events")}</li>
			)}
		</ul>
	);
};

export default EventList;
