import React, { useEffect, useState } from "react";
import { ReactComponent as Close } from "../../commons/svg/close.svg";
import "./index.scss";
import { addHiddenToBody } from "../../utils/support";

const Modal = ({
	modalTitle = "",
	children,
	closeModalHandle = () => {},
	isCloseNotFilled = false,
	addConfirmBtnTop = false,
	addTopClose = false,
	isBigSize = false,
	isNotFullWidth = false,
}) => {
	const [sizeClass, setSizeClass] = useState("");
	const [closeClass, setCloseClass] = useState("");

	useEffect(() => {
		window.scrollTo(0, 0);

		if (!isBigSize) {
			addHiddenToBody();
		}

		if (isBigSize) {
			if (isNotFullWidth) {
				setSizeClass("big  not_full_width");
			} else {
				setSizeClass("big");
			}
		}

		if (isCloseNotFilled) {
			setCloseClass("unfilled");
		}

		if (addConfirmBtnTop) {
			setCloseClass(`${closeClass} with_confirm`);
		}

		return () => {
			document.body.style.overflow = "";
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={"modal_background  flex flex--center " + sizeClass}>
			<div className={"modal " + sizeClass}>
				{modalTitle && <p>{modalTitle}</p>}
				<div className="flex modal__btns">
					{addTopClose && (
						<button
							className={
								"modal__close flex flex--center btn  " +
								closeClass
							}
							type="button"
							onClick={closeModalHandle}
						>
							<Close />
						</button>
					)}
				</div>
				{children}
			</div>
		</div>
	);
};

export default Modal;
