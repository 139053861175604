import React, { useRef, useState, useEffect } from "react";
import Message from "../message";
import { deleteScript, deleteSymbols } from "../../utils/support";
import "./index.scss";
import { InputLabel } from "@mui/material";

const InputTitle = ({
	label = "",
	type = "",
	value,
	setValue,
	errorTextValue = "",
	placeholder = "",
	name = "",
	resetStatus = () => { },
	getErrorText = () => { },
	id,
	setButtonSubmitState,
	buttonSubmitState,
	error,
}) => {
	const inputText = useRef();
	const input = useRef();
	const [errorText, setErrorText] = useState("");
	const [isClicked, setIsClicked] = useState(false);
	const [labelColor, setLabelColor] = useState("default");

	useEffect(() => {
		getErrorText(errorText);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorText]);

	useEffect(() => {
		setErrorText(errorTextValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorTextValue]);

	const onClickHandle = (e) => {
		setLabelColor("#1976d2");
		inputText.current.classList.add("hide");
		input.current.classList.remove("hide");
		setIsClicked(true);
	};

	const onBlurHandle = (e) => {
		if (!value) {
			inputText.current.classList.remove("hide");
			input.current.classList.add("hide");
			setIsClicked(false);
		}
	};

	const onChangeHandle = (e) => {
		setValue(e.target.value);

		if (type === "text") {
			deleteScript(e.target.value, setErrorText);
			deleteSymbols(e.target.value, setErrorText);
		}

		resetStatus();
	};

	return (
		<>
			<div className={`title_input`} >
				<div className={`title_input__text ${Boolean(errorText) && " error"}`} >
					{(!value && isClicked && label) && (
						<InputLabel id="label" style={{ color: labelColor }}>
							{label}*
						</InputLabel>
					)}
					<span ref={inputText}>
						{value ? (
							<span className="value">{value}</span>
						) : (
							<span className="placeholder">{placeholder}</span>
						)}
					</span>
				</div>

				<input
					name={name}
					className="hide"
					ref={input}
					type={type}
					value={value}
					onChange={onChangeHandle}
					id={id}
					minLength={1}
					onBlur={onBlurHandle}
					onFocus={onClickHandle}
					required
				/>
			</div>

			{errorText && <Message text={errorText} isError={true} />}
			{buttonSubmitState === false ? (
				<Message text={error} isError={true} />
			) : (
				<></>
			)}
		</>
	);
};

export default InputTitle;
