import React, { Component } from "react";
import { ReactComponent as Icon } from "../../commons/svg/emojiError2.svg";
import "./index.scss";
import { locale } from "../../constants/constants";

const ErrorComponent = () => {
	const onClick = () => {
		window.history.back();
		setTimeout(() => {
			window.location.reload();
		}, 400);
	};

	return (
		<div className="error_boundary">
			<p className="title"> Упс! Что-то пошло не так...</p>

			<div className="error_emoji">
				<Icon />
			</div>

			<div className="flex  flex--align-items-end">
				<button
					className="btn  btn--blue"
					type="button"
					onClick={onClick}
				>
					Вернуться назад
				</button>
				<a className="btn" href={`/${locale}`}>
					Вернуться на главную
				</a>
			</div>
		</div>
	);
};

export default class ErrorBoundry extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	// componentDidCatch(error, info) {
	//   // You can also log the error to an error reporting service
	//   logErrorToMyService(error, info);
	// }

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <ErrorComponent />;
		}
		return this.props.children;
	}
}
