import React from "react";
import "./index.scss";
import LanguageButtons from "../../components/language-buttons";
import telegram from '../../images/footer/telegram.svg';
import blog from '../../images/footer/blog.svg';
import github from '../../images/footer/github.svg';
import base from '../../images/footer/base.svg';

const Footer = ({ text }) => {
	return (
		<div className="footer">
			<h2 className="log_in__subtitle">{text("footer.title")}</h2>
			{ localStorage.getItem('language') === "ru" ? 
				<div className="footer__links">
					<a
						className="footer__link telegram"
						href="https://t.me/ipn_tools"
						target="_blank"
						rel="noreferrer noopener"
					>
						<span>{text("footer.telegram")}</span>
						<img
							src={telegram}
							className="img"
							alt="telegram icon"
						/>
					</a>

					<a
						className="footer__link blog"
						href="https://vc.ru/u/2165938-ipn"
						target="_blank"
						rel="noreferrer noopener"
					>
						<span>{text("footer.blog")}</span>
						<img
							src={blog}
							className="img"
							alt="vc.ru icon"
						/>
					</a>

					<a
						className="footer__link github"
						href="https://github.com/IPNTools"
						target="_blank"
						rel="noreferrer noopener"
					>
						<span>{text('footer.library')}</span>
						<img
							src={github}
							className="img"
							alt="docs icon"
						/>
					</a>

					<a
						className="footer__link base"
						href="https://docs.ipn.tools/"
						target="_blank"
						rel="noreferrer noopener"
					>
						<span>{text("footer.knowledge_base")}</span>
						<img
							src={base}
							className="img"
							alt="docs icon"
						/>
					</a>
				</div>
				:  
				<div className="footer__links">
					<a
						className="footer__link github"
						href="https://github.com/IPNTools"
						target="_blank"
						rel="noreferrer noopener"
					>
						<span>{text('footer.library')}</span>
						<img
							src={github}
							className="img"
							alt="docs icon"
						/>
					</a>

					<a
						className="footer__link base"
						href="https://ipn-tools.gitbook.io/welcome-to-ipn.tools/"
						target="_blank"
						rel="noreferrer noopener"
					>
						<span>{text("footer.knowledge_base")}</span>
						<img
							src={base}
							className="img"
							alt="docs icon"
						/>
					</a>					
				</div>
			}
			<div className="footer__language-box">
				<LanguageButtons props={'button-footer'}/>
			</div>
		</div>
	);
};

export default Footer;
