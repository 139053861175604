import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import Message from "../../components/message";
import Loader from "../../components/loader";
import { ReactComponent as Arr } from "../../commons/svg/select_arr.svg";
import { ReactComponent as Lock } from "../../commons/svg/lock.svg";
import "./index.scss";

const SelectInput = ({
	value = "",
	label = "",
	error = "",
	helperText = "",
	name = "",
	items = [],
	isLoading = false,
	required = false,
	isAddNoneElement = false,
	isReadOnly = false,
	setValue = () => {},
	resetStatus = () => {},
}) => {
	const [openedClass, setOpenedClass] = useState("");

	const handleChange = (event) => {
		setValue(event.target.value);
		resetStatus();
	};

	const openHandle = () => {
		setOpenedClass("open");
	};

	const closeHandle = () => {
		setOpenedClass("");
	};

	return (
		<div className={openedClass + " select_input"}>
			<FormControl
				variant="filled"
				required={required}
				disabled={isReadOnly}
			>
				<InputLabel id="demo-simple-select-filled-label">
					{isReadOnly && <Lock className="lock_icon" />}
					{label}
				</InputLabel>
				<Arr className={"select__arr " + openedClass} />
				<Select
					required={required}
					name={name}
					fullWidth
					onClose={closeHandle}
					onOpen={openHandle}
					labelId="demo-simple-select-filled-label"
					id="demo-simple-select-filled"
					value={value}
					onChange={handleChange}
				>
					{isAddNoneElement && (
						<MenuItem value={0}>
							<em>None</em>
						</MenuItem>
					)}
					{isLoading ? (
						<Loader />
					) : error ? (
						<Message text={error} isError={true} />
					) : (
						items?.map((item, i) => (
							<MenuItem key={i} value={item.value}>
								{item.title}
							</MenuItem>
						))
					)}
				</Select>

				{helperText && <FormHelperText>{helperText}</FormHelperText>}
			</FormControl>
		</div>
	);
};

export default SelectInput;
