import axios from "axios";
import { getCookie } from "../hooks";

const api_uri = process.env.REACT_APP_API;

export async function getApiKey(setApiKey) {
  try {
    const response = await axios(`${api_uri}/auth/token`, {
      method: "POST",
      headers: {
        sign: `${getCookie("sign")}`,
      },
    });
    setApiKey(response.data.token);
  } catch (error) {
    console.log(error);
  }
}

export async function getKeyCreateTime(setTimestamp) {
  try {
    const response = await axios(`${api_uri}/auth/token_data`, {
      method: "GET",
      headers: {
        sign: `${getCookie("sign")}`,
      },
    });
    setTimestamp(response.data.timestamp);
  } catch (error) {
    console.log(error);
  }
}
