import React from "react";
import "./index.scss";

const BurgerMenu = () => {
	return (
		<button className="f-hamburger-switch btn  flex  flex--center btn  btn--no_styles">
			<p className="cont">
				<span className="bx"></span>
				<span className="bx"></span>
			</p>
		</button>
	);
};

export default BurgerMenu;
