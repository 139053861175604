import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";

const api_uri = process.env.REACT_APP_API;

export const projectAPI = createApi({
    reducerPath: 'projectAPI',
    baseQuery: fetchBaseQuery({baseUrl: api_uri}),
    tagTypes: ['Project'],
    endpoints: (build) => ({
        fetchAllProjects: build.query({
            query: ({userSign, limit = 10, skip = 0}) => ({
                url: `projects`,
                params: {
                    skip: skip,
                    limit: limit,
                },
                headers: {
                    sign: userSign
                }
            }),
            providesTags: result => ['Project'],
        }),
        fetchProject: build.query({
            query: ({userSign, projectId, limit = 10, skip = 0}) => ({
                url: `projects`,
                params: {
                    project_id: projectId,
                    skip: skip,
                    limit: limit,
                },
                headers: {
                    sign: userSign
                }
            }),
            providesTags: result => ['Project'],
        }),
        createProject: build.mutation({
            query: ({body, sign}) => ({
                url: `project`,
                method: 'POST',
                body,
                headers: {
                    sign: sign
                }
            }),
            invalidatesTags: ['Project']
        }),
        updateProject: build.mutation({
            query: ({body = {}, sign}) => ({
                url: `project`,
                method: 'PATCH',
                body: body,
                headers: {
                    sign: sign
                }
            }),
            invalidatesTags: ['Project']
        }),
        deleteProject: build.mutation({
            query: ({body, sign}) => ({
                url: `project`,
                method: 'DELETE',
                body,
                headers: {
                    sign: sign
                }
            }),
            invalidatesTags: ['Project']
        }),
    })
})
