import React, { useState, useEffect } from "react";
import { ReactComponent as Plus } from "../../commons/svg/plus.svg";
import "./index.scss";

const AddNewElement = ({ text = "", onClickHandle = () => {} }) => {
	const [btnClass, setBtnClass] = useState("");

	useEffect(() => {
		if (text) {
			setBtnClass(" add_btn--with_text");
		}
	}, [text]);

	return (
		<button
			className={"btn add_btn flex  flex--center" + btnClass}
			type="button"
			onClick={onClickHandle}
		>
			<Plus className="plus" />
			{text && <p className="title add_btn__text">{text}</p>}
		</button>
	);
};

export default AddNewElement;
