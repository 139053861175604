import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

const api_uri = process.env.REACT_APP_API;

export const eventAPI = createApi({
  reducerPath: "eventAPI",
  baseQuery: fetchBaseQuery({ baseUrl: api_uri }),
  tagTypes: ["Event"],
  endpoints: (build) => ({
    fetchEvents: build.query({
      query: ({ userSign, limit = 10, skip = 0, arg = {} }) => ({
        url: `/webhooks`,
        params: {
          skip: skip,
          limit: limit,
          ...arg,
        },
        headers: {
          sign: userSign,
        },
      }),
      providesTags: (result) => ["Event"],
    }),
    fetchEvent: build.query({
      query: ({ userSign, eventId }) => ({
        url: `/webhooks`,
        params: {
          webhook_id: eventId,
        },
        headers: {
          sign: userSign,
        },
      }),
      providesTags: (result) => ["Event"],
    }),
    fetchEventLogs: build.query({
      query: ({ userSign, eventId, limit = 10, skip = 0 }) => ({
        url: `/webhooks/logs`,
        params: {
          skip: skip,
          limit: limit,
          webhook_id: eventId,
        },
        headers: {
          sign: userSign,
        },
      }),
      providesTags: (result) => ["Event"],
    }),
  }),
});
