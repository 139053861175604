import React, {useState, useEffect} from "react";
import Indicator from "../indicator";
import { Link } from "react-router-dom";
import { ReactComponent as Icon } from "../../commons/svg/site_icon.svg";
import { ReactComponent as IconError } from "../../commons/svg/site_icon_error.svg";
import './index.scss';
import { formatDateForTimeInterval, getOnlyDate } from "../../utils/support";
import { locale } from "../../constants/constants";

const SiteCard = ({
    id = '',
    title = "", 
    confirmeDate = "",
    waitingDate = "",
    text,
    isConfirmed = false, 
    isVerified = false,
}) => {
    const [status, setStatus] = useState('');
    const [indicatorText, setIndicatorText] = useState('');
    const [confirmDateText, setConfirmDateText] = useState('');

    useEffect(() => {
        if(isVerified) {
            setStatus('success');
            setIndicatorText(text("sites.confirmed"));
            setConfirmDateText(getOnlyDate(confirmeDate, locale, false));
        }

        if(!isVerified) {
            setStatus('warning');
            setIndicatorText(text("sites.waiting_to_confirm"));
            setConfirmDateText(text("sites.next_check") + formatDateForTimeInterval(waitingDate, false, locale));
        }

        if(!isConfirmed) {
            setStatus('error');
            setIndicatorText(text("sites.unconfirmed"));
            setConfirmDateText(text("sites.check_stopped"));
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVerified, isConfirmed])
    
    return (
        <Link to={`/${locale}/sites/${id}`} className={'card site_card  ' + status}>
            <h2 className="subtitle site_card__title">{title}</h2>

            <div className="site_card__info">
                <div className="info_row text">
                    <Indicator 
                        type={status}
                        text={indicatorText}
                    />
                </div>
                <div className="info_row  flex  flex--align-items-center">
                    <p className="info_row__icon_container flex flex--center"> 
                        {isConfirmed ? <Icon className="info_row__icon" />
                        : <IconError className="info_row__icon" />}
                    </p>
                    
                    <p className={`text  ${status === 'error' && 'text--error'}`}>{confirmDateText}</p>
                </div>
            </div>
        </Link>
    )
}


export default SiteCard;