import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { ReactComponent as Success } from "../../commons/svg/test_modal/success.svg";
import { ReactComponent as Error } from "../../commons/svg/test_modal/error.svg";
import { locale } from "../../constants/constants";

const Message = ({
	text = "",
	addIcon = true,
	isError = false,
	isSuccess = false,
	isWarning = false,
	isCenter = false,
	messageClassName = "",
	id = "",
}) => {
	const { t } = useTranslation();
	const [messColor, setMessColor] = useState("");

	useEffect(() => {
		if (isError) {
			setMessColor("text--error");
		}

		if (isSuccess) {
			setMessColor("text--success");
		}

		if (isWarning) {
			setMessColor("text--warning");
		}
	}, [isError, isSuccess, isWarning]);

	const updateError = (error) => {
		if (error === "Project with this name already exists" && locale === "ru") {
			return "Проект с таким названием уже существует. Пожалуйста, придумайте другое название.";
		}

		if (error === "Project list with this name already exists" && locale === "ru") {
			return "Список адресов с таким названием уже существует. Пожалуйста, придумайте другое название.";
		}

		if (error === "Event with this title already exists" && locale === "ru") {
			return "Правило с таким названием уже существует. Пожалуйста, придумайте другое название.";
		}

		if (error === "Project with this name already exists" && locale === "en") {
			return "Project with this name already exists. Please provide other name.";
		}

		if (error === "Project list with this name already exists" && locale === "en") {
			return "A list of addresses with that name already exists.  Please provide other name.";
		}

		if (error === "Event with this title already exists" && locale === "en") {
			return "Rule уже with this name already exists. Please provide other name.";
		}

		return error;
	};

	return (
		<div
			id={id}
			className={`message  flex  ${
				isCenter ? "flex--center" : ""
			} ${messageClassName}`}
		>
			{addIcon && isError && (
				<p className="message__icon">
					<Error className="message__icon" />
				</p>
			)}
			{addIcon && isSuccess && (
				<p className="message__icon">
					<Success className="message__icon" />
				</p>
			)}
			<p className={messColor}>
				{isError && <span>{t("error")}</span>}
				<span className="message__text">{updateError(text)}</span>
			</p>
		</div>
	);
};

export default Message;
