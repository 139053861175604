import React from "react";
import "./index.scss";

const Loader = ({ isBig = false }) => {
	return (
		<span
			className={`loader_container flex  flex--center  ${isBig && "big"}`}
		>
			<span className={`loader`}>
				<span className="loader_inner"></span>
			</span>
		</span>
	);
};

export default Loader;
