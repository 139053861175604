import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import AddNewElement from "../../../components/add-new-element";
import PaginationContainer from "../../../components/pagination-container";
import RoolsCard from "../../../components/rools-card";
import AddRoolForm from "../../project/add-rool-form";
import Modal from "../../modal";
import { roolAPI } from "../../../services";
import { useApi } from "../../../hooks";
import BlockLayout from "../../../blocks/block-layout";
import SignError from "../../../components/sign-error";
import "./index.scss";

const ProjectRools = ({ text }) => {
	let { projectId } = useParams();
	const { userSign } = useApi();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [firstPostIndex, setFirstPostIndex] = useState(0);
	const [lastPostIndex, setLastPostIndex] = useState(10);
	const [skip, setSkip] = useState(true);

	const { rools, count, errorRools, isLoadingRools } =
		roolAPI.useFetchAllRoolQuery(
			{ userSign, projectId, limit: lastPostIndex },
			{
				selectFromResult: ({ data, error, isLoading }) => {
					return {
						rools: data?.events,
						count: data?.count,
						errorRools: error,
						isLoadingRools: isLoading,
					};
				},
				skip,
			}
		);

	const [
		createRool,
		{
			error: createError,
			isError: isCreateError,
			isLoading: isCreateLoading,
			isSuccess: isCreateSuccess,
			reset: resetCreateResult,
		},
	] = roolAPI.useCreateRoolMutation();

	useEffect(() => {
		if (userSign && projectId && lastPostIndex) {
			setSkip(false);
		}
	}, [userSign, projectId, lastPostIndex]);

	const onCloseModal = () => {
		setIsModalOpen(false);
	};

	const addNewRool = useCallback(
		async (body, userSign, error) => {
			if (body && userSign && !error) {
				await createRool({ sign: userSign, body });
			}
		},
		[createRool, userSign]
	);

	const updatedRools = useMemo(() => {
		if (rools) {
			return [
				{
					addNewELBtn: true,
				},
				...rools,
			];
		}
	}, [rools]);

	return (
		<>
			<PaginationContainer
				pageCount={count + 1}
				getFirstIndex={setFirstPostIndex}
				getLastIndex={setLastPostIndex}
				lastIndex={lastPostIndex}
				postPerPage={10}
			>
				<SignError text={text} />

				<BlockLayout
					loading={isLoadingRools || !userSign}
					error={errorRools}
				>
					<div className="rools grid">
						{updatedRools
							?.slice(firstPostIndex, lastPostIndex)
							.map((item, i) =>
								item.addNewELBtn ? (
									<AddNewElement
										key={item.id + "new"}
										onClickHandle={() => {
											setIsModalOpen(true);
										}}
									/>
								) : (
									<RoolsCard
										key={item.id}
										id={item.id}
										text={text}
										adressesInList={item.address_count}
										title={item.title}
										isActive={item.is_active}
										events={item.webhooks}
										newEvents={item.new_webhooks}
										address={item.addresses}
										roolOrder={i + 1}
										type={item.type}
									/>
								)
							)}
					</div>
				</BlockLayout>
			</PaginationContainer>

			{isModalOpen && (
				<Modal
					closeModalHandle={onCloseModal}
					addTopClose={false}
					isBigSize={true}
					addConfirmBtnTop={true}
				>
					<AddRoolForm
						confirmError={createError}
						confirmBtnText={text("project.add_btn_text")}
						projectId={projectId}
						isCreate={true}
						isConfirmSuccess={isCreateSuccess}
						isConfirmError={isCreateError}
						isConfirmSending={isCreateLoading}
						addDeleteBtn={false}
						resetResultFunc={resetCreateResult}
						onConfirm={addNewRool}
						onCansel={onCloseModal}
					/>
				</Modal>
			)}
	
		</>
	);
};

export default ProjectRools;
