import React, { useRef, useEffect, useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import BurgerMenu from "../../components/burger-menu";
import { cutAddress } from "../../utils/support";
import { locale } from "../../constants/constants";
import "./index.scss";

const Header = ({ address = "", text }) => {
	const navigate = useNavigate();
	const menu = useRef();
	const burger = useRef();
	const submenu = useRef();
	const arrIcon = useRef();

	useEffect(() => {
		document.addEventListener("click", (e) => {
			if (
				!e.target.closest(".address__opened_menu") &&
				!e.target.closest(".address")
			) {
				closeSubmenu();
			}
		});
	});

	const openMenu = () => {
		menu.current.classList.toggle("open");
		burger.current.classList.toggle("open");
	};

	const closeMenu = () => {
		menu.current.classList.remove("open");
		burger.current.classList.remove("open");
	};

	const closeSubmenu = () => {
		submenu.current?.classList.remove("open");
		arrIcon.current?.classList.remove("open");
	};

	return (
		<header className="header">
			<div ref={burger} className="burger_container" onClick={openMenu}>
				<BurgerMenu />
			</div>
			<div
				ref={menu}
				className="flex  flex--align-items-center header__menu"
			>
				<nav className="nav flex flex--center_space_between">
					<p className="flex  flex--align-items-center">
						<NavLink
							onClick={closeMenu}
							className="nav-item"
							to={`/${locale}/projects`}
						>
							{text("header.projects")}
						</NavLink>

						<NavLink
							onClick={closeMenu}
							className="nav-item"
							to={`/${locale}/sites`}
						>
							{text("header.sites")}
						</NavLink>

						<NavLink
							onClick={closeMenu}
							className="nav-item"
							to={`/${locale}/events`}
						>
							{text("header.events")}
						</NavLink>

						<NavLink
							onClick={closeMenu}
							className="nav-item"
							to={`/${locale}/balance`}
						>
							{text("header.balance")}
						</NavLink>
					</p>

					<a
						className="nav-item"
						target="_blank"
						rel="noreferrer noopener"
						href={locale == "ru" ? "https://docs.ipn.tools" : "https://ipn-tools.gitbook.io/welcome-to-ipn.tools/"}
					>
						{text("header.support")}
					</a>
				</nav>

				<div className="address_container">
					<button
						className="btn  btn--not_bold  address  btn--no_styles"
						onClick={
							() => {
								navigate(`/${locale}/profile`); 
								closeMenu();
							}
						}
					>
						<p>{cutAddress(address)}</p>
					</button>
				</div>
			</div>
		</header>
	);
};

export default Header;
