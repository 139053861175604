import React, { useMemo } from "react";
import { chains } from "../../../constants/chains.config";
import { cutAddress } from "../../../utils/support";
import { roolAPI } from "../../../services";
import { useApi } from "../../../hooks";
import { getLocaleUserTime } from "../../../utils/support";
import BlockLayout from "../../../blocks/block-layout";
import { locale } from "../../../constants/constants";
import "./index.scss";

const EditHistory = ({
	roolId = 0,
	projectId = 0,
	address = "",
	transactionTypeOptions = [],
	text,
}) => {
	const { userSign } = useApi();

	const {
		data,
		now,
		was,
		status,
		created,
		prevUrl,
		nowUrl,
		error,
		isLoading,
	} = roolAPI.useFetchRoolsLogsQuery(
		{ userSign, projectId, eventId: roolId },
		{
			selectFromResult: ({ data, error, isLoading }) => {
				const editedData = data?.logs[0];

				const prevUrl = editedData?.was?.url_id
					? `${editedData?.was?.url_id}${editedData?.was?.path}`
					: "—";
				const nowUrl = editedData?.now?.url_id
					? `${editedData?.now?.url_id}${editedData?.now?.path}`
					: "—";

				return {
					data: data,
					editedData: editedData,
					prevUrl: prevUrl,
					nowUrl: nowUrl,
					error: error,
					isLoading: isLoading,
					now: editedData?.now,
					was: editedData?.was,
					status: editedData?.status,
					created: editedData?.created,
				};
			},
		}
	);

	const createdDate = useMemo(() => {
		return data?.logs?.find((i) => i.status === "CR")?.created;
	}, [data?.logs]);

	const activated = useMemo(() => {
		return data?.logs?.find((i) => i.status === "AC")?.created;
	}, [data?.logs]);

	const edited = useMemo(() => {
		return status === "ED" && created;
	}, [created, status]);

	const info = useMemo(() => {
		return [
			{
				title: text("rool_edit_history.title"),
				value: {
					prev: was?.title || "—",
					new: now?.title || "—",
				},
			},
			{
				title: text("rool_edit_history.network"),
				value: {
					prev:
						chains.find((i) => i.id === was?.chain_id)?.name || "—",
					new:
						chains.find((i) => i.id === now?.chain_id)?.name || "—",
				},
			},
			{
				title: text("rool_edit_history.address"),
				value: {
					prev: was?.addresses_id || "—",
					new: now?.addresses_id || "—",
				},
			},
			{
				title: text("rool_edit_history.type_of_transaction"),
				value: {
					prev:
						transactionTypeOptions.find(
							(i) => i.value === was?.type
						)?.title || "—",
					new:
						transactionTypeOptions.find(
							(i) => i.value === now?.type
						)?.title || "—",
				},
			},
			{
				title: text("rool_edit_history.topic"),
				value: {
					prev: was?.declaration || "—",
					new: now?.declaration || "—",
				},
			},
			{
				title: text("rool_edit_history.webhook"),
				value: {
					prev: prevUrl,
					new: nowUrl,
				},
			},
			{
				title: text("rool_edit_history.telegram"),
				value: {
					prev:
						status !== "ED"
							? "—"
							: was?.telegram === false
							? text("rool_edit_history.not_bind")
							: text("rool_edit_history.bind"),
					new:
						status === "AC"
							? "—"
							: now?.telegram === false
							? text("rool_edit_history.not_bind")
							: text("rool_edit_history.bind"),
				},
			},
		];
	}, [
		now?.addresses_id,
		now?.chain_id,
		now?.declaration,
		now?.telegram,
		now?.title,
		now?.type,
		status,
		was?.addresses_id,
		was?.chain_id,
		was?.declaration,
		was?.telegram,
		was?.title,
		was?.type,
		nowUrl,
		prevUrl,
		text,
		transactionTypeOptions,
	]);

	return (
		<div className="edit_history">
			{/* title */}
			<div className="header_with_line">
				<h2 className="subtitle">
					{text("rool_edit_history.history_of_rool_edit")}
				</h2>
			</div>
			<BlockLayout loading={isLoading || !userSign} error={error}>
				<div className="flex  edit_history__main">
					<div className="edit_history__statistic">
						{/* edited block */}
						{edited && (
							<div className="statistic__card">
								<p className="text  text--bold">
									{text("rool_edit_history.edited")}
								</p>
								<p className="text--small text--light_gray">
									{edited
										? getLocaleUserTime(edited, locale)
										: text("rool_edit_history.no_date")}
								</p>
							</div>
						)}

						{/* activated block */}
						{activated && (
							<div className="statistic__card">
								<p className="text  text--bold">
									{text("rool_edit_history.activated")}
								</p>
								<p className="text--small text--light_gray">
									{activated
										? getLocaleUserTime(activated, locale)
										: text("rool_edit_history.no_date")}
								</p>
							</div>
						)}

						{/* create block */}
						<div className="statistic__card">
							<p className="text  text--bold">
								{text("rool_edit_history.created")}
							</p>
							<p className="text--small text--light_gray">
								{createdDate
									? getLocaleUserTime(createdDate, locale)
									: text("rool_edit_history.no_date")}
							</p>
						</div>
					</div>

					{/* information */}
					<div className="edit_history__info">
						<h2 className="subtitle">
							{edited
								? text("rool_edit_history.rool_edited")
								: activated
								? text("rool_edit_history.rool_created")
								: text("rool_edit_history.rool_activated")}
						</h2>

						<p>
							<span className="text text--light_gray">
								{text("rool_edit_history.date")}
							</span>
							<span className="text text--bold">
								{edited
									? getLocaleUserTime(edited, locale)
									: createdDate
									? getLocaleUserTime(createdDate, locale)
									: text("rool_edit_history.no_date")}
							</span>
						</p>
						<p>
							<span className="text text--light_gray">
								{text("rool_edit_history.author")}
							</span>
							<span className="text text--bold">
								{window.innerWidth > 560
									? address
									: cutAddress(address)}
							</span>
						</p>

						{/* table */}
						<div className="table">
							<div className="table__row">
								<p className="table__column table__header">
									{text("rool_edit_history.was_column")}
								</p>
								<p className="table__column table__header">
									{text("rool_edit_history.now_column")}
								</p>
							</div>

							{info.map((item, i) => {
								const isAddressPrev =
									item?.value?.prev?.includes("0x");
								const isAddressNew =
									item?.value?.new?.includes("0x");
								return (
									<div className="table__row" key={i + "eh"}>
										<div className="table__column">
											<p className="column__title  text  text--light_gray">
												{item.title}
											</p>
											<p
												className={`column__value  text  text--bold ${
													(isAddressPrev ||
														isAddressNew) &&
													"text--word-wrap"
												}`}
											>
												{item.value.prev}
											</p>
										</div>
										<div className="table__column">
											<p className="column__title  text  text--light_gray">
												{item.title}
											</p>
											<p
												className={`column__value  text  text--bold ${
													(isAddressPrev ||
														isAddressNew) &&
													"text--word-wrap"
												}`}
											>
												{item.value.new}
											</p>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</BlockLayout>
		</div>
	);
};

export default EditHistory;
