import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./index.scss";
import { ReactComponent as Plus } from "../../commons/img/log_in/plus.svg";
import { ReactComponent as Minus } from "../../commons/img/log_in/minus.svg";

const LogInFaq = ({ text }) => {
	const [expanded, setExpanded] = useState(false);
	const [plusIcon, setPlusIcon] = useState("");

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
		setPlusIcon(isExpanded ? panel : "");
	};

	return (
		<div className="faq">
			<Accordion
				expanded={expanded === "panel1"}
				onChange={handleChange("panel1")}
			>
				<AccordionSummary
					expandIcon={plusIcon === "panel1" ? <Minus /> : <Plus />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
				>
					<p>{text("log_in_faq.first_question")}</p>
				</AccordionSummary>
				<AccordionDetails>
					<p>{text("log_in_faq.first_answer")}</p>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expanded === "panel2"}
				onChange={handleChange("panel2")}
			>
				<AccordionSummary
					expandIcon={plusIcon === "panel2" ? <Minus /> : <Plus />}
					aria-controls="panel2bh-content"
					id="panel2bh-header"
				>
					<p>{text("log_in_faq.two_question")}</p>
				</AccordionSummary>
				<AccordionDetails>
					<p>{text("log_in_faq.two_answer")}</p>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expanded === "panel3"}
				onChange={handleChange("panel3")}
			>
				<AccordionSummary
					expandIcon={plusIcon === "panel3" ? <Minus /> : <Plus />}
					aria-controls="panel3bh-content"
					id="panel3bh-header"
				>
					<p>{text("log_in_faq.three_question")}</p>
				</AccordionSummary>
				<AccordionDetails>
					<p>{text("log_in_faq.three_answer")}</p>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expanded === "panel4"}
				onChange={handleChange("panel4")}
			>
				<AccordionSummary
					expandIcon={plusIcon === "panel4" ? <Minus /> : <Plus />}
					aria-controls="panel4bh-content"
					id="panel4bh-header"
				>
					<p>{text("log_in_faq.four_question")}</p>
				</AccordionSummary>
				<AccordionDetails>
					<p>{text("log_in_faq.four_answer")}</p>
				</AccordionDetails>
			</Accordion>

			<Accordion
				expanded={expanded === "panel5"}
				onChange={handleChange("panel5")}
			>
				<AccordionSummary
					expandIcon={plusIcon === "panel5" ? <Minus /> : <Plus />}
					aria-controls="panel5bh-content"
					id="panel5bh-header"
				>
					<p>{text("log_in_faq.five_question")}</p>
				</AccordionSummary>
				<AccordionDetails>
					<p>{text("log_in_faq.five_answer")}</p>
				</AccordionDetails>
			</Accordion>

			<Accordion
				expanded={expanded === "panel6"}
				onChange={handleChange("panel6")}
			>
				<AccordionSummary
					expandIcon={plusIcon === "panel6" ? <Minus /> : <Plus />}
					aria-controls="panel6bh-content"
					id="panel6bh-header"
				>
					<p>{text("log_in_faq.six_question")}</p>
				</AccordionSummary>
				<AccordionDetails>
					<p>{text("log_in_faq.six_answer")}</p>
				</AccordionDetails>
			</Accordion>

			<Accordion
				expanded={expanded === "panel7"}
				onChange={handleChange("panel7")}
			>
				<AccordionSummary
					expandIcon={plusIcon === "panel7" ? <Minus /> : <Plus />}
					aria-controls="panel7bh-content"
					id="panel8bh-header"
				>
					<p>{text("log_in_faq.seven_question")}</p>
				</AccordionSummary>
				<AccordionDetails>
					<p>{text("log_in_faq.seven_answer_list_title")}</p>
					<ul>
						<li>{text("log_in_faq.seven_answer_list_item_1")}</li>
						<li>{text("log_in_faq.seven_answer_list_item_2")}</li>
						<li>{text("log_in_faq.seven_answer_list_item_3")}</li>
						<li>{text("log_in_faq.seven_answer_list_item_4")}</li>
					</ul>
					<p>{text("log_in_faq.seven_answer_end_text")}</p>
				</AccordionDetails>
			</Accordion>

			<Accordion
				expanded={expanded === "panel8"}
				onChange={handleChange("panel8")}
			>
				<AccordionSummary
					expandIcon={plusIcon === "panel8" ? <Minus /> : <Plus />}
					aria-controls="panel8bh-content"
					id="panel8bh-header"
				>
					<p>{text("log_in_faq.eight_question")}</p>
				</AccordionSummary>
				<AccordionDetails>
					<p>{text("log_in_faq.eight_answer")}</p>
					<p>{text("log_in_faq.eight_answer_list_1_title")}</p>
					<ul>
						<li>{text("log_in_faq.eight_answer_list_1_item_1")}</li>
						<li>{text("log_in_faq.eight_answer_list_1_item_2")}</li>
						<li>{text("log_in_faq.eight_answer_list_1_item_3")}</li>
					</ul>
					<p>{text("log_in_faq.eight_answer_list_2_title")}</p>
					<ul>
						<li>{text("log_in_faq.eight_answer_list_2_item_1")}</li>
						<li>{text("log_in_faq.eight_answer_list_2_item_2")}</li>
						<li>{text("log_in_faq.eight_answer_list_2_item_3")}</li>
						<li>{text("log_in_faq.eight_answer_list_2_item_4")}</li>
						<li>{text("log_in_faq.eight_answer_list_2_item_5")}</li>
						<li>{text("log_in_faq.eight_answer_list_2_item_6")}</li>
						<li>{text("log_in_faq.eight_answer_list_2_item_7")}</li>
						<li>{text("log_in_faq.eight_answer_list_2_item_8")}</li>
					</ul>
				</AccordionDetails>
			</Accordion>

			<Accordion
				expanded={expanded === "panel9"}
				onChange={handleChange("panel9")}
			>
				<AccordionSummary
					expandIcon={plusIcon === "panel9" ? <Minus /> : <Plus />}
					aria-controls="panel9bh-content"
					id="panel9bh-header"
				>
					<p>{text("log_in_faq.nine_question")}</p>
				</AccordionSummary>
				<AccordionDetails>
					<p>{text("log_in_faq.nine_answer_first")}</p>
					<p>{text("log_in_faq.nine_answer_last")}</p>
				</AccordionDetails>
			</Accordion>

			<Accordion
				expanded={expanded === "panel10"}
				onChange={handleChange("panel10")}
			>
				<AccordionSummary
					expandIcon={plusIcon === "panel10" ? <Minus /> : <Plus />}
					aria-controls="panel10bh-content"
					id="panel10bh-header"
				>
					<p>{text("log_in_faq.ten_question")}</p>
				</AccordionSummary>
				<AccordionDetails>
					<p>{text("log_in_faq.ten_answer")}</p>
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

export default LogInFaq;
