import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";

const api_uri = process.env.REACT_APP_API;

export const siteAPI = createApi({
    reducerPath: 'siteAPI',
    baseQuery: fetchBaseQuery({baseUrl: api_uri}),
    tagTypes: ['Site'],
    endpoints: (build) => ({
        fetchAllSites: build.query({
            query: ({userSign, limit = 10, skip = 0}) => ({
                url: `url_validations`,
                params: {
                    skip: skip,
                    limit: limit
                },
                headers: {
                    sign: userSign
                }
            }),
            providesTags: result => ['Site']
        }),
        fetchSite: build.query({
            query: ({userSign, siteId, limit = 10, skip = 0}) => ({
                url: `url_validations`,
                params: {
                    url_id: siteId,
                    skip: skip,
                    limit: limit
                },
                headers: {
                    sign: userSign
                }
            }),
            providesTags: result => ['Site'],
        }),
        rerunValidation: build.mutation({
            query: ({userSign, siteId}) => ({
                url: `url_validation_status`,
                method: 'GET',
                params: {
                    url_id: siteId,
                },
                headers: {
                    sign: userSign
                }
            }),
            providesTags: result => ['Site']
        }),
        fetchSiteHistory: build.query({
            query: ({userSign, siteId}) => ({
                url: `url_validation_history`,
                params: {
                    url_id: siteId,
                },
                headers: {
                    sign: userSign
                }
            }),
            providesTags: result => ['Site']
        }),
        getValidationFile: build.mutation({
            query: ({userSign, url}) => ({
                url: `url_validation_file`,
                method: 'GET',
                params: {
                    url_id: url,
                },
                headers: {
                    sign: userSign
                },
                responseHandler: async (response) => window.URL.createObjectURL(await response.blob()),
                cache: "no-cache",
            }),
            providesTags: result => ['Site'],
        }),
        createSite: build.mutation({
            query: ({body, sign}) => ({
                url: 'url_validation',
                method: 'POST',
                body: body,
                headers: {
                    sign: sign
                },
            }),
            invalidatesTags: ['Site']
        }),
        deleteSite: build.mutation({
            query: ({body, sign}) => ({
                url: 'url_validation',
                method: 'DELETE',
                body: body,
                headers: {
                    sign: sign
                },
            }),
            invalidatesTags: ['Site']
        }),
    })
})
