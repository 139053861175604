import React, { useEffect } from "react";
import "./index.scss";
import { useWeb3 } from "../../hooks";
import { config } from "../../app-config";
import LogInHeader from "../../blocks/log-in-header";
import AboutCard from "../../components/log_in/about_card";
import HowItWorksCard from "../../components/log_in/how_it_works_card";
import AdvantagesCard from "../../components/log_in/advantages_card";
import LogInFaq from "../../blocks/log_in_faq";
import Footer from "../../blocks/footer";
import { useNavigate } from "react-router-dom";
import { locale } from "../../constants/constants";
import logo from '../../images/logo.svg'
import fix from '../../images/about/1.svg'
import check from '../../images/about/2.svg'
import square from '../../images/about/3.svg'
import eth from '../../images/networks/eth.svg'
import bnb from '../../images/networks/bnb.svg'
import polygon from '../../images/networks/polygon.svg'
import avalanche from '../../images/networks/avalanche.svg'
import fantom from '../../images/networks/fantom.svg'
import harmony from '../../images/networks/harmony.svg'
import ultron from '../../images/networks/ultron.svg'
import tron from '../../images/networks/tron-circle.svg'
import more from '../../images/networks/more.svg'

const LogIn = ({ text }) => {
	const { onConnect } = useWeb3();
	const navigate = useNavigate();

	const connectHandle = () => {
		if (config.is_iphone && !window.ethereum) {
			window.location.replace(
				`https://metamask.app.link/dapp/${window.location.hostname}`
			);
			onConnect();
		} else {
			onConnect();
		}
	};

	useEffect(() => {
		if (locale === "ru") {
			navigate("/ru");
		}
		if (locale === "en") {
			navigate("/en");
		}
		if (!locale) {
			navigator.language === "ru" ? navigate("/ru") : navigate("/en");
		}
	}, []);

	return (
		<div className="log_in">
			<LogInHeader text={text} connect={connectHandle} />

			<main>
				<h1 className="visually-hidden">
					Instant payment notification
				</h1>

				{/* First screen */}
				<section className="flex flex--space_between  log_in__section  first_screen">
					<h2 className="visually-hidden">
						{text("log_in_about.hidden_title")}
					</h2>
					<div className="first_screen__inner">
						<p className="log_in__title">{text("log_in.title")}</p>
						<div className="first_screen__descr">
							<p>{text("log_in.descritpion_list_title")}</p>
							<ul className="first_screen__list">
								<li>
									{text("log_in.descritpion_list_first_item")}
								</li>
								<li>
									{text("log_in.descritpion_list_two_item")}
								</li>
								<li>
									{text("log_in.descritpion_list_three_item")}
								</li>
								<li>
									{text("log_in.descritpion_list_four_item")}
								</li>
							</ul>
						</div>
						<div className="first_screen__descr"><p>{text("log_in.descritpion_first")}</p></div>
						{locale === "ru" && <div className="first_screen__descr"><p>{text("log_in.descritpion_second")}</p></div>}
						<div className="first_screen__descr"><p>{text("log_in.descritpion_last")}</p></div>
						<button
							className="btn btn--green log_in_btn"
							type="bitton"
							onClick={connectHandle}
						>
							{text("log_in.first_block_btn_text")}
						</button>
					</div>
					<img src={logo} className="img" alt="logo" />
				</section>

				{/* About */}
				<section id="about" name="about" className="log_in__section">
					<h2 className="visually-hidden">
						{text("log_in_about.hidden_title")}
					</h2>
					<AboutCard
						imgSrc={check}
						imgAlt="check"
						rightTextTitle={[text("log_in_about.first_title")]}
						rightTextDescr={text("log_in_about.first_descr")}
					/>

					<AboutCard
						imgSrc={fix}
						imgAlt=""
						rightTextTitle={[
							text("log_in_about.two_title")
						]}
						rightTextDescr={text("log_in_about.two_descr")}
					/>

					<AboutCard
						imgSrc={square}
						imgAlt=""
						rightTextTitle={[
							text("log_in_about.three_title"),
						]}
						rightTextDescr={text("log_in_about.three_descr")}
					/>
				</section>

				{/* How it works */}
				<section
					id="how-it-works"
					className="log_in__section how-it-works"
					name="how-it-works"
				>
					<h2 className="log_in__subtitle">
						{text("log_in_how_it_works.title")}
					</h2>

					<div className="grid grid--four_columns how-it-works__cards">
						<HowItWorksCard
							number="1"
							text={text("log_in_how_it_works.first_text")}
						/>

						<HowItWorksCard
							number="2"
							text={text("log_in_how_it_works.two_text")}
						/>

						<HowItWorksCard
							number="3"
							text={text("log_in_how_it_works.three_text")}
						/>

						<HowItWorksCard
							number="4"
							text={text("log_in_how_it_works.four_text")}
						/>
					</div>
				</section>

				{/* Networks */}
				<section className="log_in__section networks">
					<h2 className="visually-hidden">
						{text("log_in_networks.hidden_title")}
					</h2>

					<p className="log_in__subtitle networks__title">
						{text("log_in_networks.title")}
					</p>
					<p className="networks__descr">
						{text("log_in_networks.descr")}
					</p>
					<div className="flex flex--wrap networks__networks">
						{networks?.map((item, i) => {
							return (
								<div key={i + "n"} className="network">
									<img
										src={item.img}
										alt={item.alt}
										className=""
									/>
									<p>{item.text}</p>
								</div>
							);
						})}

						<div className="network">
							<img
								src={more}
								alt=""
								className=""
							/>
							<p>{text("log_in_networks.further_more")}</p>
						</div>
					</div>
				</section>

				{/* Advantages */}
				<section id="advantages" className="log_in__section advantages">
					<h2 className="visually-hidden">
						{text("log_in_advantages.hidden_title")}
					</h2>
					<p className="log_in__subtitle">
						{text("log_in_advantages.title")}
					</p>

					<div className="advantages__cards grid grid--three_columns">
						<AdvantagesCard
							title={[
								text("log_in_advantages.first_title_1"),
								text("log_in_advantages.first_title_2"),
							]}
							descr={text("log_in_advantages.first_descr")}
						/>

						<AdvantagesCard
							title={[
								text("log_in_advantages.two_title_1"),
								text("log_in_advantages.two_title_2"),
							]}
							descr={text("log_in_advantages.two_descr")}
						/>

						<AdvantagesCard
							title={[
								text("log_in_advantages.three_title_1"),
								text("log_in_advantages.three_title_2"),
							]}
							descr={text("log_in_advantages.three_descr")}
						/>
					</div>
				</section>

				{/* Banner */}
				<section className="log_in_banner banner">
					<div className="banner__inner  flex flex--align-items-center">
						<div className="banner__main">
							<h2 className="banner__title">
								{text("log_in_banner.text")}
							</h2>

							<button
								className="btn"
								type="bitton"
								onClick={connectHandle}
							>
								{text("log_in_banner.btn")}
							</button>
						</div>
					</div>
				</section>

				{/* FAQ */}
				<section id="faq" className="log_in__section" name="faq">
					<h2 className="log_in__subtitle">
						{text("log_in_faq.title")}
					</h2>

					<LogInFaq text={text} />
				</section>

				<section className="log_in__section">
					<Footer text={text} />
				</section>
			</main>
		</div>
	);
};

const networks = [
	{
		img: eth,
		alt: "Ethereum",
		text: "Ethereum",
	},
	{
		img: bnb,
		alt: "Binance",
		text: "Binance Smart Chain",
	},
	{
		img: polygon,
		alt: "Polygon",
		text: "Polygon",
	},
	{
		img: avalanche,
		alt: "Avalanche",
		text: "Avalanche",
	},
	{
		img: fantom,
		alt: "Fantom",
		text: "Fantom",
	},
	{
		img: harmony,
		alt: "Harmony",
		text: "Harmony",
	},
	{
		img: ultron,
		alt: "Ultron",
		text: "Ultron",
	},
	{
		img: tron,
		alt: "Tron",
		text: "Tron",
	},
];

export default LogIn;
