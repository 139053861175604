import React from "react";
import Loader from "../../components/loader";
import Message from "../../components/message";
import { updateErrorInfo } from "../../utils/support";

const BlockLayout = ({ loading, error, children, bigLoader = true }) => {
	return (
		<>
			{loading ? (
				<Loader isBig={bigLoader} />
			) : error ? (
				<Message text={updateErrorInfo(error)} isError={true} />
			) : (
				children
			)}
		</>
	);
};

export default BlockLayout;
