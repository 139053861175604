import { config } from "../app-config";

export const transactionTypeOptionsRu = [
	{
		value: "TR",
		title:"Трансфер",
	},
	{
		value: "CU",
		title: "Своё событие",
	},
	{
		value: "NT",
		title:"Нативная",
	},
];

export const nativeTransactionTypeOptionsRu = [
	{
		value: "NT",
		title:"Нативная",
	},
];

export const operationOptionsRu = [
	{
		value: "TP",
		title: "Пополнение",
	},
	{
		value: "BS",
		title: "Приветственные бонусы",
	},
	{
		value: "TX",
		title: "Сработало правило",
	},
	{
		value: "WC",
		title: "Вывод - отменен",
	},
	{
		value: "WD",
		title: "Вывод - в процессе",
	},
	{
		value: "WV",
		title: "Вывод - выполнено",
	},
];
