import { useCallback, useState } from "react";
import debounce from "lodash/debounce";

export const useDebouncedValue = (initialValue, wait = 200) => {
	const [debouncedValue, setDebouncedValue] = useState(initialValue);
	const debouncedFunction = useCallback(debounce(setDebouncedValue, wait), [
		wait,
	]);
	return [debouncedValue, debouncedFunction];
};
