import React, { useState, useEffect } from "react";
import "./index.scss";
import TypedSelect from "../typed-select";
import { ReactComponent as Delete } from "../../commons/svg/delete_gray.svg";
import { ReactComponent as Plus } from "../../commons/svg/plus.svg";
import CustomInput from "../../components/custom-input";
import { useTranslation } from "react-i18next";

const ConditionInput = ({
	name = "",
	error = "",
	selectedValue = "",
	selectedLabel = "",
	label = "",
	type = "text",
	selectItems = [],
	isSimpleInput = false,
	isReset = false,
	isLoading = false,
	getErrorText = () => {},
	addNewEl = () => {},
}) => {
	const { t } = useTranslation();
	const [isDisabled, setIsDisabled] = useState(true);
	const [helperText, setHelperText] = useState("");
	const [inputsText, setInputsText] = useState();
	const [isResetSelect, setIsResetSelect] = useState(false);
	const [errorText, setErrorText] = useState("");

	useEffect(() => {
		if (selectedValue) {
			setInputsText(selectedValue);
		}
	}, [selectedValue]);

	useEffect(() => {
		getErrorText(errorText);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorText]);

	useEffect(() => {
		if (isReset) {
			setInputsText();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isReset]);

	const onAddField = () => {
		setIsDisabled(false);
		setHelperText(`${t("project.address")}`);
		setIsResetSelect(false);
	};

	const onDeleteField = () => {
		setIsDisabled(true);
		setHelperText("");
		setInputsText("");
		setIsResetSelect(true);
	};

	return (
		<div className={`condition_input flex ${isDisabled && "disabled"}`}>
			{isSimpleInput ? (
				<div className="big-select">
					<CustomInput
						type={type}
						name={name}
						text={label}
						value={inputsText}
						isReadOnly={isDisabled}
						setValue={setInputsText}
					/>
				</div>
			) : (
				<TypedSelect
					name={name}
					selectItems={selectItems}
					selectedValue={selectedValue}
					selectedLabel={selectedLabel}
					label={label}
					error={error}
					addBtnText={t("project.add_new_list_text")}
					helperText={helperText}
					addNewElOpt={true}
					isLoading={isLoading}
					isDisabled={isDisabled}
					isReset={isReset || isResetSelect}
					getErrorText={setErrorText}
					addNewElHandle={() => {
						addNewEl(true);
					}}
				/>
			)}

			{!isDisabled ? (
				<button
					className="btn rools__form__btn"
					type="button"
					onClick={onDeleteField}
				>
					<Delete className="rools__form__btn__delete  cond_btn_icon" />
				</button>
			) : (
				<button
					className="btn  rools__form__btn flex flex--center"
					type="button"
					onClick={onAddField}
				>
					<Plus className="rools__form__plus cond_btn_icon" />
				</button>
			)}
		</div>
	);
};

export default ConditionInput;
