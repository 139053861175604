import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";

const api_uri = process.env.REACT_APP_API;

export const settingsAPI = createApi({
    reducerPath: 'settingsAPI',
    baseQuery: fetchBaseQuery({baseUrl: api_uri}),
    tagTypes: ['Settings'],
    endpoints: (build) => ({
        fetchSettings: build.query({
            query: () => ({
                url: `v1/settings`,
            }),
            providesTags: result => ['Settings']
        }),
    })
})
