import React from "react";
import "./index.scss";

const AdvantagesCard = ({ title, descr }) => {
	return (
		<div className="advantages_card">
			{title.length > 1 &&
				title.map((item, i) => {
					return (
						<p key={i + "advk"} className="advantages_card__title">
							{item}
						</p>
					);
				})}

			<p className="advantages_card__descr">{descr}</p>
		</div>
	);
};

export default AdvantagesCard;
