import React, { useRef, useState, useEffect } from "react";
import Fuse from "fuse.js";
import TextField from "@mui/material/TextField";
import { ReactComponent as Arr } from "../../commons/svg/select_arr.svg";
import Message from "../../components/message";
import Loader from "../../components/loader";
import { deleteScript, deleteSymbols } from "../../utils/support";
import "./index.scss";

const fuseOptions = {
	includeScore: true,
	keys: ["label"],
};

const TypedSelect = ({
	label = "",
	name = "",
	helperText = "",
	selectedValue = "",
	type = "text",
	error = "",
	selectedLabel = "",
	addBtnText = "",
	selectItems = [],
	isReset = false,
	required = false,
	isDisabled = false,
	isLoading = false,
	addNewElHandle = () => {},
	getErrorText = () => {},
	getValue = () => {},
}) => {
	const [inputValue, setInputValue] = useState("");
	const [hideInputValue, setHideInputValue] = useState("");
	const [errorText, setErrorText] = useState("");
	const [selectedData, setSelectedData] = useState({
		value: "",
		label: "",
	});
	const [filtredItems, setFiltredItems] = useState([]);
	const [items, setItems] = useState([]);
	const arr = useRef();
	const input = useRef();
	const selectMenu = useRef();
	const selectList = useRef();

	useEffect(() => {
		getErrorText(errorText);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorText]);

	useEffect(() => {
		if (selectedValue) {
			if (selectedLabel) {
				setSelectedData({
					value: selectedValue,
					label: selectedLabel,
				});
			} else {
				setInputValue(selectedValue);
				setHideInputValue(selectedValue);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (hideInputValue) {
			getValue(hideInputValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hideInputValue]);

	useEffect(() => {
		if (selectItems) {
			const correctlyShapedArray = selectItems.map((val) => ({
				item: Object.assign(val, {}),
				matches: [],
				score: 1,
			}));

			setItems(correctlyShapedArray);
			setFiltredItems(correctlyShapedArray);
		}
	}, [selectItems, isReset]);

	useEffect(() => {
		if (selectedData?.value) {
			setHideInputValue(selectedData.value);
			setInputValue(selectedData.label);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedData]);

	useEffect(() => {
		if (isReset) {
			setInputValue("");
			setHideInputValue("");
			setSelectedData({});
			setFiltredItems(items);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isReset]);

	const onFilterSelectItems = (value) => {
		const fuse = new Fuse(selectItems, fuseOptions);

		if (value.length > 0) {
			setFiltredItems(fuse.search(value));
		} else {
			setFiltredItems(items);
		}
	};

	const onInputClick = () => {
		if (!isDisabled) {
			arr.current.classList.toggle("open");
			selectMenu.current.classList.toggle("open");
		}
	};

	const onChangeInput = (e) => {
		setInputValue(e.target.value);

		if (!selectedData.value) {
			setHideInputValue(e.target.value);
		}

		setFiltredItems();
		if (e.target.value.length === 2) {
			setSelectedData({});
		}
		onFilterSelectItems(e.target.value);

		if (type === "text") {
			deleteScript(e.target.value, setErrorText);
			deleteSymbols(e.target.value, setErrorText);
		}
	};

	// const onClickBtn = () => {
	// 	addNewElHandle();
	// 	onInputClick();
	// };

	const onClickSelectItem = (e) => {
		const value = e.target.getAttribute("data-value");
		const label = e.target.getAttribute("data-label");
		setSelectedData({
			value,
			label,
		});

		setHideInputValue(value);
		setInputValue(label);
		hideSelectPopup();
	};

	const hideSelectPopup = () => {
		setFiltredItems(items);
		arr.current.classList.remove("open");
		selectMenu.current.classList.remove("open");
	};

	return (
		<div className="typed_select">
			<div className="input_container">
				<TextField
					required={required}
					disabled={isDisabled}
					onClick={onInputClick}
					variant="filled"
					label={label}
					helperText={errorText ? errorText : helperText}
					value={inputValue || ""}
					onChange={(e) => {
						onChangeInput(e);
					}}
					type={type}
					error={Boolean(errorText)}
					onBlur={hideSelectPopup}
					autoComplete="off"
				/>

				<input
					className="hidden"
					value={hideInputValue}
					ref={input}
					type={type}
					name={name}
					onChange={(e) => setHideInputValue(e.target.value)}
				/>
				<Arr ref={arr} className="select__arr" />
			</div>

			<div ref={selectMenu} className="select_list_container">
				<ul className="list_style_type_none" ref={selectList}>
					{isLoading ? (
						<Loader />
					) : error ? (
						<Message text={error} isError={true} />
					) : (
						<>
							{filtredItems?.map((item, i) => (
								<li
									key={i + "sli"}
									className={`select_item MuiMenuItem-root ${
										selectedData.value === item.item.id &&
										"selected"
									}`}
									data-value={item.item.id}
									data-label={item.item.label}
									onClick={(e) => {
										onClickSelectItem(e);
									}}
								>
									{item.item.label}
								</li>
							))}

							{addBtnText && (
								<li className="select_item select_item--with_btn MuiMenuItem-root">
									<button
										className="btn--no_styles select_btn"
										onClick={addNewElHandle}
                                        type="button"
									>
										{addBtnText}
									</button>
								</li>
							)}
						</>
					)}
				</ul>
			</div>
		</div>
	);
};

export default TypedSelect;
