import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeaderWithArr from "../../components/page_header_with_arr";
import SubpageLayout from "../../components/subpage-layout";
import Indicator from "../../components/indicator";
import Loader from "../../components/loader";
import Message from "../../components/message";
import ConfirmDeleteModal from "../../blocks/confirm-delete-modal";
import "./index.scss";
import {
	getLocaleUserTime,
	updateErrorInfo,
	formatDateForTimeInterval,
	getOnlyDate,
} from "../../utils/support";
import { useApi } from "../../hooks";
import { siteAPI } from "../../services";
import BlockLayout from "../../blocks/block-layout";
import { locale } from "../../constants/constants";

const Website = ({ text }) => {
	const { userSign } = useApi();
	let { siteId } = useParams();
	const navigate = useNavigate();
	const [skip, setSkip] = useState(true);

	const [
		deleteSite,
		{
			error: deleteError,
			isError: isDeleteError,
			isLoading: isDeleteLoading,
			isSuccess: isDeleteSuccess,
			reset: resetDeleteResult,
		},
	] = siteAPI.useDeleteSiteMutation();

	const [
		rerunValidation,
		{
			error: rerunError,
			isError: isRerunError,
			isLoading: isRerunLoading,
			isSuccess: isRerunSuccess,
			reset: resetRerunResult,
		},
	] = siteAPI.useRerunValidationMutation();

	const [
		getValidationFile,
		{
			error: getFileError,
			isError: isGetFileError,
			isLoading: isGetFileLoading,
			isSuccess: isGetFileSuccess,
			reset: resetGetFileResult,
		},
	] = siteAPI.useGetValidationFileMutation();

	const { site, error, isLoading } = siteAPI.useFetchSiteQuery(
		{ userSign, siteId },
		{
			selectFromResult: ({ data, error, isLoading }) => {
				return {
					site: data?.url_validations[0],
					error: error,
					isLoading: isLoading,
				};
			},
			skip,
		}
	);

	const {
		data: changeHistory,
		error: errorAllChangeHistory,
		isLoading: isLoadingAllChangeHistory,
		refetch: refetchChangeHistory,
	} = siteAPI.useFetchSiteHistoryQuery(
		{ userSign, siteId },
		{
			skip,
		}
	);

	const [statusText, setStatusText] = useState("");
	const [confirmDateText, setConfirmDateText] = useState("");
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	useEffect(() => {
		if (userSign && siteId) {
			setSkip(false);
		}
	}, [userSign, siteId]);

	useEffect(() => {
		if (isRerunSuccess) {
			refetchChangeHistory();
		}
		if (isRerunSuccess || isRerunError) {
			const timeout = setTimeout(resetRerunResult, 4000);

			return () => {
				clearTimeout(timeout);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isRerunSuccess, isRerunError]);

	useEffect(() => {
		if (isDeleteSuccess) {
			navigate(`/${locale}/sites`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDeleteSuccess]);

	// reset get file result
	useEffect(() => {
		if (isGetFileSuccess || isGetFileError) {
			const timeout = setTimeout(resetGetFileResult, 4000);

			return () => {
				clearTimeout(timeout);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isGetFileSuccess, isGetFileError]);

	// set confirm status and date
	useEffect(() => {
		if (site?.is_verified) {
			setStatusText(text("website.confirmed"));
			setConfirmDateText(getOnlyDate(site?.check_at, false));
		} else {
			setConfirmDateText(
				formatDateForTimeInterval(site?.check_at, locale, false)
			);

			if (!site?.is_confirmed) {
				setStatusText(text("website.unconfirmed"));
				return;
			}

			setStatusText(text("website.waiting_to_confirm"));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [site]);

	const onCloseModal = () => {
		setOpenDeleteModal(false);
		if (isDeleteError) {
			resetDeleteResult();
		}
	};

	const onDeleteSite = async (e) => {
		e.preventDefault();

		if (siteId && userSign) {
			const body = {
				url_id: siteId,
			};
			await deleteSite({sign: userSign, body}).unwrap();
		}
	};

	const getFile = async () => {
		if (siteId && userSign) {
			const url = await getValidationFile({ userSign, url: +siteId });
			const link = document.createElement("a");
			link.setAttribute("href", url?.data);
			link.setAttribute("download", `ipn${site?.types_key}.html`);

			document.body.appendChild(link);
			link.click();
			link.parentNode.removeChild(link);
		}
	};

	const rerunValidationHandle = async () => {
		if (siteId && userSign) {
			await rerunValidation({ userSign, siteId: +siteId });
		}
	};

	return (
		<div className="website">
			<h1 className="visually-hidden">{text("website.site")}</h1>
			<PageHeaderWithArr
				isLoading={isLoading || !userSign}
				isError={error}
				error={error}
				text={site?.url}
				to={`/${locale}/sites`}
			/>
			<SubpageLayout>
				<div>
					<section className="section text website__status">
						<h2 className="subtitle">{text("website.status")}</h2>

						<p className="text--light_gray">{statusText}</p>

						<BlockLayout
							loading={isLoading || !userSign}
							error={error}
						>
							<p>
								<span className="text--light_gray">
									{!site?.is_verified
										? text("website.next_check")
										: text("website.confirm_time")}{" "}
								</span>
								<span className="text">{confirmDateText}</span>
							</p>
						</BlockLayout>

						{/* delete site btn */}
						<button
							className="btn delete_btn"
							type="button"
							onClick={() => setOpenDeleteModal(true)}
						>
							{text("website.delete_site")}
						</button>
					</section>

					{/* How confirm site information block */}
					{!site?.is_verified && (
						<section className="section text website__info">
							<h2 className="subtitle">
								{text("website.how_confirm_site")}
							</h2>

							{/* dns record */}
							<p className="text text--light_gray">
								<span className="text--bold">
									{text("website.dns_record")}
								</span>

								{text("website.dns_record_description")}
							</p>

							<BlockLayout
								loading={isLoading || !userSign}
								error={error}
								bigLoader={false}
							>
								<div className="example_block">
									<code className="code">
										verification: {site?.types_key}
									</code>
								</div>
							</BlockLayout>

							{/*metateg */}
							<p className="text  text--light_gray">
								<span className="text--bold">
									{text("website.metateg")}
								</span>

								{text("website.metateg_description")}
							</p>

							<BlockLayout
								loading={isLoading || !userSign}
								error={error}
								bigLoader={false}
							>
								<div className="example_block">
									<code className="code">
										{`<meta name="verification"
										content="${site?.types_key}">`}
									</code>
								</div>
							</BlockLayout>

							{/*html file */}
							<p className="text  text--light_gray">
								<span className="text--bold">
									{text("website.html_file")}
								</span>

								{text("website.html_file_description")}

								<button
									className="btn btn--thin download"
									onClick={getFile}
								>
									{isGetFileLoading ? (
										<span className="flex flex--center">
											<Loader />{" "}
											{text("website.downloading")}
										</span>
									) : (
										<span>{text("website.download")}</span>
									)}
								</button>
							</p>

							{isGetFileError && (
								<Message
									text={updateErrorInfo(getFileError)}
									isError={true}
								/>
							)}
						</section>
					)}
				</div>

				{/*check history */}
				<div className="right_block">
					<h2 className="subtitle">
						{text("website.check_history")}
					</h2>

					<div className="check_now_btn">
						<button
							className="btn  btn--green"
							type="button"
							onClick={rerunValidationHandle}
						>
							{isRerunLoading
								? text("website.check_now_loading")
								: text("website.check_now")}
						</button>
						{isRerunError && (
							<Message
								text={updateErrorInfo(rerunError)}
								isError={true}
							/>
						)}
						{isRerunSuccess && (
							<Message
								text={text("website.check_now_success")}
								isSuccess={true}
							/>
						)}
					</div>

					{errorAllChangeHistory && userSign && (
						<p className="text--error">
							<span>
								{text("website.error")}
								{errorAllChangeHistory?.status}
							</span>

							<span>
								{updateErrorInfo(errorAllChangeHistory)}
							</span>
						</p>
					)}

					<BlockLayout
						loading={isLoadingAllChangeHistory || !userSign}
						error={errorAllChangeHistory}
					>
						{!errorAllChangeHistory &&
							(changeHistory?.history ? (
								<div className="table">
									<div className="table__row">
										<p className="table__column text--light_gray">
											{text("website.date")}
										</p>

										<p className="table__column text--light_gray">
											{text("website.result")}
										</p>
									</div>

									{changeHistory?.history?.map((item, i) => {
										return (
											<div
												className="table__row"
												key={i + "ths"}
											>
												<div className="table__column">
													<p className="column__title  text">
														{getLocaleUserTime(
															item.created,
															locale,
															false
														)}
													</p>
												</div>
												<div className="table__column">
													<div className="column__title  text">
														{item.is_verified ===
														true ? (
															<Indicator
																type="success"
																text={text(
																	"website.check_success"
																)}
																size="small"
															/>
														) : (
															<p>
																{text(
																	"website.check_failed"
																)}
															</p>
														)}
													</div>
												</div>
											</div>
										);
									})}
								</div>
							) : (
								<p className="text--light_gray">
									{text("website.no_checks_yet")}
								</p>
							))}
					</BlockLayout>
				</div>
			</SubpageLayout>

			{openDeleteModal && (
				<ConfirmDeleteModal
					deleteEvent={text("website.delete_event")}
					onCloseModalHandle={onCloseModal}
					onDeleteHandle={onDeleteSite}
					isLoading={isDeleteLoading}
					isError={isDeleteError}
					error={deleteError}
				/>
			)}
		</div>
	);
};

export default Website;
