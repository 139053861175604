import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";

const api_uri = process.env.REACT_APP_API;

export const listAPI = createApi({
    reducerPath: 'listAPI',
    baseQuery: fetchBaseQuery({baseUrl: api_uri}),
    tagTypes: ['List'],
    endpoints: (build) => ({
        fetchLists: build.query({
            query: ({sign, projectId, limit = 10, skip = 0} ) => ({
                url: `/project/lists`,
                params: {
                    project_id : projectId,
                    skip: skip,
                    limit: limit,
                },
                headers: {
                    sign: sign
                }
            }),
            providesTags: result => ['List']
        }),
        fetchList: build.query({
            query: ({sign, projectId, listId, limit = 10, skip = 0}) => ({
                url: `/project/lists`,
                params: {
                    project_id : projectId,
                    list_id: listId,
                    skip: skip,
                    limit: limit,
                },
                headers: {
                    sign: sign
                }
            }),
            providesTags: result => ['List'],
        }),
        createList: build.mutation({
            query: ({sign, body}) => ({
                url: `/project/list`,
                method: 'POST',
                body: body,
                headers: {
                    sign: sign
                }
            }),
            invalidatesTags: ['List']
        }),
        updateList: build.mutation({
            query: ({sign, body = {}}) => ({
                url: `/project/list`,
                method: 'PATCH',
                body: body,
                headers: {
                    sign: sign
                }
            }),
            invalidatesTags: ['List']
        }),
        deleteList: build.mutation({
            query: ({sign, body = {}}) => ({
                url: `project/list`,
                method: 'DELETE',
                body: body,
                headers: {
                    sign: sign
                }
            }),
            invalidatesTags: ['List']
        }),
    })
})
