import React, { useState, useEffect, useMemo } from "react";
import AddNewElement from "../../components/add-new-element";
import PaginationContainer from "../../components/pagination-container";
import SiteCard from "../../components/site-card";
import CustomInput from "../../components/custom-input";
import Message from "../../components/message";
import FormBtns from "../../blocks/form-btns";
import Modal from "../../blocks/modal";
import { useApi } from "../../hooks";
import { siteAPI } from "../../services";
import { updateErrorInfo } from "../../utils/support";
import SignError from "../../components/sign-error";
import BlockLayout from "../../blocks/block-layout";
import "./index.scss";

const Sites = ({ text }) => {
	const [firstPostIndex, setFirstPostIndex] = useState(0);
	const [lastPostIndex, setLastPostIndex] = useState(2);
	const { userSign } = useApi();
	const [skip, setSkip] = useState(true);

	const {
		sites,
		count,
		errorAllSites,
		isLoadingAllSites,
		refetch: refetchAllSites,
	} = siteAPI.useFetchAllSitesQuery(
		{ userSign: userSign, limit: lastPostIndex },
		{
			selectFromResult: ({ data, error, isLoading }) => {
				return {
					sites: data?.url_validations,
					count: data?.count,
					errorAllSites: error,
					isLoadingAllSites: isLoading,
				};
			},
			skip,
		}
	);

	const [
		createSite,
		{
			error: createError,
			isError: isCreateError,
			isLoading: isCreateLoading,
			isSuccess: isCreateSuccess,
			reset: resetCreateResult,
		},
	] = siteAPI.useCreateSiteMutation();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [site, setSite] = useState("");
	const [errorText, setErrorText] = useState("");

	useEffect(() => {
		if (userSign && lastPostIndex) {
			setSkip(false);
		}
	}, [userSign, lastPostIndex]);

	useEffect(() => {
		if (isCreateSuccess) {
			setSite("");
			refetchAllSites();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCreateSuccess]);

	const onCloseModal = () => {
		setIsModalOpen(false);
		resetCreateResult();
		setErrorText("");
	};

	const addNewSite = async (e) => {
		e.preventDefault();
		if (site && userSign && !errorText) {
			const body = {
				url: site,
			};

			await createSite({sign: userSign, body}).unwrap();
		}
	};

	const updatedSites = useMemo(() => {
		if (sites) {
			return [
				{
					addNewELBtn: true,
				},
				...sites,
			];
		}
	}, [sites]);

	return (
		<div className="sites">
			<div className="simple_page_title">
				<h1 className="title">{text("sites.sites")}</h1>
			</div>
			<PaginationContainer
				pageCount={count + 1}
				getFirstIndex={setFirstPostIndex}
				getLastIndex={setLastPostIndex}
				addScrollTo={true}
				postPerPage={6}
			>
				<SignError text={text} />

				<BlockLayout
					loading={isLoadingAllSites || !userSign}
					error={errorAllSites}
				>
					<div className="sites__cards grid">
						{!errorAllSites &&
							updatedSites
								?.slice(firstPostIndex, lastPostIndex)
								.map((item) => {
									return item.addNewELBtn ? (
										<AddNewElement
											key={"new" + item.id}
											onClickHandle={() => {
												setIsModalOpen(true);
											}}
										/>
									) : (
										<SiteCard
											key={"sites" + item.id}
											id={item.id}
											title={item.url}
											confirmeDate={item.updated}
											waitingDate={item.check_at}
											text={text}
											isConfirmed={item.is_confirmed}
											isVerified={item.is_verified}
										/>
									);
								})}
					</div>
				</BlockLayout>
			</PaginationContainer>

			{/* add site form */}
			{isModalOpen && (
				<Modal closeModalHandle={onCloseModal}>
					<h2 className="title">{text("sites.adding_site")}</h2>

					<form onSubmit={addNewSite} className="sites__form">
						<CustomInput
							type="url"
							value={site}
							setValue={setSite}
							text={text("sites.site_address")}
							resetStatus={resetCreateResult}
							getErrorText={setErrorText}
						/>

						<FormBtns
							confirmText={text("sites.add")}
							isLoading={isCreateLoading}
							waitingText={text("sites.sending")}
							onCloseHandle={onCloseModal}
							addDeleteBtn={false}
							isDisabledConfirmBtn={!site}
						/>
					</form>

					{isCreateSuccess && (
						<Message
							text={text("sites.site_successfully_send")}
							isSuccess={true}
						/>
					)}
					{isCreateError && (
						<Message
							text={updateErrorInfo(createError)}
							isError={true}
						/>
					)}
					{errorText && <Message text={errorText} isError={true} />}
				</Modal>
			)}
		</div>
	);
};

export default Sites;
