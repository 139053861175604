import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Delete } from "../../commons/svg/deleteIcon.svg";
import { ReactComponent as Check } from "../../commons/svg/check.svg";
import Loader from "../../components/loader";
import "./index.scss";

const FormBtns = ({
	confirmText = "",
	waitingText = "",
	deleteText = "",
	deleteTitleText = "",
	addConfirmIcon = false,
	addDeleteBtn = true,
	onlyConfirmBtn = false,
	isDisabledConfirmBtn,
	addRoolTest = false,
	isLoading = false,
	testIsReadOnly = false,
	isDisabledDeleteBtn = false,
	onCloseHandle = () => {},
	onDeleteHandle = () => {},
	onTestHandle = () => {},
	formSubmitted = false,
	confirm = () => {},
}) => {
	const [testTitleText, setTestTitleText] = useState("");
	const { t } = useTranslation();

	useEffect(() => {
		if (testIsReadOnly) {
			setTestTitleText(t("form_btns.test_text"));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [testIsReadOnly]);

	return (
		<div className="flex flex--center_space_between  form_btns">
			<div className="flex left_btns">
				<button
					className="btn  btn--green submit_btn flex flex--align-items-center"
					type="submit"
					disabled={isDisabledConfirmBtn}
				>
					{isLoading ? (
						<>
							<Loader />
							<span className="waiting_text">{waitingText}</span>
						</>
					) : (
						<>
							{addConfirmIcon && <Check />}

							<span>{confirmText}</span>
						</>
					)}
				</button>

				{!onlyConfirmBtn && (
					<button
						className="btn cancel_btn"
						type="button"
						onClick={onCloseHandle}
					>
						<span>{formSubmitted ? t("form_btns.close") : t("form_btns.cansel")}</span>
					</button>
				)}

				{addRoolTest && (
					<button
						className="btn  btn--no_styles test_btn"
						type="button"
						onClick={onTestHandle}
						disabled={testIsReadOnly}
						title={testTitleText}
					>
						<span>{t("form_btns.rool_test")}</span>
					</button>
				)}
			</div>
			{!onlyConfirmBtn && addDeleteBtn && (
				<button
					className="btn flex flex--align-items-center delete_btn"
					type="button"
					disabled={isDisabledDeleteBtn}
					title={deleteTitleText}
					onClick={onDeleteHandle}
				>
					<Delete className="delete_icon" />
					<span>{deleteText}</span>
				</button>
			)}
		</div>
	);
};

export default FormBtns;
