import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useNetwork } from "wagmi";
import SelectInput from "../../../components/select";
import CustomInput from "../../../components/custom-input";
import SwitchBtn from "../../../components/switch-btn";
import Message from "../../../components/message";
import InputTitle from "../../../components/input-title";
import ConditionInput from "../../../components/condition-input";
import TypedSelect from "../../../components/typed-select";
import Loader from "../../../components/loader";
import FormBtns from "../../form-btns";
import Modal from "../../modal";
import AddListItemForm from "../../project/add-list-item-form";
import RoolTest from "../../project/rool-test";
import { useApi } from "../../../hooks";
import { siteAPI, listAPI, roolAPI, projectAPI } from "../../../services";
import { parseEventString, updateErrorInfo } from "../../../utils/support";
import {
	transactionTypeOptionsRu,
	nativeTransactionTypeOptionsRu,
} from "../../../constants/transactions-ru";
import {
	transactionTypeOptionsEng,
	nativeTransactionTypeOptionsEng,
} from "../../../constants/transactions-eng";
import "./index.scss";
import { toast } from 'react-toastify';
import { locale } from "../../../constants/constants";

const AddRoolForm = ({
	confirmError = "",
	title = "",
	networkValue = "",
	addressValue = "",
	addressLabel = "",
	typeOfTransaction = "TR",
	intervalValue = "0:05:00",
	confirmBtnText = "",
	eventDec = "Transfer (index_topic_1 address from, index_topic_2 address to, uint256 value)",
	pathValue = "",
	website = "",
	limitValue = 0,
	conditions = [],
	isCreate = false,
	isConfirmSuccess = false,
	isConfirmError = false,
	isConfirmSending = false,
	isWebsite = false,
	isTelegram = false,
	addDeleteBtn = true,
	onConfirm = () => { },
	onCansel = () => { },
	onDelete = () => { },
	resetResultFunc = () => { },
	native = false,
}) => {
	const { userSign } = useApi();
	const { chain, chains } = useNetwork();
	const formConditions = useRef();
	const form = useRef();
	const { t } = useTranslation();
	let { roolId, projectId } = useParams();
	const [roolTitle, setRoolTitle] = useState(title);
	const [network, setNetwork] = useState(networkValue || chain?.id);
	const [address, setAddress] = useState(addressValue);
	const [transactionType, setTransactionType] = useState(typeOfTransaction);
	const [eventDeclaration, setEventDeclaration] = useState(eventDec);
	const [site, setSite] = useState("");
	const [notesInterval, setNotesInterval] = useState(intervalValue);
	const [path, setPath] = useState(pathValue || "");
	const [limit, setLimit] = useState(limitValue);
	const [errorText, setErrorText] = useState("");
	const [nativeChecked, setNativeChecked] = useState(false);
	const [openSiteNotes, setOpenSiteNotes] = useState(isWebsite || false);
	const [openTelegramNotes, setOpenTelegramNotes] = useState(
		isTelegram || false
	);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isRoolTestOpen, setRoolTestOpen] = useState(false);
	const [isReset, setIsReset] = useState(false);
	const [isTGMess, setIsTGMess] = useState(false);
	const [isNotDisabledTGMessBtn, setIsNotDisabledTGMessBtn] = useState(false);
	const [conditionsFields, setConditionsFields] = useState([]);
	const [skipLists, setSkipLists] = useState(true);
	const [buttonSubmitState, setButtonSubmitState] = useState();
	const [transactionTypeOptions, setTransactionTypeOptions] = useState(transactionTypeOptionsRu);
	const [nativeTransactionTypeOptions, setNativeTransactionTypeOptions] = useState();

	useEffect(() => {
		locale === 'ru' ? setTransactionTypeOptions(transactionTypeOptionsRu) : setTransactionTypeOptions(transactionTypeOptionsEng)
		locale === 'ru' ? setNativeTransactionTypeOptions(nativeTransactionTypeOptionsRu) : setNativeTransactionTypeOptions(nativeTransactionTypeOptionsEng)
	}, [locale]);

	const {
		data: sites,
		error: errorAllSites,
		isLoading: isLoadingAllSites,
	} = siteAPI.useFetchAllSitesQuery({ userSign: userSign, limit: 100 });

	const { projectListsCount, projectListstError, projectListsLoading } =
		projectAPI.useFetchProjectQuery(
			{ userSign, projectId },
			{
				selectFromResult: ({ data, error, isLoading }) => {
					return {
						projectListsCount: data?.projects[0]?.lists,
						projectListstError: error,
						projectListsLoading: isLoading,
					};
				},
			}
		);

	const { lists, listsError, listsLoading } = listAPI.useFetchListsQuery(
		{ projectId, sign: userSign, limit: projectListsCount },
		{
			selectFromResult: ({ data, error, isLoading }) => {
				const newData = data?.project_lists?.map((item) => ({
					isAddNewBtn: false,
					id: `id__${item.id}`,
					label: item.name,
				}));

				return {
					lists: newData,
					listsError: error,
					listsLoading: isLoading,
				};
			},
			skip: skipLists,
		}
	);

	const [
		sendMess,
		{
			isError: isSendError,
			isLoading: isSendLoading,
			isSuccess: isSendSuccess,
			reset: resetSendResult,
		},
	] = roolAPI.useSendMessMutation();

	useEffect(() => {
		window.scrollTo(0, 0);

		return () => {
			setErrorText("");
			resetResult();
			setIsReset(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (projectListsCount) {
			setSkipLists(false);
		}
	}, [projectListsCount]);

	// set telegram switch btn on edit rool
	useEffect(() => {
		if (isTelegram) {
			setIsNotDisabledTGMessBtn(true);
		}
	}, [isTelegram]);

	// set Conditions Fields
	useEffect(() => {
		if (eventDeclaration) {
			if (conditions) {
				const arr = parseEventString(eventDeclaration)?.map(
					(item, i) => {
						if (
							item?.name === conditions[i]?.name &&
							conditions[i]?.value
						) {
							return conditions[i];
						} else {
							return item;
						}
					}
				);
				setConditionsFields(arr);
			} else {
				setConditionsFields(parseEventString(eventDeclaration));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventDeclaration]);
	// set event declaration input value
	useEffect(() => {
		switch (transactionType) {
			case "TR": {
				setEventDeclaration(
					"Transfer (index_topic_1 address from, index_topic_2 address to, uint256 value)"
				);
				break;
			}
			default:
				setEventDeclaration(
					"Transfer (index_topic_1 address from, index_topic_2 address to, uint256 value)"
				);
		}
	}, [transactionType]);

	useEffect(() => {
		if (native) {
			setNativeChecked(true);
		}
	}, [native]);

	useEffect(() => {
		if (nativeChecked) {
			setTransactionType("NT");
		} else {
			if (typeOfTransaction && typeOfTransaction !== "NT") {
				setTransactionType(typeOfTransaction);
			} else {
				setTransactionType("TR");
			}

			if (addressValue && typeOfTransaction !== "NT") {
				setAddress(addressValue);
			} else {
				setAddress("");
			}

			if (eventDec) {
				setEventDeclaration(eventDec);
			} else {
				setEventDeclaration(
					"Transfer (index_topic_1 address from, index_topic_2 address to, uint256 value)"
				);
			}
		}
	}, [nativeChecked]);

	// reset form after confirm success
	useEffect(() => {
		if (isConfirmSuccess) {
			if (isCreate) {
				setAddress("");
				setIsReset(true);
				form.current.reset();
				resetForm();
			}

			const timer = setTimeout(() => {
				resetResult();

				if (isCreate) {
					setIsReset(true);
				}
			}, 2000);

			return () => {
				clearTimeout(timer);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isConfirmSuccess, isCreate]);

	// set website notification fields
	useEffect(() => {
		if (openSiteNotes === false) {
			setSite("");
			setNotesInterval("0:05:00");
			setPath("");
			setLimit(0);
		} else {
			if (sites?.url_validations) {
				if (website) {
					setSite(website);
				} else {
					setSite(sites?.url_validations[0]?.id);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openSiteNotes, sites]);

	const onCloseModal = () => {
		setIsModalOpen(false);
	};

	const handleSiteCheck = () => {
		setOpenSiteNotes(!openSiteNotes);
		resetSendResult();
	};

	const handleTelegramCheck = () => {
		setOpenTelegramNotes(!openTelegramNotes);
		resetSendResult();
	};

	const buildFormBody = (form) => {
		try {
			let conditionData = {};

			const formData = new FormData(form);
			notConditionsInputs.forEach((key) => formData.delete(key));

			for (let key of formData.keys()) {
				conditionData[key] = formData.get(key).toLowerCase();
			}

			const declaration = nativeChecked ? "" : eventDeclaration;
			const addresses = nativeChecked ? null : address

			if (conditionData && userSign && network && !errorText) {
				const body = {
					sign: userSign,
					project_id: Number(projectId),
					title: roolTitle,
					addresses: addresses,
					declaration: declaration,
					on_site: openSiteNotes,
					telegram: openTelegramNotes,
					path: path,
					interval: notesInterval,
					limit: Number(limit),
					type: transactionType,
				};

				if (isCreate) {
					body.conditions = conditionData;
					body.network = Number(network);
				} else {
					body.arguments = conditionData;
					body.chain_id = Number(network);
					body.event_id = Number(roolId);
					body.url_id = site && openSiteNotes ? Number(site) : null;
				}

				return body;
			}
		} catch (err) {
			console.log(err);
			setErrorText(err.reason || err.message);
		}
	};

	const notifySuccess = () => toast(`${t("rool_form.rool_successfully")} ${!isCreate
		? t("rool_form.edited")
		: t("rool_form.created")
		}`,
		{ position: "top-center" }
	);

	const error = (confirmError) => {
		if (confirmError?.data?.detail?.[0]?.msg === "Declaration is set incorrectly") return t("rool_form.error_declaration")
		if (confirmError?.data?.message === "Event with this title already exists") return t("rool_form.error_exist")
		else return confirmError
	}

	const notifyError = () => toast.error(
		updateErrorInfo(
			error(confirmError)
		),
		{ position: "top-center" });

	const confirm = async (e) => {
		e.preventDefault();
		const body = buildFormBody(e.target);
		await onConfirm(body, userSign, errorText);
	};

	useEffect(() => {
		isConfirmSuccess && notifySuccess() && onCansel()
		isConfirmError && notifyError()
		resetResultFunc();
	}, [isConfirmSuccess, isConfirmError]);

	useEffect(() => {
		toast.dismiss();
	}, []);

	const resetResult = () => {
		resetResultFunc();
		resetSendResult();
	};

	const resetForm = () => {
		setRoolTitle("");
		setNetwork(chain?.id);
		setTransactionType("TR");
		setEventDeclaration(
			"Transfer (index_topic_1 address from, index_topic_2 address to, uint256 value)"
		);
		setSite("");
		setNotesInterval("0:05:00");
		setPath("");
		setLimit(0);
		setOpenSiteNotes(false);
		setOpenTelegramNotes(false);
		setNativeChecked(false);
	};

	const onSendTestSiteMess = async () => {
		setIsTGMess(false);
		try {
			if (site && userSign && !errorText) {
				const body = {
					url_id: +site,
					path: path || `/${locale}`,
					on_site: true,
					telegram: false,
				};

				if (body) {
					await sendMess({ sign: userSign, body });
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	const onClickTelegramBindBtn = () => {
		setIsNotDisabledTGMessBtn(true);
		setIsTGMess(false);
		resetSendResult();
	};

	const onSendTestTelerammMess = async () => {
		setIsTGMess(true);
		try {
			if (userSign && !errorText) {
				const body = {
					url_id: 0,
					path: `/${locale}`,
					on_site: false,
					telegram: true,
				};

				if (body) {
					await sendMess({ sign: userSign, body });
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	const nativeCoinOn = () => {
		setNativeChecked(true);
		document.querySelector('.button--native').classList.add('btn--green');
		document.querySelector('.button--native').classList.remove('btn--transparent');
		document.querySelector('.button--transaction').classList.add('btn--transparent');
		document.querySelector('.button--transaction').classList.remove('btn--green');
	}

	const nativeCoinOff = () => {
		setNativeChecked(false);
		document.querySelector('.button--transaction').classList.add('btn--green');
		document.querySelector('.button--transaction').classList.remove('btn--transparent');
		document.querySelector('.button--native').classList.add('btn--transparent');
		document.querySelector('.button--native').classList.remove('btn--green');
	}


	return (
		<div>
			<form ref={form} onSubmit={confirm} className="rools__form">
				<div className="form_part">
					{/* rool name input */}
					<div className="rools__form__title">
						<InputTitle
							name="project"
							type="text"
							value={roolTitle}
							label={t("rool_form.rool_name")}
							placeholder={t("rool_form.rool_name")}
							resetStatus={resetResult}
							setValue={setRoolTitle}
							getErrorText={setErrorText}
							id="project"
							setButtonSubmitState={setButtonSubmitState}
							buttonSubmitState={buttonSubmitState}
							error={t("rool.error")}
						/>
					</div>

					<div className="rools__form__section">
						{/* Transaction section */}
						<div className="flex transaction__title_container">
							<h2 className="subtitle">
								{t("rool_form.transaction")}
							</h2>
						</div>
						<div className="button_box">
							<div className="flex flex--align-items-center button_container">
								<button
									className={native == true ? "button--native btn--green" : "button--native btn--transparent"}
									onClick={nativeCoinOn}
								>
									{t("project.native_token")}
								</button>
								<button
									className={native == true ? "button--transaction btn--transparent" : "button--transaction btn--green"}
									onClick={nativeCoinOff}
								>
									{t("project.contract")}
								</button>
							</div>
							<p></p>
						</div>
						<div
							className={`flex  mobile_column ${nativeChecked ? "inline-flex" : ""
								}`}
						>
							{/* network select */}
							<div
								className={`small-select ${nativeChecked
									? "small-select--strict-width"
									: ""
									}`}
							>
								<SelectInput
									value={network}
									name="network"
									label={t("rool_form.network")}
									items={chains.map((item) => ({
										value: item.name == 'Tron' ? '0x2b6653dc' : item.name == "Tron(T)" ? "0x94a9059e" : item.id,
										title: item.name
									}))}
									required={true}
									isAddNoneElement={false}
									setValue={setNetwork}
								/>
							</div>

							{!nativeChecked && (
								<div className="big-select">
									{/* address input */}
									<TypedSelect
										error={updateErrorInfo(
											listsError || projectListstError
										)}
										name="addresses"
										selectedValue={
											isCreate ? addressValue : address
										}
										label={t("rool_form.address")}
										helperText={t(
											"rool_form.choose_from_list_or_enter_manually"
										)}
										addBtnText={t("project.add_new_list_text")}
										selectedLabel={addressLabel}
										selectItems={lists}
										isReset={isReset}
										isLoading={
											listsLoading || projectListsLoading
										}
										required={true}
										isCreate={isCreate}
										getValue={setAddress}
										getErrorText={setErrorText}
										addNewElHandle={() => {
											setIsModalOpen(true);
										}}
									/>
								</div>
							)}
						</div>
						<div
							className={`flex  mobile_column ${nativeChecked ? "inline-flex" : ""
								}`}
						>
							<div
								className={`small-select ${nativeChecked
									? "small-select--strict-width"
									: ""
									}`}
							>
								{/* transaction type select */}
								<SelectInput
									value={transactionType}
									name="type"
									label={t("rool_form.transaction_type")}
									items={
										nativeChecked
											? nativeTransactionTypeOptions
											: transactionTypeOptions
									}
									required={true}
									isReadOnly={nativeChecked}
									setValue={setTransactionType}
								/>
							</div>

							{!nativeChecked && (
								<div className="big-select">
									{/* Event Declaration */}
									<CustomInput
										type="text"
										name="event_declaration"
										text="Event Declaration"
										helperText={t(
											"rool_form.event_declaration_helper_text"
										)}
										value={eventDeclaration}
										isReadOnly={transactionType !== "CU"}
										addLockIcon={true}
										setValue={setEventDeclaration}
									/>
								</div>
							)}
						</div>
					</div>

					<div ref={formConditions} className="rools__form__section">
						{/* Conditions */}
						<h2 className="subtitle">
							{t("rool_form.conditions")}
						</h2>
						{conditionsFields?.map((item, i) => {
							return item.name === "to" && nativeChecked ? (
								<TypedSelect
									key={i + "ci"}
									name={item.name}
									selectItems={lists}
									selectedValue={item.value}
									selectedLabel={item.label}
									label={item.name && item.name}
									type={item.type}
									addBtnText={t("project.add_new_list_text")}
									isReset={isReset}
									isLoading={listsLoading}
									required={true}
									isSimpleInput={item.type === "number"}
									getErrorText={setErrorText}
									error={updateErrorInfo(listsError)}
									addNewElHandle={() => {
										setIsModalOpen(true);
									}}
								/>
							) : (
								<ConditionInput
									key={i + "ci"}
									name={item.name}
									selectItems={lists}
									selectedValue={item.value}
									selectedLabel={item.label}
									label={item.name && item.name}
									type={item.type}
									isReset={isReset}
									isLoading={listsLoading}
									isSimpleInput={item.type === "number"}
									getErrorText={setErrorText}
									error={updateErrorInfo(listsError)}
									addNewEl={() => {
										setIsModalOpen(true);
									}}
								/>
							);
						})}
					</div>
				</div>

				<div className="form_part notification">
					{/* Notification to site */}
					<div className="subtitle flex  flex--align-items-center">
						<p className="notif_text">
							{t("rool_form.notes_to_site")}
						</p>
						<div>
							<SwitchBtn
								checked={openSiteNotes}
								onChangeHandle={handleSiteCheck}
							/>
						</div>
					</div>

					{openSiteNotes && (
						<div className="site_notification__opened">
							<div className="flex  mobile_column">
								{/* domain select */}
								<div className="small-select">
									<SelectInput
										error={updateErrorInfo(errorAllSites)}
										value={site}
										name="url_id"
										label={t("rool_form.website")}
										items={sites?.url_validations.map(
											(item) => ({
												value: item.id,
												title: item.url,
											})
										)}
										isLoading={isLoadingAllSites}
										setValue={setSite}
										isAddNoneElement={false}
										resetStatus={resetSendResult}
									/>
								</div>
								{/* path input */}
								<div className="big-select">
									<CustomInput
										name="path"
										type="text"
										text={t("rool_form.path_input_text")}
										value={path}
										setValue={setPath}
										resetStatus={resetSendResult}
									/>
								</div>
							</div>

							<div className="flex mobile_column">
								{/* retry interval select */}
								<div className="small-select blue">
									<SelectInput
										value={notesInterval}
										name="interval"
										label={t("rool_form.retry_interval")}
										items={eventsIntervals}
										setValue={setNotesInterval}
										resetStatus={resetSendResult}
									/>
								</div>

								<div className="small-select blue">
									{/* retry limit input */}
									<CustomInput
										type="number"
										value={limit}
										name="limit"
										text={t("rool_form.retry_limit")}
										limitForNumber={1000}
										setValue={setLimit}
										resetStatus={resetSendResult}
									/>
								</div>
							</div>

							<p className="text--small  notification__text">
								{t("rool_form.notes_to_site_text_1")}
							</p>
							<p className="text--small  notification__text">
								{t("rool_form.notes_to_site_text_2")}
							</p>

							<div className="notification__example">
								<p className="text--bold text">
									{t("rool_form.example_text")}
								</p>

								<div className="example_block">
									<code className="code">
										<p>
											"hash":
											"0x12acc13fa5e0a1e004e0959c76a2c7c50841b6e5daecab16f58f2608693hy973",
										</p>
										<p>"network": "binance",</p>
										<p className="code__two_row">
											"info": &#123;
											<span className="code__three_row">
												"project": {t("project.project")}
											</span>
											<span className="code__three_row">
												"type": "TR",
											</span>
											<span className="code__three_row">
												"rule": {t("project.rule")},
											</span>
											<span className="code__three_row">
												"amount": -1,
											</span>
											<span className="code__three_row">
												"date": 1690183279.962649,
											</span>
											<span className="code__three_row">
												"on_site": true,
											</span>
											<span className="code__three_row">
												"telegram": true
											</span>
										</p>

										<p className="code__two_row">&#125;,</p>
										<p className="code__two_row">
											"logs": &#91;
											<span className="code__three_row">
												&#123;
												<span className="code__four_row">
													"from":
													"0x31d2bd7cf2365b022ce44ba04g049233567g3745",
												</span>
												<span className="code__four_row">
													"to":
													"0x2fbbfa992a5585b262ce546eafb26a0a337fh84h",
												</span>
												<span className="code__four_row">
													"value":
													"8000000000000000000",
												</span>
												<span className="code__four_row">
													"address":
													"0x8dfdaec7fae3a98d4fcb597be278fdd4a12sj7ge",
												</span>
												<span className="code__four_row">
													"topic":
													"0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef"
												</span>
												&#125;
											</span>
										</p>
										<p className="code__two_row">&#93;</p>
									</code>
								</div>

								<button
									type="button"
									className="btn  btn--thin  test_mess_btn "
									onClick={onSendTestSiteMess}
								>
									{isSendLoading && !isTGMess ? (
										<span className="flex flex--center">
											<Loader /> {t("rool_form.sending")}
										</span>
									) : (
										<span>
											{t("rool_form.send_test_message")}
										</span>
									)}
								</button>

								{isSendSuccess && !isTGMess && (
									<Message
										text={t(
											"rool_form.test_success_message"
										)}
										isSuccess={true}
									/>
								)}
								{isSendError && !isTGMess && (
									<Message
										text={t("rool_form.test_error_message")}
										isError={true}
									/>
								)}
							</div>
						</div>
					)}
				</div>

				<div className="form_part  notification">
					<div className="subtitle flex  flex--align-items-center">
						{/* Notification to telegram */}
						<p className="notif_text">
							{t("rool_form.notes_to_telegram")}
						</p>
						<div>
							<SwitchBtn
								checked={openTelegramNotes}
								onChangeHandle={handleTelegramCheck}
							/>
						</div>
					</div>
					{openTelegramNotes && (
						<div className="site_notification__opened">
							{/* Telegram bind link */}
							<p className="btn  btn--thin bind_account_btn">
								<a
									href={`${process.env.REACT_APP_BOT
										}?start=${userSign.slice(2, 34)}`}
									onClick={onClickTelegramBindBtn}
									rel="noreferrer noopener"
									target="_blank"
								>
									{t("rool_form.bind_account")}
								</a>
							</p>
							<p className="text--small notification__text">
								{t("rool_form.notes_to_telegram_text_1")}
							</p>

							<div className="notification__example">
								<p className="text--bold text">
									{t("rool_form.example_text")}
								</p>

								<div className="example_block">
									<code className="code">
										<p>{t("rool_form.transaction_made")}</p>
										<p>Logs:</p>
										<p>----------------</p>
										<p>
											from:0x31d2bd7cf2365b022ce44ba04g049233567g3745
										</p>
										<p>
											to:0x2fbbfa992a5585b262ce546eafb26a0a337fh84h
										</p>
										<p>value: 16 BNB</p>
										<p>
											address:0x8dfdaec7fae3a98d4fcb597be278fdd4a12sj7ge
										</p>
										<p>
											topic:0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef
										</p>
									</code>
								</div>

								<button
									className="btn  btn--thin test_mess_btn"
									onClick={onSendTestTelerammMess}
									disabled={!isNotDisabledTGMessBtn}
									type="button"
								>
									{isSendLoading && !isTGMess ? (
										<span className="flex flex--center">
											<Loader /> {t("rool_form.sending")}
										</span>
									) : (
										<span>
											{t("rool_form.send_test_message")}
										</span>
									)}
								</button>

								{isSendSuccess && isTGMess && (
									<Message
										text={t(
											"rool_form.test_success_message"
										)}
										isSuccess={true}
									/>
								)}
								{isSendError && isTGMess && (
									<Message
										text={t(
											"rool_form.test_telegram_error_message"
										)}
										isError={true}
									/>
								)}
							</div>
						</div>
					)}
				</div>

				{/* Form buttons */}
				<FormBtns
					confirmText={confirmBtnText}
					waitingText={t("rool_form.sending")}
					deleteText={t("rool_form.delete_rool")}
					onCloseHandle={onCansel}
					isLoading={isConfirmSending}
					addDeleteBtn={addDeleteBtn}
					addRoolTest={true}
					testIsReadOnly={isCreate}
					onDeleteHandle={onDelete}
					isDisabledConfirmBtn={buttonSubmitState}
					onTestHandle={() => {
						setRoolTestOpen(true);
					}}
					confirm={confirm}
				/>
			</form>

			{/* Add list form modal */}
			{isModalOpen && (
				<Modal isBigSize={true} closeModalHandle={onCloseModal}>
					<AddListItemForm
						confirmBtnText={t("rool_form.add_list_confirm_text")}
						addDeleteBtn={false}
						text={t}
						onCansel={onCloseModal}
					/>
				</Modal>
			)}

			{/* Rool test modal */}
			{isRoolTestOpen && (
				<RoolTest
					userSign={userSign}
					roolId={roolId}
					text={t}
					closeModalFunc={() => {
						setRoolTestOpen(false);
					}}
				/>
			)}
		</div>
	);
};

const notConditionsInputs = [
	"project",
	"addresses",
	"network",
	"event_declaration",
	"on_site",
	"telegram",
	"url_id",
	"path",
	"interval",
	"limit",
	"type",
];

const eventsIntervals = [
	{
		value: "0:00:01",
		title: localStorage.getItem('language') == "ru" ? "1 секунда" : "1 second",
	},
	{
		value: "0:00:05",
		title: localStorage.getItem('language') == "ru" ? "5 секунд" : "5 seconds",
	},
	{
		value: "0:00:30",
		title: localStorage.getItem('language') == "ru" ? "30 секунд" : "30 seconds",
	},
	{
		value: "0:01:00",
		title: localStorage.getItem('language') == "ru" ? "1 минута" : "1 minute",
	},
	{
		value: "0:02:00",
		title: localStorage.getItem('language') == "ru" ? "2 минуты" : "2 minutes",
	},
	{
		value: "0:05:00",
		title: localStorage.getItem('language') == "ru" ? "5 минут" : "5 minutes",
	},
	{
		value: "0:10:00",
		title: localStorage.getItem('language') == "ru" ? "10 минут" : "10 minutes",
	},
	{
		value: "0:30:00",
		title: localStorage.getItem('language') == "ru" ? "30 минут" : "30 minutes",
	},
	{
		value: "1:00:00",
		title: localStorage.getItem('language') == "ru" ? "1 час" : "1 hour",
	},
	{
		value: "2:00:00",
		title: localStorage.getItem('language') == "ru" ? "2 часа" : "2 hours",
	},
	{
		value: "4:00:00",
		title: localStorage.getItem('language') == "ru" ? "4 часа" : "4 hours",
	},
	{
		value: "8:00:00",
		title: localStorage.getItem('language') == "ru" ? "8 часов" : "8 hours",
	},
	{
		value: "12:00:00",
		title: localStorage.getItem('language') == "ru" ? "12 часов" : "12 hours",
	},
	{
		value: "24:00:00",
		title: localStorage.getItem('language') == "ru" ? "24 часа" : "24 hours",
	},
];

export default AddRoolForm;
