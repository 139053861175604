import React, { useState, useEffect, useMemo, useCallback } from "react";
import SelectInput from "../../components/select";
import CustomInput from "../../components/custom-input";
import PaginationContainer from "../../components/pagination-container";
import EventList from "../../blocks/event-list";
import { eventAPI, projectAPI } from "../../services";
import { useApi } from "../../hooks";
import "./index.scss";
import SignError from "../../components/sign-error";
import BlockLayout from "../../blocks/block-layout";
import { useDebouncedValue } from "../../hooks";

const Events = ({ text }) => {
	const [projectId, setProjectId] = useState("");
	const { userSign } = useApi();
	const [searchValue, setSearchValue] = useState("");
	const [firstPostIndex, setFirstPostIndex] = useState(0);
	const [lastPostIndex, setLastPostIndex] = useState(4);
	const [skipProjects, setSkipProjects] = useState(true);
	const [skipEvents, setSkipEvents] = useState(true);

	const [debouncedQValue, debouncedQValueFunction] = useDebouncedValue(
		"",
		1000
	);
	const [debouncedEventId, debouncedEventIdFunction] = useDebouncedValue(
		0,
		1000
	);

	const { projectsData, projectsError, projectsLoading } =
		projectAPI.useFetchAllProjectsQuery(
			{ userSign: userSign, limit: 100 },
			{
				selectFromResult: ({ data, error, isLoading }) => {
					return {
						projectsData: data?.projects,
						projectsError: error,
						projectsLoading: isLoading,
					};
				},
				skip: skipProjects,
			}
		);

	const {
		data: events,
		error: eventsError,
		isLoading: isLoadingEvents,
	} = eventAPI.useFetchEventsQuery(
		{
			userSign,
			limit: lastPostIndex,
			arg: {
				project_id: +projectId,
				event_id: debouncedEventId,
				q: debouncedQValue,
			},
		},
		{ skip: skipEvents }
	);

	useEffect(() => {
		if (userSign) {
			setSkipProjects(false);
			setSkipEvents(false);
		}
	}, [userSign]);

	const onChangeHandle = useCallback((value) => {
		setSearchValue(value);
		if (isNaN(+value)) {
			debouncedQValueFunction(value);
		} 
		else if (value.includes("0x")) {
			debouncedQValueFunction(value);
		} 
		else {
			debouncedEventIdFunction(+value);
		}
		if (value.length === 0) {
			debouncedQValueFunction("");
			debouncedEventIdFunction(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const projects = useMemo(() => {
		return projectsData?.map((item) => ({
			value: item.id,
			title: item.name,
		}));
	}, [projectsData]);

	return (
		<div className="events">
			<div className="simple_page_title  events__title  flex flex--center_space_between">
				<h1 className="title">{text("events.events")}</h1>
				<div className="flex flex--center_space_between events__inputs">
					<div className="small-select">
						<SelectInput
							name="project"
							label={text("events.project")}
							error={projectsError}
							value={projectId}
							items={projects && projects}
							isLoading={projectsLoading}
							isAddNoneElement={true}
							setValue={setProjectId}
						/>
					</div>

					<div className="big-select">
						<CustomInput
							type="text"
							value={searchValue}
							setValue={onChangeHandle}
							text={text("events.search_placeholder")}
						/>
					</div>
				</div>
			</div>
			<SignError text={text} />

			<BlockLayout
				loading={isLoadingEvents || !userSign}
				error={eventsError}
			>
				<PaginationContainer
					pageCount={events?.count}
					getFirstIndex={setFirstPostIndex}
					getLastIndex={setLastPostIndex}
					lastIndex={lastPostIndex}
					postPerPage={4}
				>
					<EventList
						items={events?.webhooks?.slice(
							firstPostIndex,
							lastPostIndex
						)}
						addProjectInfo={true}
						noSeparateAddress={true}
					/>
				</PaginationContainer>
			</BlockLayout>
		</div>
	);
};

export default Events;
