import { useEffect } from "react";
import { useDisconnect, useNetwork, useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSign, setSignError } from "../../redux/reducers/account";
import { formatEther } from "viem";
import { checkUTMOnRedirect } from "../../utils/support";

export const useWeb3 = () => {
	const dispatch = useDispatch();
	const { chain, chains } = useNetwork();
	const { disconnectAsync, isError, error } = useDisconnect();

	useEffect(() => {
		if(chain?.id) {
			const isCorrectChain = chains?.find((item) => item.id === chain?.id);
			if (!Boolean(isCorrectChain)) {
				onDisconnect();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chain, chains]);

	const { open } = useWeb3Modal();

	const onConnect = async () => {
		try {
			await open();
		} catch (e) {
			console.log("Connect error: ", e);
		}
	};
	

	const onDisconnect = async () => {
		try {
			await disconnectAsync();
			// window.localStorage.clear();
			dispatch(setSign({ data: "" }));
			dispatch(setSignError({ data: "" }));
			checkUTMOnRedirect(window.location.origin)
		} catch (e) {
			if (isError) {
				console.log("Disconnect error: ", error);
			}
		}
	};

	const fromWei = (amount) => formatEther(amount);

	return { onDisconnect, onConnect, fromWei };
};
