export function getCookie(key) {
	const decodedCookie = decodeURIComponent(document.cookie);
	const cookies = decodedCookie.split(';');
	for(let i = 0; i < cookies.length; i++) {
	  let cookie = cookies[i];
	  while (cookie.charAt(0) == ' ') {
		cookie = cookie.substring(1);
	  }
	  if (cookie.indexOf(key + "=") == 0) {
		return cookie.substring(key.length + 1, cookie.length);
	  }
	}
	return '';
}