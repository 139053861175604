import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PageHeaderWithArr from "../../components/page_header_with_arr";
import SubpageLayout from "../../components/subpage-layout";
import PaginationContainer from "../../components/pagination-container";
import EventList from "../../blocks/event-list";
import AddRoolForm from "../../blocks/project/add-rool-form";
import Modal from "../../blocks/modal";
import EditHistory from "../../blocks/project/edit_history";
import ConfirmDeleteModal from "../../blocks/confirm-delete-modal";
import { 
	transactionTypeOptionsRu, 
	nativeTransactionTypeOptionsRu 
} from "../../constants/transactions-ru";
import { 
	transactionTypeOptionsEng, 
	nativeTransactionTypeOptionsEng 
} from "../../constants/transactions-eng";
import { roolAPI, eventAPI, listAPI } from "../../services";
import { chains } from "../../constants/chains.config";
import { useApi } from "../../hooks";
import "./index.scss";
import BlockLayout from "../../blocks/block-layout";
import SignError from "../../components/sign-error";
import { locale } from "../../constants/constants";

const Rool = ({ address = "", text }) => {
	let { roolId, projectId } = useParams();
	const { userSign } = useApi();
	const navigate = useNavigate();
	const [ isModalOpen, setIsModalOpen ] = useState(false);
	const [ isHistoryOpen, setIsHistoryOpen ] = useState(false);
	const [ openDeleteModal, setOpenDeleteModal ] = useState(false);
	const [ skipRool, setSkipRool ] = useState(true);
	const [ skipLists, setSkipLists ] = useState(true);
	const [ skipEvents, setSkipEvents ] = useState(true);
	const [ firstPostIndex, setFirstPostIndex ] = useState(0);
	const [ lastPostIndex, setLastPostIndex ] = useState(3);
	const [ transactionTypeOptions, setTransactionTypeOptions ] = useState(transactionTypeOptionsRu);
	const [ nativeTransactionTypeOptions, setNativeTransactionTypeOptions ] = useState(nativeTransactionTypeOptionsRu);

	useEffect(() => {
		locale === 'ru' ? ( setTransactionTypeOptions(transactionTypeOptionsRu) && setNativeTransactionTypeOptions(nativeTransactionTypeOptionsRu) ) : 
		( setTransactionTypeOptions(transactionTypeOptionsEng) &&  setNativeTransactionTypeOptions(nativeTransactionTypeOptionsEng))
	}, [locale]);

	const { lists } = listAPI.useFetchListsQuery(
		{ sign: userSign, projectId, limit: 1000 },
		{
			selectFromResult: ({ data }) => {
				const newData = data?.project_lists?.map((item) => ({
					isAddNewBtn: false,
					id: `id__${item.id}`,
					label: item.name,
					count: item.body?.length,
				}));
				return {
					lists: newData,
				};
			},
			skip: skipLists,
		}
	);

	const { rool, error, isLoading } = roolAPI.useFetchRoolQuery(
		{ userSign, projectId, eventId: roolId },
		{
			selectFromResult: ({ data, error, isLoading }) => {
				return {
					rool: data && data[0],
					error: error,
					isLoading: isLoading,
				};
			},
			skip: skipRool,
		}
	);

	const {
		data: events,
		error: eventsError,
		isLoading: isLoadingEvents,
	} = eventAPI.useFetchEventsQuery(
		{
			userSign,
			limit: lastPostIndex,
			arg: { project_id: projectId, event_id: roolId },
		},
		{ skip: skipEvents }
	);

	const [
		updateRool,
		{
			error: updateError,
			isError: isUpdateError,
			isLoading: isUpdateLoading,
			isSuccess: isUpdateSuccess,
			reset: resetUpdateResult,
		},
	] = roolAPI.useUpdateRoolMutation();
	const [
		deleteRool,
		{
			error: deleteError,
			isError: isDeleteError,
			isLoading: isDeleteLoading,
			isSuccess: isDeleteSuccess,
			reset: resetDeleteResult,
		},
	] = roolAPI.useDeleteRoolMutation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (userSign && projectId && roolId) {
			setSkipRool(false);
			setSkipEvents(false);
		}
	}, [userSign, projectId, roolId]);

	useEffect(() => {
		if (projectId) {
			setSkipLists(false);
		}
	}, [projectId]);

	useEffect(() => {
		if (isDeleteSuccess) {
			setOpenDeleteModal(false);
			navigate(`/${locale}/projects/${projectId}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDeleteSuccess]);

	const conditions = useMemo(() => {
		return (
			rool?.arguments &&
			Object.entries(rool?.arguments?.conditions)?.map((item) => {
				if (item[1]) {
					return {
						name: item[0],
						type:
							item[1]?.includes("id__") || item[1]?.includes("0x")
								? "string"
								: "number",
						value: item[1],
						label: lists?.find((i) => i.id === item[1])?.label,
					};
				} else return {};
			})
		);
	}, [lists, rool?.arguments]);

	const onCloseModal = useCallback(() => {
		setIsModalOpen(false);
	}, []);

	const onOpenDeleteModal = useCallback(() => {
		setOpenDeleteModal(true);
		onCloseModal();
	}, [onCloseModal]);

	const onCloseDeleteModal = useCallback(() => {
		setOpenDeleteModal(false);
		if (isDeleteError) {
			resetDeleteResult();
		}
	}, [isDeleteError, resetDeleteResult]);

	const onCloseHistory = useCallback(() => {
		setIsHistoryOpen(false);
	}, []);

	const updateRoolHandle = useCallback(
		async (body, userSign, error) => {
			if (body && userSign && !error) {
				await updateRool({ sign: userSign, body });
			}
		},
		[updateRool, userSign]
	);

	const onDelete = useCallback(
		async (e) => {
			e.preventDefault();

			if (userSign && roolId) {
				await deleteRool({
					sign: userSign,
					eventId: roolId,
					projectId,
				});
			}
		},
		[deleteRool, projectId, roolId, userSign]
	);

	const chainName = useMemo(() => {
		return chains?.find((i) => +i.id === rool?.chain_id)?.name;
	}, [rool?.chain_id]);

	const transactionType = useMemo(() => {
		if (rool?.type === "NT") {
			return nativeTransactionTypeOptions.find(
				(i) => i.value === rool?.type
			)?.title;
		} else {
			return transactionTypeOptions.find((i) => i.value === rool?.type)
				?.title;
		}
	}, [rool?.type]);

	return (
		<div className="rool">
			{/* title */}
			<PageHeaderWithArr
				text={rool?.title}
				isLoading={isLoading || !userSign}
				isError={error}
				error={error}
				to={`/${locale}/projects/${projectId}`}
				isBtnToPrev={true}
				roolId={roolId}
			/>

			<SubpageLayout>
				<SignError text={text} />

				<BlockLayout loading={isLoading || !userSign} error={error}>
					<div className="left_part text--word-wrap">
						{/* Transaction section */}
						<section className="section text">
							<h2 className="subtitle">
								{text("rool.transaction")}
							</h2>

							<div>
								<p className="rool__row">
									<span className="text--light_gray">
										{text("rool.network")}
									</span>

									<span className="text--bold">
										{chainName ? chainName : 'Tron'}
									</span>
								</p>
								{rool?.type !== "NT" && (
									<p className="rool__row">
										<span className="text--light_gray">
											{text("rool.address")}
										</span>

										<span className="text--bold">
											{rool?.addresses ? (
												window.innerWidth > 560 ? (
													rool?.addresses
												) : rool?.addresses.includes(
														"0x"
												  ) ? (
													<span className="text--word-wrap">
														{rool?.addresses}
													</span>
												) : (
													rool?.addresses
												)
											) : (
												"не указан"
											)}
										</span>
									</p>
								)}
								<p className="rool__row">
									<span className="text--light_gray">
										{text("rool.type_of_transaction")}
									</span>

									<span className="text--bold">
										{transactionType}
									</span>
								</p>
								{conditions?.map((item, i) => {
									const listLabel = item?.value?.includes(
										"id__"
									)
										? item.label
										: item?.value;

									return (
										item.value &&
										listLabel && (
											<p
												className="rool__row"
												key={i + "cond"}
											>
												<span className="text--light_gray">
													{text("rool.field")}
												</span>

												<span className="text--bold">
													{item.name}
												</span>

												<span className="text--light_gray">
													{text("rool.locate")}
												</span>

												{item.label ? (
													<span className="text--bold text--word-wrap">
														{text("rool.from")}{" "}
														{listLabel}
													</span>
												) : (
													<span className="text--bold text--word-wrap">
														{listLabel}
													</span>
												)}
											</p>
										)
									);
								})}
							</div>
						</section>

						{/* Notifications section */}
						<section className="section">
							<h2 className="subtitle">
								{text("rool.notifications")}
							</h2>
							<div>
								{rool?.on_site ? (
									<p className="rool__row">
										<span className="text--light_gray">
											{text("rool.to_site_address")}
										</span>

										<span className="text--bold">
											{rool?.url}
											{rool?.path}
										</span>
									</p>
								) : (
									<p className="rool__row text--light_gray">
										{text("rool.do_not_site_notification")}
									</p>
								)}

								<p className="rool__row text--light_gray">
									{rool?.telegram
										? text("rool.telegram_notification")
										: text(
												"rool.do_not_telegram_notification"
										  )}
								</p>
							</div>
						</section>

						{/* edit button */}
						<section className="section">
							<button
								className="btn btn--thin"
								type="button"
								onClick={() => {
									setIsModalOpen(true);
								}}
							>
								{text("rool.edit_rool")}
							</button>
						</section>

						{/* Edit history */}
						<section className="section">
							<h2 className="text--small  text--light_gray">
								{text("rool.edit_history")}
							</h2>

							<p className="text--small  text--light_gray">
								<span>{text("rool.rool_edits")}</span>
								<button
									className="edited_count btn--no_styles"
									onClick={() => {
										setIsHistoryOpen(true);
									}}
									type="button"
								>
									{rool?.changed} {text("rool.times")}
								</button>
							</p>
						</section>
					</div>
				</BlockLayout>

				{/* Events */}
				<div className="right_block">
					<h2 className="subtitle">{text("rool.events")}</h2>
					<BlockLayout
						loading={isLoadingEvents || !userSign}
						error={eventsError}
					>
						<PaginationContainer
							pageCount={events?.count}
							getFirstIndex={setFirstPostIndex}
							getLastIndex={setLastPostIndex}
							lastIndex={lastPostIndex}
							postPerPage={3}
						>
							<EventList
								items={events?.webhooks?.slice(
									firstPostIndex,
									lastPostIndex
								)}
								addProjectInfo={false}
								noSeparateAddress={false}
							/>
						</PaginationContainer>
					</BlockLayout>
				</div>
			</SubpageLayout>

			{/* Confirm Delete Modal */}
			{openDeleteModal && (
				<ConfirmDeleteModal
					isLoading={isDeleteLoading}
					deleteEvent={text("rool.delete_event")}
					onCloseModalHandle={onCloseDeleteModal}
					onDeleteHandle={onDelete}
					isError={isDeleteError}
					error={deleteError}
				/>
			)}

			{/* Edit form */}
			{isModalOpen && (
				<Modal
					closeModalHandle={onCloseModal}
					addTopClose={false}
					isBigSize={true}
					addConfirmBtnTop={false}
				>
					<AddRoolForm
						confirmError={updateError}
						title={rool?.title}
						networkValue={rool?.chain_id}
						addressValue={
							rool?.addresses_id
								? `id__${rool?.addresses_id}`
								: ""
						}
						addressLabel={rool?.addresses}
						typeOfTransaction={rool?.type}
						eventDec={rool?.declaration}
						website={rool?.url_id}
						confirmBtnText={text("rool.edit_btn_text")}
						pathValue={rool?.path}
						intervalValue={rool?.interval}
						limitValue={rool?.limit}
						conditions={conditions}
						isWebsite={rool?.on_site}
						isTelegram={rool?.telegram}
						isConfirmSuccess={isUpdateSuccess}
						isConfirmError={isUpdateError}
						isConfirmSending={isUpdateLoading}
						onConfirm={updateRoolHandle}
						resetResultFunc={resetUpdateResult}
						onCansel={onCloseModal}
						onDelete={onOpenDeleteModal}
						native={!rool?.declaration}
					/>
				</Modal>
			)}

			{/* Edit history modal */}
			{isHistoryOpen && (
				<Modal
					closeModalHandle={onCloseHistory}
					addTopClose={false}
					isCloseNotFilled={true}
					isBigSize={true}
					addConfirmBtnTop={false}
				>
					<EditHistory
						projectId={projectId}
						roolId={roolId}
						address={address}
						text={text}
						transactionTypeOptions={transactionTypeOptions}
					/>
				</Modal>
			)}
		</div>
	);
};

export default Rool;
