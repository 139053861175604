import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import InputTitle from "../../../components/input-title";
import { useParams } from "react-router-dom";
import FormBtns from "../../form-btns";
import "./index.scss";
import { listAPI } from "../../../services";
import Message from "../../../components/message";
import {
	deleteScript,
	formatListTextareaAddresses,
	updateErrorInfo,
	deleteSymbols,
	isTronAddress
} from "../../../utils/support";
import { useApi } from "../../../hooks/components/useApi";
import { toast } from 'react-toastify';
import { isAddress } from "viem";
import { locale } from "../../../constants/constants";

const AddListItemForm = ({
	title = "",
	confirmBtnText = "",
	text,
	addresses = [],
	addDeleteBtn = true,
	isEdit = false,
	isDelete = false,
	onCansel,
	onDelete,
	refetchProjectInfo,
}) => {
	const { t } = useTranslation();
	let { listId, projectId } = useParams();
	const [listTitle, setListTitle] = useState(title);
	const [addressesValue, setAddressesValue] = useState("");
	const [errorText, setErrorText] = useState("");
	const [errorTextarea, setErrorTextarea] = useState("");
	const [isValid, setIsValid] = useState(false);
	const [buttonSubmitState, setButtonSubmitState] = useState();
	const { userSign } = useApi();
	const [isAddressError, setIsAddressError] = useState(false);
	const adressesList = addressesValue.match(/.{1,42}/g);

	function isValidAddress(str) {
		if (str == null) {
			setIsValid(false);
			setIsAddressError(false);
		}
		if (str.every(n => (isAddress(n)) || isTronAddress(n)) === true) {
			setIsValid(true);
			setIsAddressError(false);
		}
		else {
			setIsValid(false);
			setIsAddressError(true);
		}
	}

	useEffect(() => {
		if (adressesList) isValidAddress(adressesList)
	}, [adressesList]);

	const [
		createList,
		{
			error: createError,
			isError: isCreateError,
			isLoading: isCreateLoading,
			isSuccess: isCreateSuccess,
			reset: resetCreateResult,
		},
	] = listAPI.useCreateListMutation();

	const [
		updateList,
		{
			error: updateError,
			isError: isUpdateError,
			isLoading: isUpdateLoading,
			isSuccess: isUpdateSuccess,
			reset: resetUpdateResult,
		},
	] = listAPI.useUpdateListMutation();

	useEffect(() => {
		// format addresses array and set textarea value on edit
		if (window.innerWidth > 560) {
			setAddressesValue(addresses.join("\n"));
		} else {
			setAddressesValue(addresses.join("\n\n"));
		}

		return function cleanup() {
			resetResult();
			setErrorText("");
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isCreateSuccess) {
			resetForm();
			refetchProjectInfo();
		}
	}, [isCreateSuccess]);

	const resetForm = () => {
		setListTitle("");
		setAddressesValue("");
	};

	const resetResult = () => {
		resetUpdateResult();
		resetCreateResult();
	};

	const notifySuccess = () => toast(`${text("add_list_form.list_successfully")}  ${isEdit
		? text("add_list_form.edited")
		: text("add_list_form.created")
		}`,
		{ position: "top-center" }
	);

	const postList = async (e) => {
		e.preventDefault();
		if (addressesValue) {
			let addresses = formatListTextareaAddresses(addressesValue);

			if (!listTitle) {
				setErrorText("Это поле обязательно.");
			}

			if (listTitle && addresses && userSign && !errorText) {
				const body = {
					name: listTitle,
					body: addresses,
					sign: userSign,
					project_id: projectId
				};

				await createList({ sign: userSign, body });
				isCreateSuccess && (onCansel() && notifySuccess());
			}
		}
	};

	const sendEditedList = async (e) => {
		e.preventDefault();
		if (addressesValue) {
			let addressesList = formatListTextareaAddresses(addressesValue);

			const sendingTitle = title !== listTitle ? listTitle : null;

			if (listTitle && addresses && !errorText) {
				const body = {
					name: sendingTitle,
					body: addressesList,
					sign: userSign,
					project_id: projectId,
					list_id: listId
				};

				updateList({ sign: userSign, body })
				await onCansel();
				await notifySuccess();
			}
		}
	};

	const onAddressesChange = (e) => {
		setAddressesValue(e.target.value);
		deleteScript(e.target.value, setErrorTextarea);
		deleteSymbols(e.target.value, setErrorTextarea);
		resetResult();
	};

	const errorMessage = async (e) => {
		e.preventDefault();
		setIsAddressError(true);
	}

	return (
		<form
			className="li_item__form"
			onSubmit={isValid ? (isEdit ? sendEditedList : postList) : (errorMessage)}
		>
			<div className="rools__form__title">
				<InputTitle
					type="text"
					value={listTitle}
					label={text("add_list_form.list_name")}
					placeholder={text("add_list_form.list_name")}
					errorTextValue={errorText}
					resetStatus={resetResult}
					setValue={setListTitle}
					getErrorText={setErrorText}
					setButtonSubmitState={setButtonSubmitState}
					buttonSubmitState={buttonSubmitState}
					error={t("add_list_form.error")}
					id="project"
				/>
			</div>

			{/* addresses textarea */}
			<div className="li_item__form__addresses">
				<p className="subtitle">{text("add_list_form.adresses")}</p>

				<div
					className={`card__inner_block  ${Boolean(errorTextarea) && "error"
						}`}
				>
					<textarea
						placeholder={text("add_list_form.specify_addresses")}
						onChange={onAddressesChange}
						value={addressesValue}
					></textarea>
				</div>

				{errorTextarea && (
					<Message text={errorTextarea} isError={true} />
				)}
			</div>

			{isCreateError && (
				<Message text={updateErrorInfo(createError)} isError={true} />
			)}
			{isUpdateError && (
				<Message text={updateErrorInfo(updateError)} isError={true} />
			)}
			{isAddressError && (
				<Message text={locale === "ru" ? "Введите корректные адреса без пробелов" : "Enter correct wallets without whitespaces"} isError={true} />
			)}

			<FormBtns
				isLoading={isCreateLoading || isUpdateLoading}
				waitingText={text("add_list_form.sending")}
				confirmText={confirmBtnText}
				deleteText={text("add_list_form.delete_list")}
				deleteTitleText={
					!isDelete
						? text("add_list_form.disable_delete_btn_text")
						: ""
				}
				onCloseHandle={onCansel}
				onConfirmHandle={postList}
				addDeleteBtn={addDeleteBtn}
				onDeleteHandle={onDelete}
				isDisabledConfirmBtn={!addressesValue}
				isDisabledDeleteBtn={!isDelete}
			/>
		</form>
	);
};

export default AddListItemForm;
