import { useSignMessage } from "wagmi";
import { useDispatch, useSelector } from "react-redux";
import { setSign, setSignError } from "../../redux/reducers/account";
import { getCookie, setCookie } from "../index";


export const useApi = () => {
	const dispatch = useDispatch();
	const { signMessageAsync } = useSignMessage();
	const userSign = getCookie('sign');

	// Log in
	const userLogIn = async (mess) => {
		dispatch(setSignError({ data: "" }));

		if (mess && typeof mess == "string") {
			try {
				const sign = await signMessageAsync({
					message: mess,
				});
				dispatch(setSign({ data: sign }));
				dispatch(setSignError({ data: "" }));
				setCookie('sign', sign, 90);
				return sign;
			}catch (err) {
				console.dir(err);
				dispatch(
					setSignError({
						data: "Вы отклонили подпись. Пожалуйста, перезагрузите страницу, чтобы подписать снова.",
					})
				);
				dispatch(setSign({ data: "" }));
			}
		}
	};

	return { userSign, userLogIn };
};
